import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Outlet, Link } from "react-router-dom";
import Footer from "./Footer.js";
import ArrowRight from "./ArrowRight.js";
import HoriLine from "./HoriLine.js";
import BlobLeft from "./BlobLeft.js";
import BlobRight from "./BlobRight.js";
import LinearProgress from "@mui/material/LinearProgress";
import { ThemeProvider } from '@mui/material/styles';
import UhkakuvatMob from './UhkakuvatMob.js';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';



let uhkvideo = [];


uhkvideo.push( <video key="strvideo" id="uhkvideoelementforplayback" width="auto" height="450vh" autoPlay="true" muted="true" mute="true" playsInline="true" loop="true" disablePictureInPicture="true" onCanPlay={uhkCanPlay} src={window.UHKAKUVATTEKSTIT.kolmasosiovideo} type="video/mp4" /> );

function uhkCanPlay(e)
{
	//console.log(e);
	//console.log("I can now play in Uhk");
	window.uhkVideoCanPlay = true;
}

let videoPlaying = false;

function sectionCallback()
{
	/*
	console.log("Play video");
	if (uhkVideoCanPlay)
		if (!videoPlaying) document.getElementById("uhkvideoelementforplayback").play();
	window.SECTIONCALLBACKS = [];
	*/
}

let firstUhkLoad = true;

let stopupdates = function () {};

function Uhkakuvat(p)
{
	window.SECTIONCALLBACKS = [];
	React.useEffect( () => { if ( window.RECENTLOAD )  window.drawUhk() } );
	let [progress, pupdate] = React.useState(0);
	let [progressLp, pupdateLp] = React.useState(0);
	let [nomoreload, nomoreupdate] = React.useState(<div></div>);

	stopupdates = nomoreupdate;

	if (! (window.KAIKKIUHKATKUVATLADATTU && window.uhkVideoCanPlay) )
	{
		function loopy()
		{
			if (! (window.KAIKKIUHKATKUVATLADATTU && window.uhkVideoCanPlay) )
			{
				setTimeout(loopy, 500);
			}
			else
			{
				let empty = [];
				stopupdates(empty);
			}
			pupdate((window.allmedia.uhkat.length + window.allmedia.uhkatv.length) + " images out of " + window.allmedia.uhkats );
			let addition = 0;
			if (window.uhkVideoCanPlay) addition = 10;
			pupdateLp((90 * (window.allmedia.uhkat.length/window.allmedia.uhkats)) + addition);
		}
		setTimeout(loopy, 1000);
		let cont = [];
		if (firstUhkLoad) firstUhkLoad = false;
		else cont = uhkvideo;
		return(	<div><ThemeProvider theme={window.MUIthemeWhite}>
			<div className="keskitetty" style={{color: "#FFFFFF", fontSize: "125%"}}>
				<div>Ladataan:</div>
				<div>
    	  				<LinearProgress variant="determinate" value={progressLp} />
				</div>
			</div></ThemeProvider><div style={{ display:"none" }}> {cont} {nomoreload} </div> </div>);
	}
	else
	{
		if (isMobile && window.NOTIPAD)
		{
			return (<UhkakuvatMob uhkvideo={uhkvideo}/>);
		}
		else
		{

			//let vsrc = window.allmedia.uhkatv[0].src;
			window.SECTIONCALLBACKS.push(sectionCallback);
			return (<div style={{color: "#FFFFFF", fontSize: "125%"}}>
			<div key="uhkisosioeka" className="kuvallinenvasen">
				<div>
					<h1>{window.UHKAKUVATTEKSTIT.ekaosio}</h1>
					<div key="gapihmomia" className="otsikkogap"></div>
					{window.UHKAKUVATTEKSTIT.ekaosioekar}
					<div key="gapihmomib" className="paragap"></div>
					{window.UHKAKUVATTEKSTIT.ekaosiotokar}
				</div>
				<div></div><div></div>
			</div>
			<HoriLine fromcolor="rgb(0, 0, 0)" tocolor="rgb(77,77,77)"/>
			<div key="gapihmomic" className="osiogap"></div>
			<div key="uhkisosiotoka" className="kuvallinenoikeavasmargi">
				<div>
					<img height="300vh" src={window.UHKAKUVATTEKSTIT.tokaosiokuva} /><br/>
					{window.UHKAKUVATTEKSTIT.tokaosiokuvateksti}
				</div>
				<div></div>
				<div style={{ display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "repeat(auto-fit, minmax(100px, auto))", justifyItems: "center", alignItems: "center"}}>
					<div style={{display: "inline-block", verticalAlign: "middle", lineHeight:"1"}}>
						<h3>{window.UHKAKUVATTEKSTIT.tokaosioekar}</h3>
						<div key="gapihmomid" className="osiogap"></div>
						<div>{window.UHKAKUVATTEKSTIT.tokaosiotokar}</div>
					</div>
				</div>
			</div>
			<div key="gapihmomie" className="osiogap"></div>
			<div key="uhkisosioekaa" className="kuvallinenvasen">
				<div>
					{window.UHKAKUVATTEKSTIT.kolmasosio}
				</div>
				<div></div>
			</div>
			<section id="uhkvideosection"></section>
			<div key="gapihmomif" className="osiogap"></div>


			<div key="uhkisosiotokaa" className="kuvallinenoikeavasmargi">
				<div style={{position: "absolute", clipPath: "polygon(16% 7%, 16% 100%, 92% 100%, 92% 7%)" }}>
					{uhkvideo}
					</div>
					<div style={{position: "absolute"}}>
						<img height="500vh" src={window.UHKAKUVATTEKSTIT.kolmasosiokuva} />
					</div>
				<div>
				</div>
				<div></div>
				<div style={{ display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "repeat(auto-fit, minmax(100px, auto))", justifyItems: "center", alignItems: "center"}}>
					<div style={{height:"10vh"}}></div>

					<div style={{display: "inline-block", verticalAlign: "middle", lineHeight:"1"}}>
						<div>{window.UHKAKUVATTEKSTIT.kolmasosioekar}</div>
						<div key="gapihmomig" className="osiogap"></div>
						<h3>{window.UHKAKUVATTEKSTIT.kolmasosiotokar}</h3>
					</div>
				</div>
			</div>

			<div key="gapihmomih" className="osiogap"></div>
			<div key="gapihmomiha" className="osiogap"></div>
			<div key="gapihmomihb" className="osiogap"></div>
			<div key="gapihmomihc" className="osiogap"></div>
			<div key="gapihmomihd" className="osiogap"></div>
			<div key="gapihmomihe" className="osiogap"></div>
			<div className="vasen" style={{fontSize:"0.5em"}}>
					{window.UHKAKUVATTEKSTIT.sivunalateksti}
			</div>


			<div className="seuraavasivu">
				<div className="seuraavasivuotsikko" >
					<Link to="../digistrategia" style={{textDecoration: "none", verticalAlign: "middle"}} onClick={moveToDigistrategia}>
						<span style={{color: "#FFFFFF", fontSize:"2em", fontWeight:"bold", verticalAlign: "middle"}}>DIGISTRATEGIA</span>
					</Link>
				</div>
				<div style={{transform: "scale(0.75)"}}>
					<Link to="../digistrategia" style={{textDecoration: "none", verticalAlign: "middle"}} onClick={moveToDigistrategia}>
						<ArrowRight stroke="#FFFFFF"/>
					</Link>
				</div>
			</div>

		</div>);
		}
	}
}
function moveToDigistrategia()
{
	window.SETSTRAT();
	window.drawStr();
}


export default Uhkakuvat;
