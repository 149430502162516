import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Outlet, Link } from "react-router-dom";


function Logo(p)
{
	return (<Link to={window.ROOTROOT} onClick={p.onClick} className="hampsniplink" style={{textDecoration: "none"}}>
	<div className="opacitywrapper">
	<div className="logosvg">
		<svg width="113.042" height="100" viewBox="0 0 113.042 100">
  <g id="Logo">
    <path id="Path_34" className="cls-1" d="M183,181.377h7.118c0,1.981.917,2.935,2.9,2.935,1.614,0,2.641-.807,2.641-2.054,0-1.1-.7-1.761-2.531-2.128l-3.559-.734c-4.255-.88-6.6-3.412-6.6-7.594,0-5.356,3.815-8.474,9.942-8.474,6.53,0,10.052,3.008,10.052,8.988h-7.08c0-1.944-.881-2.935-2.935-2.935-1.761,0-2.789.843-2.789,2.054,0,1.064.734,1.688,2.679,2.091l3.338.7c4.182.88,6.64,3.376,6.64,7.7,0,5.283-3.742,8.474-9.906,8.474-6.31,0-9.905-3.228-9.905-9.025" transform="translate(-161.708 -144.353)"/>
    <path id="Path_35" className="cls-1" d="M403,169.321h7.337V195H403v-9.5h-4.4V195h-7.337V169.321h7.337v9.466H403Z" transform="translate(-345.802 -149.65)"/>
    <path id="Path_36" className="cls-1" d="M591.03,176.865c0-9.061,3.339-13.537,10.088-13.537s10.09,4.476,10.09,13.537S607.87,190.4,601.118,190.4s-10.088-4.476-10.088-13.538m12.547,0c0-4.915-.7-6.86-2.459-6.86s-2.457,1.945-2.457,6.86.7,6.86,2.457,6.86,2.459-1.944,2.459-6.86" transform="translate(-522.365 -144.353)"/>
    <path id="Path_37" className="cls-1" d="M792.7,195l-.955-25.681h6.348l.293,14.749v5.1h.881v-5.1l.844-6.677h4.329l.843,6.677v5.1h.881v-5.1l.293-14.749H812.8L811.846,195h-8.4l-.735-5.576v-2.275h-.88v2.275L801.1,195Z" transform="translate(-699.758 -149.65)"/>
    <path id="Path_38" className="cls-1" d="M203.661,489.425l-3.219-9.176h-.74v9.176h-7.4v-25.9H203.07c5,0,8.325,3.034,8.325,7.733,0,3.145-1.887,5.624-5.143,6.771v.814l1.85.481,3.626,10.1ZM199.7,469.962v4.588h1.443a2.368,2.368,0,0,0,2.664-2.405c0-1.369-1.036-2.183-2.664-2.183Z" transform="translate(-169.961 -409.673)"/>
    <path id="Path_39" className="cls-1" d="M386.067,471.126c0-9.139,3.367-13.654,10.175-13.654s10.175,4.514,10.175,13.654-3.367,13.654-10.175,13.654-10.175-4.514-10.175-13.654m12.655,0c0-4.958-.7-6.919-2.479-6.919s-2.479,1.961-2.479,6.919.7,6.919,2.479,6.919,2.479-1.961,2.479-6.919" transform="translate(-341.214 -404.324)"/>
    <path id="Path_40" className="cls-1" d="M590.032,471.126c0-9.139,3.368-13.654,10.176-13.654s10.175,4.514,10.175,13.654-3.367,13.654-10.175,13.654-10.176-4.514-10.176-13.654m12.655,0c0-4.958-.7-6.919-2.479-6.919s-2.479,1.961-2.479,6.919.7,6.919,2.479,6.919,2.479-1.961,2.479-6.919" transform="translate(-521.483 -404.324)"/>
    <path id="Path_41" className="cls-1" d="M818,489.425h-6.142V470.517h-.851v6.4l-.481,5.106h-4.292l-.481-5.106v-6.4H804.9v18.908h-6.142v-25.9h8.917l.259,9.953v2.22h.888v-2.22l.259-9.953H818Z" transform="translate(-705.964 -409.672)"/>
    <path id="Path_42" className="cls-1" d="M178.846,777.295h-4.787v3.15h4.54v1.1h-4.54v4.279H172.9v-9.63h5.943Z" transform="translate(-152.816 -686.017)"/>
    <path id="Path_43" className="cls-1" d="M248.144,803.878v4.031h-1.1l.262-1.83h-.179a2.541,2.541,0,0,1-2.518,2.022,1.809,1.809,0,0,1-2.049-1.871c0-1.2.839-1.857,2.435-1.94l2.091-.137v-.275c0-1.211-.523-1.706-1.774-1.706-1.156,0-1.775.509-1.775,1.486h-1.114c0-1.623,1.114-2.477,2.889-2.477,1.967,0,2.834.839,2.834,2.7m-1.06,1.156-2.036.193c-.949.082-1.348.4-1.348,1.018,0,.578.426.907,1.155.907a2.2,2.2,0,0,0,2.229-2.118" transform="translate(-214.257 -708.101)"/>
    <path id="Path_44" className="cls-1" d="M316.8,805.776h1.1c0,.949.591,1.376,1.788,1.376,1,0,1.61-.344,1.61-.949,0-.563-.3-.756-1.17-.935l-1.266-.262c-1.307-.262-1.954-.8-1.954-1.816,0-1.239,1.06-2.009,2.779-2.009,1.775,0,2.821.839,2.821,2.325h-1.1c0-.963-.578-1.389-1.775-1.389-1.018,0-1.623.357-1.623.963,0,.523.33.742,1.128.908l1.293.275c1.376.262,1.967.8,1.967,1.816,0,1.239-1.059,2.022-2.765,2.022-1.789,0-2.834-.839-2.834-2.325" transform="translate(-279.997 -708.101)"/>
    <path id="Path_45" className="cls-1" d="M391.293,785.824v-9.63h1.1v3.1l-.3,1.83h.193A2.353,2.353,0,0,1,394.6,779.1c1.554,0,2.393.949,2.393,2.7v4.031h-1.128v-3.894c0-1.2-.536-1.816-1.541-1.816-1.183,0-1.926.922-1.926,2.339v3.371Z" transform="translate(-345.833 -686.017)"/>
    <path id="Path_46" className="cls-1" d="M465.682,785.044h2.82v.99h-6.8v-.99h2.875v-4.554h-2.93V779.5h4.031Zm.124-7.016h-1.348v-1.6h1.348Z" transform="translate(-408.017 -686.227)"/>
    <path id="Path_47" className="cls-1" d="M532.71,804.634a3.358,3.358,0,1,1,3.356,3.467,3.247,3.247,0,0,1-3.356-3.467m5.571,0a2.229,2.229,0,1,0-2.215,2.477,2.225,2.225,0,0,0,2.215-2.477" transform="translate(-470.821 -708.101)"/>
    <path id="Path_48" className="cls-1" d="M611.577,807.909v-6.535h1.1l-.3,1.83h.192a2.353,2.353,0,0,1,2.311-2.022c1.556,0,2.4.949,2.4,2.7v4.031h-1.129v-3.894c0-1.2-.536-1.816-1.541-1.816-1.184,0-1.926.922-1.926,2.339v3.371Z" transform="translate(-540.525 -708.101)"/>
    <path id="Path_49" className="cls-1" d="M168.523,9.63V0h2.559c2.462,0,3.934,1.8,3.934,4.815s-1.472,4.815-3.934,4.815Zm1.156-1.1h1.5c1.609,0,2.628-1.417,2.628-3.714S172.787,1.1,171.178,1.1h-1.5Z" transform="translate(-148.944)"/>
    <path id="Path_50" className="cls-1" d="M245.388,8.851h2.82v.991h-6.8V8.851h2.875V4.3h-2.93V3.307h4.031Zm.124-7.016h-1.348V.239h1.348Z" transform="translate(-213.317 -0.211)"/>
    <path id="Path_51" className="cls-1" d="M313.018,31.715h1.1a1.707,1.707,0,0,0,1.94,1.651,1.838,1.838,0,0,0,1.995-2.064v-.178l.4-1.83h-.193A2.328,2.328,0,0,1,315.92,31.3a2.864,2.864,0,0,1-2.9-3.15,2.862,2.862,0,0,1,2.9-3.164,2.342,2.342,0,0,1,2.339,2.022h.193l-.4-1.83h1.1v5.737c0,2.284-1.046,3.439-3.1,3.439-1.926,0-2.944-.949-3.04-2.641m5.062-3.563a1.974,1.974,0,1,0-3.921,0,1.972,1.972,0,1,0,3.921,0" transform="translate(-276.652 -22.084)"/>
    <path id="Path_52" className="cls-1" d="M392.248,8.851h2.821v.991h-6.8V8.851h2.875V4.3h-2.93V3.307h4.031Zm.124-7.016h-1.348V.239h1.348Z" transform="translate(-343.115 -0.211)"/>
    <path id="Path_53" className="cls-1" d="M464.968,13.2H461.24v3.467c0,.8.4,1.279,1.059,1.279.785,0,1.129-.509,1.129-1.61v-.742h1.1v.756c0,1.693-.825,2.587-2.229,2.587a1.985,1.985,0,0,1-2.159-2.27V13.2h-1.927v-.991h1.927V10.845l1.1-.537v1.9h3.728Z" transform="translate(-404.979 -9.11)"/>
    <path id="Path_54" className="cls-1" d="M541.865,27.684v4.031h-1.1l.261-1.83h-.179a2.541,2.541,0,0,1-2.518,2.023,1.809,1.809,0,0,1-2.049-1.871c0-1.2.839-1.857,2.435-1.94l2.091-.137v-.275c0-1.211-.523-1.706-1.774-1.706-1.156,0-1.775.509-1.775,1.486h-1.114c0-1.623,1.114-2.477,2.889-2.477,1.967,0,2.834.839,2.834,2.7m-1.06,1.155-2.036.193c-.949.082-1.348.4-1.348,1.018,0,.578.426.907,1.155.907a2.2,2.2,0,0,0,2.229-2.118" transform="translate(-473.854 -22.084)"/>
    <path id="Path_55" className="cls-1" d="M612.537,8.64h2.821V9.63h-6.8V8.64h2.876V.99h-2.93V0h4.03Z" transform="translate(-537.812 0)"/>
    <path id="Path_56" className="cls-1" d="M6.516,779.124v-1.2a1.961,1.961,0,0,0,2.248-2.168c0-1.238-.646-1.992-1.67-1.992-.807,0-1.332.471-1.561,1.709l-.2,1.064c-.309,1.615-1.171,2.45-2.53,2.45-1.683,0-2.8-1.292-2.8-3.271a2.932,2.932,0,0,1,3.271-3.258v1.2c-1.467,0-2.208.673-2.208,2.1,0,1.319.673,2.033,1.657,2.033.807,0,1.305-.512,1.561-1.75l.2-1.05c.309-1.588,1.158-2.423,2.558-2.423,1.683,0,2.786,1.211,2.786,3.244a2.96,2.96,0,0,1-3.311,3.312" transform="translate(0 -682.717)"/>
    <path id="Path_57" className="cls-1" d="M31.835,712.1H27.809v-1.117h3.985a1.4,1.4,0,0,0,1.575-1.454c0-1.145-.9-1.818-2.262-1.818h-3.3v-1.076H34.2v1.076l-1.791-.282v.174a2.313,2.313,0,0,1,1.979,2.275c0,1.414-.889,2.221-2.558,2.221" transform="translate(-24.578 -624.541)"/>
    <path id="Path_58" className="cls-1" d="M30.682,638.857V637.78c.929,0,1.346-.578,1.346-1.749,0-.983-.337-1.576-.929-1.576-.551,0-.74.3-.915,1.145l-.256,1.238c-.256,1.279-.781,1.912-1.777,1.912-1.212,0-1.966-1.037-1.966-2.719,0-1.737.821-2.76,2.275-2.76v1.077c-.942,0-1.36.565-1.36,1.736,0,1,.35,1.588.942,1.588.512,0,.727-.323.889-1.1L29.2,635.3c.256-1.346.781-1.925,1.777-1.925,1.212,0,1.979,1.036,1.979,2.706,0,1.75-.821,2.773-2.275,2.773" transform="translate(-23.144 -559.698)"/>
    <path id="Path_59" className="cls-1" d="M14.649,557.941v3.648h3.393c.781,0,1.252-.39,1.252-1.036,0-.768-.5-1.1-1.575-1.1h-.727v-1.076h.74c1.657,0,2.531.807,2.531,2.181a1.943,1.943,0,0,1-2.221,2.113H14.649v1.885h-.969v-1.885H12.347l-.525-1.077h1.857v-3.648Z" transform="translate(-10.449 -493.12)"/>
    <path id="Path_60" className="cls-1" d="M28.825,490.368h3.944v1.077l-1.791-.256v.175a2.486,2.486,0,0,1,1.979,2.464,1.77,1.77,0,0,1-1.831,2.005c-1.171,0-1.817-.821-1.9-2.382l-.134-2.047h-.269c-1.185,0-1.67.512-1.67,1.736,0,1.132.5,1.737,1.454,1.737v1.09c-1.588,0-2.423-1.09-2.423-2.828,0-1.925.821-2.773,2.639-2.773m1.131,1.037.188,1.992c.081.929.39,1.319,1,1.319.565,0,.888-.417.888-1.131a2.149,2.149,0,0,0-2.073-2.181" transform="translate(-23.144 -433.398)"/>
    <path id="Path_61" className="cls-1" d="M1.969,412.7H3.531v1.319H1.969Zm8.427.121v-2.76h.969v6.651H10.4v-2.813H5.94v2.867H4.971v-3.944Z" transform="translate(-1.74 -362.417)"/>
    <path id="Path_62" className="cls-1" d="M32.769,350.5H26.374v-1.077l1.791.3v-.188a2.3,2.3,0,0,1-1.979-2.261c0-1.522.929-2.343,2.639-2.343h3.944v1.1h-3.81c-1.171,0-1.777.525-1.777,1.508,0,1.158.9,1.884,2.288,1.884h3.3Z" transform="translate(-23.144 -304.855)"/>
    <path id="Path_63" className="cls-1" d="M28.825,274.8h3.944v1.077l-1.791-.256v.175a2.486,2.486,0,0,1,1.979,2.464,1.77,1.77,0,0,1-1.831,2.005c-1.171,0-1.817-.821-1.9-2.382l-.134-2.047h-.269c-1.185,0-1.67.512-1.67,1.736,0,1.132.5,1.737,1.454,1.737v1.09c-1.588,0-2.423-1.09-2.423-2.828,0-1.925.821-2.773,2.639-2.773m1.131,1.037.188,1.992c.081.929.39,1.319,1,1.319.565,0,.888-.417.888-1.131a2.149,2.149,0,0,0-2.073-2.181" transform="translate(-23.144 -242.879)"/>
    <path id="Path_64" className="cls-1" d="M9.368,202.318v.188l1.791-.3v1.076H1.735v-1.076H4.764l1.791.3v-.188a2.413,2.413,0,0,1-1.979-2.45c0-1.75,1.306-2.935,3.379-2.935,2.1,0,3.393,1.2,3.393,2.935a2.413,2.413,0,0,1-1.979,2.45m-1.414-4.268c-1.48,0-2.409.795-2.409,2.087s.942,2.1,2.409,2.1c1.494,0,2.423-.807,2.423-2.1s-.942-2.087-2.423-2.087" transform="translate(-1.533 -174.055)"/>
    <path id="Path_65" className="cls-1" d="M10.19,125.4v-2.76h.969v6.651H10.19v-2.814H2.7v2.867H1.735V125.4Z" transform="translate(-1.533 -108.391)"/>
    <path id="Path_66" className="cls-1" d="M29.862,56.816V61.7a2,2,0,0,0,2.127-2.06,1.7,1.7,0,0,0-1.171-1.736V56.829a2.737,2.737,0,0,1,2.14,2.8c0,2.087-1.319,3.2-3.352,3.2a3.116,3.116,0,0,1-3.42-3.083c0-1.844,1.2-2.935,3.2-2.935Zm-.888,1.144a1.686,1.686,0,0,0-1.831,1.791,1.922,1.922,0,0,0,1.831,1.926Z" transform="translate(-23.145 -50.215)"/>
  </g>
		</svg></div></div></Link>);
}

export default Logo;
