import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Outlet, Link } from "react-router-dom";
import ArrowLeft from "./ArrowLeft.js"
import ArrowRight from "./ArrowRight.js"
import Glide from '@glidejs/glide'
import "@glidejs/glide/src/assets/sass/glide.core.scss";
import "@glidejs/glide/src/assets/sass/glide.theme.scss";
import LinearProgress from "@mui/material/LinearProgress";
import { ThemeProvider } from '@mui/material/styles';
import KaruselliMob from './KaruselliMob.js';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';





let currentPage = 0;
let setPage = null;
let refKaru = React.createRef();

let skipdraw = false;

let glideSet = false;

let myGlide = null;

function DisableControls(Glide, Components, Events) {
  const PREV_CONTROL_SELECTOR = "[data-glide-dir='<']";
  const NEXT_CONTROL_SELECTOR = "[data-glide-dir='>']";
  const component = {
    buildAfter() {
      this.prevBtn = Components.Html.root.querySelector(PREV_CONTROL_SELECTOR);
      this.nextBtn = Components.Html.root.querySelector(NEXT_CONTROL_SELECTOR);
    },
    handleDisable() {
      const perView = Glide.settings.perView;
      const slidesCount = Components.Html.slides.length;
      this.prevBtn.disabled = (Glide.index <= 0);
      this.nextBtn.disabled = (Glide.index >= slidesCount - perView);
//	console.log("Getting it");
      let aright = document.getElementsByClassName("arrowright")[0];
      let aleft = document.getElementsByClassName("arrowleft")[0];
      if (aright != null)
	{
	if (this.nextBtn.disabled) aright.style.transform="scale(0)";
	else aright.style.transform="scale(0.75)";
	}
      if (aleft != null)
	{
	if (this.prevBtn.disabled) aleft.style.transform="scale(0)";
	else aleft.style.transform="scale(0.75)";
	}
	/*
	if (this.prevBtn.disabled) this.prevBtn.style.
	else this.prevBtn.style.color="rgba(255,255,255,1)";
	if (this.nextBtn.disabled) this.nextBtn.style.color="rgba(255,255,255,0)";
	else this.nextBtn.style.color="rgba(255,255,255,1)";
	*/
    },
  };

  Events.on("build.after", function () {
    component.buildAfter();
    component.handleDisable();
  });
  Events.on("run.after", function () {
    component.handleDisable();
  });
  return component;
}

let karudraw = false;

let stopupdates = function () {};
let allowLeft = true;
let allowRight = true;
let leftPrevent = () => {allowLeft = true;}
let rightPrevent = () => {allowRight = true;}

function Karuselli(p)
{
	let [page, pageSetter] = React.useState(0);
	currentPage = page;
	setPage = pageSetter;
//new Glide('.glide').mount();
//new Glide('.glide').mount({ Controls, Breakpoints });
	//console.log("KARUSELLI");
	window.SECTIONCALLBACKS = [];
	React.useEffect( () => { 
		window.drawKar();
		if (karudraw)
		{
			if (true)
			{
			myGlide = new Glide(".glide", {
			  peek: 250,
			  startAt: 0,
			  perView: 1,
			  type: "slider",
			  focusAt: "center"
			});
			/*
			myGlide.on("run.before", function (e) {console.log("Slide change"); console.log(e); console.log(currentPage); 
								if (e.direction == ">") setPage(currentPage++); 
								else setPage(currentPage--);
								});
			*/
			}
			const glideArrowLeft = document.querySelector('.glide__arrow--left')
const glideArrowRight = document.querySelector('.glide__arrow--right')

glideArrowLeft.addEventListener('click', () => {
   		myGlide.go('<');
})
         
glideArrowRight.addEventListener('click', () => {
   		myGlide.go('>');
})
			myGlide.mount({ DisableControls: DisableControls });
			glideSet = true;
		}
	}
		);
	let buttonl = [];
	buttonl.push(
          <div key="karubuttonl" className="glide__arrow glide__arrow--left" style={{border: "0px", transition:""}} data-glide-dir="<">
	<ArrowLeft key="karuarrowleft" scale="0.75"/>
          </div>);
	let buttonr = [];
	buttonr.push(
          <div key="karubuttonr" className="glide__arrow glide__arrow--right" style={{border: "0px", transition: ""}} data-glide-dir=">">
	<ArrowRight key="karuarrowright" scale="0.75"/>
         </div>);
	let [progress, pupdate] = React.useState(0);
	let [progressLp, pupdateLp] = React.useState(0);
	let [nomoreload, nomoreupdate] = React.useState(<div></div>);

	stopupdates = nomoreupdate;

	if (! window.KAIKKIKARUSELLIKUVATLADATTU )
	{
		karudraw = false;
		function loopy()
		{
					if (! window.KAIKKIKARUSELLIKUVATLADATTU )
					{
						setTimeout(loopy, 500);
					}
					else
					{
						let empty = [];
						stopupdates(empty);
					}
					pupdate((window.allmedia.karuselli.length) + " images out of " + window.allmedia.karusellis);
					pupdateLp(100 * (window.allmedia.karuselli.length/window.allmedia.karusellis));
		}
		setTimeout(loopy, 1000);
		return(	<ThemeProvider theme={window.MUIthemeWhite}>
			<div className="keskitetty" style={{color: "#FFFFFF", fontSize: "125%"}}>
				<div>Ladataan:</div>
				<div>
    	  				<LinearProgress variant="determinate" value={progressLp} />{nomoreload}
				</div>
			</div></ThemeProvider>);
	}
	else
	{
		if (isMobile && window.NOTIPAD)
		{
			return (<KaruselliMob />);
		}
		else
		{


	karudraw = true;
	 return (
		<div key="kokokaru">
		<div className="keskitettymob" style={{fontFamily: "gtflexaB", fontSize: "140%", color:"white"}}>Visioita muotialan tulevaisuudesta</div>
		<div style={{height: "3em"}}></div>

	<div key="karuroot" className="glide" data-current-page={page}>
        <div key="karuarleft" className="glide__arrows" style={{border: "0px"}}>
				{buttonl}
        </div>
        <div key="karutrack" className="glide__track" data-glide-el="track">
          <ul key="karuul" className="glide__slides">
            <li key="karulieka" className="glide__slide"><Johdanto /></li>
            <li key="karulitoka" className="glide__slide"><Nykytila /></li>
            <li key="karulikolmas" className="glide__slide"><Tulevaisuus /></li>
          </ul>
        </div>
        <div key="karuarright" className="glide__arrows" style={{border: "0px"}}>
				{buttonr}
        </div>
      </div>
	</div>
  );
	}
}

}

function Johdanto(p)
{
	//skipdraw = true; 
	window.PAGEBEGIN();
	return JohdantoGeneerinen(false, true, "johdanto.png", "JOHDANTO");
}

function Nykytila(p)
{
	skipdraw = true; 
	window.PAGEBEGIN();
	return JohdantoGeneerinen(true, true, "nykytila.png", "NYKYTILA");
}

function Tulevaisuus(p)
{
	skipdraw = true; 
	window.PAGEBEGIN();
	return JohdantoGeneerinen(true, false, "tulevaisuus.png", "TULEVAISUUS");
}

function moveToMurros()
{
	skipdraw = false; 
	window.SETMURROS();
	window.drawMur();
}

function moveToDigimuoti()
{
	skipdraw = false; 
	window.SETDIGIMUOTI();
	window.drawMur();
}

function moveToJohdanto()
{
	skipdraw = false; 
	window.SETJOHDANTO();
	window.drawJoh();
}

function JohdantoGeneerinen(vasen, oikea, kuva, teksti, kohde)
{
	let vasent = [];
	let oikeat = [];
	vasen = false;
	oikea = false;
	//if (vasen) vasent.push(<ArrowLeft onClick={() => {console.log("Click and..."); console.log(refKaru); if (refKaru.current) refKaru.current.slidePrev(); }}/>);
	//if (oikea) oikeat.push(<ArrowRight onClick={() => {console.log("Click and..."); console.log(refKaru); if (refKaru.current) refKaru.current.slideNext(); }}/>);
	let tekstit = [];
	let kuvat = [];
	
	if (teksti == "JOHDANTO") 
	{
		tekstit.push(
		<Link key="karujohdantolink" to="johdanto" style={{textDecoration: "none"}}>
			<div key="karujohdantodivi" className="johdantootsikko" key={"johdanto"+kohde} style={{color:"white", fontSize:"2em", fontWeight:"bold"}} onClick={moveToJohdanto}>
			{teksti}
			</div>
		</Link>
		);
		kuvat.push(<Link key="karujohdantolinki" to="johdanto" style={{textDecoration: "none"}}>
			<div key="karujohdantodivit" className="johdantootsikkokuva" key={"johdanto"+kohde+"click"} onClick={moveToJohdanto}>
				<img key="karukuvaeka" width="55%" src={kuva} />
			</div></Link>);
	}
	if (teksti == "NYKYTILA") 
	{
		tekstit.push(
		<Link key="karunykylink" to="kuluttamisenmurros" style={{textDecoration: "none"}}>
			<div key="karunykydivi" className="johdantootsikko" style={{color:"white", fontSize:"2em", fontWeight:"bold"}} onClick={moveToMurros}>
			{teksti}
			</div>
		</Link>
		);
		kuvat.push(<Link  key="karunykylinkt" to="kuluttamisenmurros" style={{textDecoration: "none"}}>
			<div key="karunykydivit" className="johdantootsikkokuva" onClick={moveToMurros}>
				<img key="karukuvatoka" width="55%" src={kuva} />
			</div>
		</Link>);
	}
	if (teksti == "TULEVAISUUS") 
	{
		tekstit.push(
		<Link key="karutulelink" to="digitaalinenmuoti" style={{textDecoration: "none"}}>
			<div key="tuledivi" className="johdantootsikko" style={{color:"white", fontSize:"2em", fontWeight:"bold"}} onClick={moveToDigimuoti}>
			{teksti}
			</div>
		</Link>
		);
		kuvat.push(<Link key="karutulelinki" to="digitaalinenmuoti" style={{textDecoration: "none"}}>
			<div key="karutuledivit" className="johdantootsikkokuva" onClick={moveToDigimuoti}>
				<img key="karukuvakolmas" width="55%" src={kuva} />
			</div>
		</Link>
		);
	}
	return (<div key="karusuperdivi">
			<div key="karusupidivi" className="mainmenumid">
				<div key="karusupsupdivi">{vasent}</div>
				{kuvat}
				<div key="karusuppusdivi">{oikeat}</div>
			</div>
			<div key="karusupidivija" className="mainmenumid">
					<div key="karutyhjadivieka"></div>
					{tekstit}
					<div key="karutyhjadivitoka"></div>
			</div>
		</div>);
}
export default Karuselli;
