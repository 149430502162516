import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Outlet, Link } from "react-router-dom";
import ArrowLeft from "./ArrowLeft.js"
import ArrowRight from "./ArrowRight.js"
import LinearProgress from "@mui/material/LinearProgress";
import { ThemeProvider } from '@mui/material/styles';
import JohdantoMob from './JohdantoMob.js';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';


let setPage = function () {console.log("Warning: redundant code setPage...");}

let stopupdates = function () {};



function Johdanto(p)
{
	//console.log("JOHDANTO");
	window.SECTIONCALLBACKS = [];
	React.useEffect( () => { if ( window.RECENTLOAD )  window.drawJoh() });
	let [progress, pupdate] = React.useState(0);
	let [progressLp, pupdateLp] = React.useState(0);
	let [nomoreload, nomoreupdate] = React.useState(<div></div>);

	stopupdates = nomoreupdate;

	if (! window.KAIKKIJOHDANTOKUVATLADATTU )
	{
		function loopy()
		{
			if (! window.KAIKKIJOHDANTOKUVATLADATTU )
			{
				setTimeout(loopy, 500);
			}
			else
			{
				let empty = [];
				stopupdates(empty);
			}
			pupdate((window.allmedia.uhkat.length) + " images out of " + window.allmedia.uhkats);
			pupdateLp(100 * (window.allmedia.uhkat.length/window.allmedia.uhkats));
		}
		setTimeout(loopy, 1000);
		return(	<ThemeProvider theme={window.MUIthemeWhite}>
			<div className="keskitetty" style={{color: "#FFFFFF", fontSize: "125%"}}>
				<div>Ladataan:</div>
				<div>
    	  				<LinearProgress variant="determinate" value={progressLp} />{nomoreload}
				</div>
			</div></ThemeProvider>);
	}
	else
	{
		if (isMobile && window.NOTIPAD)
		{
			return (<JohdantoMob />);
		}
		else
		{
			return (<div className="mainmenutop">
					<div></div>
					<JohdantoSwitch />
					<div></div>
				</div>
				);
		}
	}
}
let pallerointi = null;

function sectionCallback()
{
	//console.log(window.ACTIVESECTION);
	if (pallerointi) pallerointi(window.ACTIVESECTION);
}

//let specialColors = false;

function JohdantoSwitch(p)
{
			window.SECTIONCALLBACKS.push(sectionCallback);
			return (<div key="johdantorunko">
				<section id="johdantoeka"></section><JohdantoSivuEka />
				<div key="johdantorunko1" style={{height:window.innerHeight/2}}></div>
				<section id="johdantotoka"></section><JohdantoSivuToka />
				<div key="johdantorunko2" style={{height:window.innerHeight/2}}></div>
				<section id="johdantokolmas"></section><JohdantoSivuKolmas />
				<div key="johdantorunko3" style={{height:window.innerHeight/2}}></div>
				<section id="johdantoneljas"></section><JohdantoSivuNeljas />
				<div key="johdantorunko4" style={{height:window.innerHeight/2}}></div>
				<JohdantoPalleroKollaasi key="johdantorunkopallerot" />
				</div>);
}

function JohdantoPalleroKollaasi()
{
	let [ pallerot, setPallerot ] = React.useState("johdantoeka");
	pallerointi = setPallerot;
	let oikea = [];
	oikea.push(pallerotEka());
	switch (pallerot)
	{
		case "johdantotoka": oikea = []; oikea.push(pallerotToka()); break;
		case "johdantokolmas": oikea = []; oikea.push(pallerotKolmas()); break;
		case "johdantoneljas": oikea = []; oikea.push(pallerotNeljas()); break;
		case "johdantoeka": 
		default:
	}
	return(
			<div key={"pallerokollaasi"+pallerot} className="pallerot">{oikea}</div>
		);
}

function JohdantoSisaltosivuPallerot(sisalto, pallerot)
{
	return(
			<div className="mainmenusisalto">
				<div></div>
				{sisalto}
				<div></div>
			</div>
);
}




function JohdantoSivuEka(p)
{
	let sisalto = [];
	sisalto.push(<div key="sivueka" className="kuvallinenvasenjoh">
			<div key="sivuekain" className="palstattu">
				<h1 key="sivuekah">{window.JOHDANTOTEKSTIT.ekasivuotsikko}</h1>
				<div key="sivuekainin" className="otsikkogap"></div>
				<p key="sivuekap">{window.JOHDANTOTEKSTIT.ekasivurunkoa}</p><p key="sivuekapp">{window.JOHDANTOTEKSTIT.ekasivurunkob}</p>
			</div>
			<div key="sivuekakuvad"><img key="sivuekakuva" width="100%" src="jvalilehti1.png" /></div>
		     </div>);
	return JohdantoSisaltosivuPallerot(sisalto, pallerotEka());
}


function JohdantoSivuToka(p)
{
	let sisalto = [];
	sisalto.push(<div  key="sivutoka"className="kuvallinenoikeajoh">
			<div  key="sivuekain"><img  key="sivutokaku" width="100%" src="jvalilehti2.png" /></div>
			<div  key="sivutokainin"className="palstattu">
				<h1  key="sivutokah">{window.JOHDANTOTEKSTIT.tokasivuotsikko}</h1>
				<div  key="sivutokaininin" className="otsikkogap"></div>
				<p  key="sivutokap">{window.JOHDANTOTEKSTIT.tokasivurunko}</p>
			</div>
		     </div>);
	return JohdantoSisaltosivuPallerot(sisalto, pallerotToka());
}


function JohdantoSivuKolmas(p)
{
	let sisalto = [];
	sisalto.push(<div  key="sivukolm" className="kuvallinenvasenjoh">
			<div  key="sivukolmin" className="palstattu">
				<h1  key="sivukolmo">{window.JOHDANTOTEKSTIT.kolmassivuotsikko}</h1>
				<div  key="sivukolmodin"className="otsikkogap"></div>
				<p  key="sivukolmop">{window.JOHDANTOTEKSTIT.kolmassivurunko}</p>
			</div>
			<div  key="sivukolmodi"><img  key="sivukolmoku" width="100%" src="jvalilehti3.png" /></div>
		     </div>);
	return JohdantoSisaltosivuPallerot(sisalto, pallerotKolmas());
}



function JohdantoSivuNeljas(p)
{
	let sisalto = [];
	sisalto.push(<div  key="sivuneljad" className="keskitettyjoh">
			<div key="salmiakki">{pikkusalmiakki()}</div>
			<h1 key="sivuneljah">{window.JOHDANTOTEKSTIT.neljassivuotsikko}</h1>
			<div  key="sivuneljadivini" className="otsikkogap"></div>
			<p  key="sivuneljap">{window.JOHDANTOTEKSTIT.neljassivurunko}</p>
			<div  key="sivuneljadivinia" className="otsikkogap"></div>
			<div  key="sivuneljadivinib" className="otsikkogap"></div>
			<div  key="sivuneljadivinic" className="otsikkogap"></div>
			<Link  key="sivuneljalin" to={window.ROOTROOT} className="hampsniplink" style={{textDecoration: "none"}} onClick={moveToKaruselli} >
			<div  key="sivuneljadivilin" className="arrowanimdown"><ArrowDown  key="sivuneljarrow"/></div>
			</Link>
		     </div>);
	return JohdantoSisaltosivuPallerot(sisalto, pallerotNeljas());
}
function moveToKaruselli()
{
	window.SETKARUSELLI();
	window.drawKar();
}


function pikkusalmiakki(p)
{
	return(
<svg key="pikkusalmiakkikey" id="Group_116" width="40" height="40" viewBox="0 0 40 40">
  <defs>
    <clipPath id="clip-paths">
      <rect id="Rectangle_116" width="40" height="40" fill="#fddfda" stroke="#fddfda" strokeWidth="1"/>
    </clipPath>
  </defs>
  <g id="Group_22" clipPath="url(#clip-paths)">
    <path id="Path_676"  d="M40.954,21h0A50.2,50.2,0,0,0,21,40.955h0A50.2,50.2,0,0,0,1.042,21,50.2,50.2,0,0,0,21,1.043h0A50.2,50.2,0,0,0,40.954,21Z" transform="translate(-0.998 -0.999)" fill="#fddfda" stroke="#fddfda" strokeMiterlimit="10" strokeWidth="1"/>
  </g>
</svg>
);
}

function pallerotEka(p)
{
	return(<svg key="pallerotekakey" width="15" height="108" viewBox="0 0 15 108">
  <g id="Group_1" transform="translate(-3709 3669) rotate(90)">
    <circle id="Ellipse_15" cx="7.5" cy="7.5" r="7.5" transform="translate(-3669 -3724)" fill="#fff"/>
    <g id="Ellipse_16" transform="translate(-3638 -3724)" fill="none" stroke="#fff" strokeWidth="1">
      <circle cx="7.5" cy="7.5" r="7.5" stroke="none"/>
      <circle cx="7.5" cy="7.5" r="7" fill="none"/>
    </g>
    <g id="Ellipse_17" transform="translate(-3607 -3724)" fill="none" stroke="#fff" strokeWidth="1">
      <circle cx="7.5" cy="7.5" r="7.5" stroke="none"/>
      <circle cx="7.5" cy="7.5" r="7" fill="none"/>
    </g>
    <g id="Ellipse_19" transform="translate(-3576 -3724)" fill="none" stroke="#fff" strokeWidth="1">
      <circle cx="7.5" cy="7.5" r="7.5" stroke="none"/>
      <circle cx="7.5" cy="7.5" r="7" fill="none"/>
    </g>
  </g>
</svg>);
}

function pallerotToka(p)
{
return(<svg key="pallerottokakey" width="15" height="108" viewBox="0 0 15 108">
  <g id="Group_1" transform="translate(-3709 3669) rotate(90)">
    <circle id="Ellipse_15" cx="7.5" cy="7.5" r="7.5" transform="translate(-3623 -3709) rotate(180)" fill="#fff"/>
    <g id="Ellipse_16" transform="translate(-3654 -3709) rotate(180)" fill="none" stroke="#fff" strokeWidth="1">
      <circle cx="7.5" cy="7.5" r="7.5" stroke="none"/>
      <circle cx="7.5" cy="7.5" r="7" fill="none"/>
    </g>
    <g id="Ellipse_17" transform="translate(-3607 -3724)" fill="none" stroke="#fff" strokeWidth="1">
      <circle cx="7.5" cy="7.5" r="7.5" stroke="none"/>
      <circle cx="7.5" cy="7.5" r="7" fill="none"/>
    </g>
    <g id="Ellipse_19" transform="translate(-3576 -3724)" fill="none" stroke="#fff" strokeWidth="1">
      <circle cx="7.5" cy="7.5" r="7.5" stroke="none"/>
      <circle cx="7.5" cy="7.5" r="7" fill="none"/>
    </g>
  </g>
</svg>);
}


function pallerotKolmas(p)
{
return(<svg key="pallerotkolmaskey" width="15" height="108" viewBox="0 0 15 108">
  <g id="Group_1" transform="translate(-3709 3669) rotate(90)">
    <circle id="Ellipse_15" cx="7.5" cy="7.5" r="7.5" transform="translate(-3607 -3724)" fill="#fff"/>
    <g id="Ellipse_16" transform="translate(-3654 -3709) rotate(180)" fill="none" stroke="#fff" strokeWidth="1">
      <circle cx="7.5" cy="7.5" r="7.5" stroke="none"/>
      <circle cx="7.5" cy="7.5" r="7" fill="none"/>
    </g>
    <g id="Ellipse_17" transform="translate(-3623 -3709) rotate(180)" fill="none" stroke="#fff" strokeWidth="1">
      <circle cx="7.5" cy="7.5" r="7.5" stroke="none"/>
      <circle cx="7.5" cy="7.5" r="7" fill="none"/>
    </g>
    <g id="Ellipse_19"  transform="translate(-3576 -3724)" fill="none" stroke="#fff" strokeWidth="1">
      <circle cx="7.5" cy="7.5" r="7.5" stroke="none"/>
      <circle cx="7.5" cy="7.5" r="7" fill="none"/>
    </g>
  </g>
</svg>);
}


function pallerotNeljas(p)
{
return(<svg key="pallerotneljaskey" width="15" height="108" viewBox="0 0 15 108">
  <g id="Group_1" transform="translate(-3709 3669) rotate(90)">
    <circle id="Ellipse_15" cx="7.5" cy="7.5" r="7.5" transform="translate(-3561 -3709) rotate(180)" fill="#fff"/>
    <g id="Ellipse_16" transform="translate(-3654 -3709) rotate(180)" fill="none" stroke="#fff" strokeWidth="1">
      <circle cx="7.5" cy="7.5" r="7.5" stroke="none"/>
      <circle cx="7.5" cy="7.5" r="7" fill="none"/>
    </g>
    <g id="Ellipse_17" transform="translate(-3623 -3709) rotate(180)" fill="none" stroke="#fff" strokeWidth="1">
      <circle cx="7.5" cy="7.5" r="7.5" stroke="none"/>
      <circle cx="7.5" cy="7.5" r="7" fill="none"/>
    </g>
    <g id="Ellipse_19" transform="translate(-3592 -3709) rotate(180)" fill="none" stroke="#fff" strokeWidth="1">
      <circle cx="7.5" cy="7.5" r="7.5" stroke="none"/>
      <circle cx="7.5" cy="7.5" r="7" fill="none"/>
    </g>
  </g>
</svg>
);
}

function ArrowDown(p)
{
	return (<svg key="arrowdownkey" width="67.918" height="104" viewBox="0 0 67.918 104">
  <defs>
    <clipPath id="clipPathDo">
      <rect id="Rectangle_36" width="104" height="67.918" fill="none" stroke="#fff" strokeWidth="3"/>
    </clipPath>
  </defs>
  <g id="Group_55" transform="translate(67.918 -0.461) rotate(90)">
    <g id="Group_51" transform="translate(0.461 0)" clipPath="url(#clipPathDo)">
      <path id="Path_113" d="M151.245.006a25.26,25.26,0,0,0,1.982,9.156,35.89,35.89,0,0,0,9.854,13.063c4.185,3.577,9.5,8.3,17.489,11.7A83.82,83.82,0,0,0,163.082,45.7a35.89,35.89,0,0,0-9.854,13.063,25.26,25.26,0,0,0-1.982,9.156" transform="translate(-83.061 -0.003)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
      <line id="Line_16" x2="96.791" transform="translate(-0.489 33.959)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
    </g>
  </g>
</svg>);

}

export default Johdanto;
