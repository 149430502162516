import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Outlet, Link } from "react-router-dom";


function DimmingGradient(p)
{
	return (
	<div key="yetanotheropacity" className="opacitywrapper">
	<div key="dimmersvgkey" className="dimmersvg">
		<svg key="gradisvg" height="100%" preserveAspectRatio="none" viewBox="0 0 2048 100">
 <defs>
    <linearGradient key="linegrad" id="DimmerGradient1" x1="0%" x2="0%" y1="0%" y2="100%">
      <stop key="grada" className="grad-cls-1" stopColor={"rgb(" + p.gradientcols[0] +","+p.gradientcols[1]+","+p.gradientcols[2]+")"} stopOpacity="1" offset="0%" />
      <stop key="gradb" className="grad-cls-1" stopColor={"rgb(" + p.gradientcols[0] +","+p.gradientcols[1]+","+p.gradientcols[2]+")"} stopOpacity="0.9" offset="80%" />
      <stop key="gradc" className="grad-cls-1" stopColor={"rgb(" + p.gradientcols[0] +","+p.gradientcols[1]+","+p.gradientcols[2]+")"} stopOpacity="0" offset="100%" />
    </linearGradient>
  </defs>

  <rect key="dimmerrect"
    width="2048"
    height="100"
    fill={"url(#DimmerGradient1)"} />
		</svg></div></div>);
}
/*    fill="url(#DimmerGradient1)" />*/


export default DimmingGradient;
