import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Outlet, Link } from "react-router-dom";
import Footer from "./Footer.js";
import ArrowRight from "./ArrowRight.js";
import HoriLine from "./HoriLine.js";
import BlobLeft from "./BlobLeft.js";
import BlobRight from "./BlobRight.js";
import Muotikaruselli from "./Muotikaruselli.js";
import LinearProgress from "@mui/material/LinearProgress";
import { ThemeProvider } from '@mui/material/styles';

function ihs(f)
{
	return (window.innerHeight * f) + "px";
}



function DigimuotiMob(p)
{
	return (<div style={{color: "#FFFFFF", fontSize: "100%", textAlign: "center"}}>
	<div className="keskitettymob" >
	<h1>{window.DIGIMUOTITEKSTIT.ekaosio}</h1>
	</div>
	<div key="gapihmomia" className="otsikkogap"></div>
	<div className="keskitettymob" >
	{window.DIGIMUOTITEKSTIT.ekaosioekar}
	</div>
	<div key="gapihmomib" className="paragap"></div>
	<div className="keskitettymob" >
	{window.DIGIMUOTITEKSTIT.ekaosiotokar}
	</div>
	<HoriLine fromcolor="rgb(0, 0, 0)" tocolor="rgb(77,77,77)"/>
	<div className="keskitettymob" style={{fontSize:"50%"}}>
		<img height={ihs(0.25)} src={window.DIGIMUOTITEKSTIT.tokaosiokuva} /><br/>
		{window.DIGIMUOTITEKSTIT.tokaosiokuvateksti}
	</div>
	<div style={{ display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "repeat(auto-fit, minmax(100px, auto))", justifyItems: "center", alignItems: "center"}}>
		<div className="keskitettymob" style={{display: "inline-block", verticalAlign: "middle", lineHeight:"1"}}>
			<div style={{fontFamily: "gtflexaB"}}>{window.DIGIMUOTITEKSTIT.tokaosioekar}</div>
			<div key="gapihmomid" className="osiogap"></div>
			<div>{window.DIGIMUOTITEKSTIT.tokaosiotokar}</div>
			<div key="gapihmomie" className="paragap"></div>
			<div>{window.DIGIMUOTITEKSTIT.tokaosiokolmasr}</div>
			<div key="gapihmomif" className="paragap"></div>
			<div>{window.DIGIMUOTITEKSTIT.tokaosioneljasr}</div>
		</div>
	</div>
	<div key="gapihmomig" className="osiogap"></div>
	<div className="keskitettymob"><img height={ihs(0.25)} src={window.DIGIMUOTITEKSTIT.kolmasosiokuva} /></div>
	<div key="gapihmomig" className="osiogap"></div>
	<div className="keskitettymob" >
	<h1>{window.DIGIMUOTITEKSTIT.kolmasosio}</h1>
	</div>
	<div key="gapihmomih" className="otsikkogap"></div>
	<div className="keskitettymob" >
	{window.DIGIMUOTITEKSTIT.kolmasosioekar}
	</div>
	<div key="gapihmomii" className="paragap"></div>
	<div className="keskitettymob" >
	{window.DIGIMUOTITEKSTIT.kolmasosiotokar}
	</div>
	<div key="gapihmomij" className="osiogap"></div>
	<div style={{marginLeft:"5%", marginRight:"5%"}}>
		<Muotikaruselli mobile={true}/>
	</div>
	<div key="gapihmomik" className="osiogap"></div>
	<div className="keskitettymob">
		<h3>{window.DIGIMUOTITEKSTIT.neljasosio}</h3>
	</div>
	<div key="gapihmomil" className="osiogap"></div>
	<div style={{ display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "repeat(auto-fit, minmax(100px, auto))", justifyItems: "center", alignItems: "center"}}>
		<div className="keskitettymob" style={{display: "inline-block", verticalAlign: "baseline", lineHeight:"1"}}>
			{window.DIGIMUOTITEKSTIT.neljasosioekar}
			<div key="gapihmomim" className="paragap"></div>
			{window.DIGIMUOTITEKSTIT.neljasosiotokar}
		</div>
	</div>
	<div className="keskitettymob">
		<img height={ihs(0.25)} src={window.DIGIMUOTITEKSTIT.neljasosiokuva} />
	</div>

	<div key="gapihmomin" className="osiogap"></div>
	<div className="keskitettyMob" style={{fontSize:"0.5em"}}>
			{window.DIGIMUOTITEKSTIT.sivunalateksti}
	</div>
	<div className="keskitettymob" style={{fontSize:"0.5em"}}>
			{window.DIGIMUOTITEKSTIT.sivunalatekstivideo}
	</div>

	<div className="seuraavasivumob">
		<div className="seuraavasivuotsikko" style={{transform: "scale(0.5)"}}>
			<Link to="../uhkakuvat" style={{textDecoration: "none", verticalAlign: "middle"}} onClick={moveToUhkakuvatMob} >
				<span style={{color: "#FFFFFF", fontSize:"2em", fontWeight:"bold", verticalAlign: "middle"}}>UHKAKUVAT</span>
			</Link>
		</div>
		<div style={{transform: "scale(0.75)"}}>
			<Link to="../uhkakuvat" style={{textDecoration: "none", verticalAlign: "middle"}} onClick={moveToUhkakuvatMob} >
				<ArrowRight stroke="#FFFFFF"/>
			</Link>
		</div>
	</div>
		<div key="gapihmomidsiloppu" className="osiogap"></div>
		<div key="gapihmomidsiloppua" className="osiogap"></div>

	</div>);
}
function moveToUhkakuvatMob()
{
	window.SETUHKAT();
	window.drawUhk();
}


export default DigimuotiMob;
