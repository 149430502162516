import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Outlet, Link } from "react-router-dom";
import Footer from "./Footer.js";
import ArrowRight from "./ArrowRight.js";
import HoriLine from "./HoriLine.js";
import BlobLeft from "./BlobLeft.js";
import BlobRight from "./BlobRight.js";
import Slider from "rc-slider";
import { createSliderWithTooltip } from "rc-slider";
import LinearProgress from "@mui/material/LinearProgress";
import { ThemeProvider } from '@mui/material/styles';
import ReactCardFlip from 'react-card-flip';



let prevvalueMob = 0;

let valueChangerMob = function () {};

let stopupdatesMob = function () {};


let ekafMob = false;
let fswapekaMob = function () {};
function ekaflipperMob()
{
	ekafMob = !ekafMob;
	fswapekaMob(ekafMob);
}

let tokafMob = false;
let fswaptokaMob = function () {};
function tokaflipperMob()
{
	tokafMob = !tokafMob;
	fswaptokaMob(tokafMob);
}

let kolmasfMob = false;
let fswapkolmasMob = function () {};
function kolmasflipperMob()
{
	kolmasfMob = !kolmasfMob;
	fswapkolmasMob(kolmasfMob);
}



function ihs(f)
{
	return (window.innerHeight * f) + "px";
}

	/*
		<BlobRight scale="0.1" fromcolor="rgb(244, 225, 220)" tocolor="#FF8888" />
		<BlobLeft scale="0.5" fromcolor="rgb(244, 225, 220)" tocolor="#FF8888" />	
	*/
function MurrosMob(p)
{
	let marks = {
		0: " ",
		50: " ",
		100: " "
	};
	let [chosenValue, valueChange] = React.useState(window.MURROSTEKSTIT.ekavaihtoehto);
	valueChangerMob = valueChange;
	let [ekaflipped, flipeka] = React.useState(false);
	let [tokaflipped, fliptoka] = React.useState(false);
	let [kolmasflipped, flipkolmas] = React.useState(false);
	fswapekaMob = flipeka;
	fswaptokaMob = fliptoka;
	fswapkolmasMob = flipkolmas;



		return (<div style={{color: "#D10000", fontSize: "100%", textAlign:"center"}}>
		<div className="keskitettymob">
			<img height={ihs(0.25)} src={window.MURROSTEKSTIT.ekaosiokuvamob} />
		</div>
		<div className="keskitettymob">
			<h1>{window.MURROSTEKSTIT.ekaosio}</h1>
			<div key="gapihmomia" className="otsikkogap"></div>
			{window.MURROSTEKSTIT.ekaosioekar}
			<div key="gapihmomib" className="paragap"></div>
			{window.MURROSTEKSTIT.ekaosiotokar}
		</div>
		<div>
		<HoriLine fromcolor="rgb(244, 225, 220)" tocolor="#FF8888"/>
		</div>
		<div className="keskitettymob">
			<img height={ihs(0.25)}  src={window.MURROSTEKSTIT.tokaosiokuva} />
		</div>
		<div key="gapihmomic" className="osiogap"></div>
		<div className="keskitettymob">
			<span style={{verticalAlign: "middle"}} >{window.MURROSTEKSTIT.tokaosioekar}</span>
		</div>
		<div key="gapihmomid" className="osiogap"></div>
		<div className="keskitettymob">
		<h3>{window.MURROSTEKSTIT.kolmasosioekar}</h3>
		</div>
		<div key="gapihmomie" className="osiogap"></div>
		<h3 className="keskitettymob" style={{textAlign: "center"}}>{window.MURROSTEKSTIT.kortitosio}</h3>
		<BlobLeft fromcolor="rgb(244, 225, 220)" tocolor="#FF8888" width="40vh" height="100vh"/>	
		<div style={{marginLeft:"30%", marginRight:"30%"}}>
			<div className="murroskortit" style={{verticalAlign: "center"}} >
				<table border="0px" width="100%"><thead></thead><tbody><tr>
					<td>
							<ReactCardFlip isFlipped={ekaflipped} flipDirection="vertical">
								<img width="100%" src={window.MURROSKORTIT[0].kuva} onClick={ekaflipperMob} />
								<img width="100%"  src={window.MURROSKORTIT[1].kuva} onClick={ekaflipperMob} />
							</ReactCardFlip>

					</td></tr><tr>
					<td height="2%"></td></tr><tr>
					<td height="2%"></td></tr><tr>
					<td height="2%">
		<BlobRight fromcolor="rgb(244, 225, 220)" tocolor="#FF8888" width="40vh" height="100vh"/>	
</td></tr><tr>
					<td height="2%"></td></tr><tr>
					<td>
							<ReactCardFlip isFlipped={tokaflipped} flipDirection="vertical">
								<img width="100%"  src={window.MURROSKORTIT[2].kuva} onClick={tokaflipperMob} />
								<img width="100%"  src={window.MURROSKORTIT[3].kuva} onClick={tokaflipperMob} />
							</ReactCardFlip>

					</td></tr><tr>
					<td height="2%"></td></tr><tr>
					<td height="2%"></td></tr><tr>
					<td height="2%"></td></tr><tr>
					<td height="2%"></td></tr><tr>
					<td>
							<ReactCardFlip isFlipped={kolmasflipped} flipDirection="vertical">
								<img width="100%"  src={window.MURROSKORTIT[4].kuva} onClick={kolmasflipperMob} />
								<img width="100%"  src={window.MURROSKORTIT[5].kuva} onClick={kolmasflipperMob} />
							</ReactCardFlip>

					</td></tr></tbody></table>
			</div>
		</div>
		<div key="gapihmomif" className="osiogap"></div>
		<div className="keskitettymob">
			<img height={ihs(0.25)} src={window.MURROSTEKSTIT.neljasosiokuva} />
		</div>
		<div key="gapihmomic" className="osiogap"></div>
		<div></div>
		<div className="keskitettymob">
			{window.MURROSTEKSTIT.neljasosioekar}
			<div key="gapihmomih" className="paragap"></div>
			<h3>{window.MURROSTEKSTIT.neljasosiotokar}</h3>
		</div>
		<div key="gapihmomii" className="osiogap"></div>
		<div className="keskitettymob">
		{window.MURROSTEKSTIT.viidesosioekar}
		</div>
		<div style={{textAlign: "left", marginLeft:"5%", marginRight:"5%"}}>
			<ul style={{listStyleImage: "url('"+window.MURROSTEKSTIT.viidesosiobullet+"')"}}>
				<li>{window.MURROSTEKSTIT.viidesosioekap}</li>
				<li>{window.MURROSTEKSTIT.viidesosiotokap}</li>
				<li>{window.MURROSTEKSTIT.viidesosiokolmasp}</li>
			</ul>
		</div>
		<div key="gapihmomiz"></div>
			<div key="laskurikorttiroot" className="keskitetty" style={{ background: "url(laskuripohja.svg)", backgroundSize: "100% 100%", margin:"0%", fontSize: "75%", overflow:"hidden"}}>
				<div className="laskurislidercont">
					<div className="laskurisliderotsbox">
						{window.MURROSTEKSTIT.laskuriotsikko}
					</div>
					<div className="laskurislidervaihtoehdotboxeka">
						{window.MURROSTEKSTIT.laskuriekavaihtoehto}
					</div>
					<div className="laskurislidervaihtoehdotboxtoka">
						{window.MURROSTEKSTIT.laskuritokavaihtoehto}
					</div>
					<div className="laskurislidervaihtoehdotboxkolmas">
						{window.MURROSTEKSTIT.laskurikolmasvaihtoehto}
					</div>
					<div className="laskurisliderliukubox">
						<Slider key="slideri" dots min={0} marks={marks} step={null} defaultValue={0} minValue={0} maxValue={100} prefixCls="pinkslider" onChange={sliderChangeMob}/>
					</div>
					<div className="laskurislidertulosbox">
						{chosenValue}
					</div>
					
				</div>
			</div>

		<div key="gapihmomiq" className="osiogap"></div>
		<div className="keskitettymob">
		{window.MURROSTEKSTIT.kuudesosioekar}
		</div>
		<div key="gapihmomiaa" className="otsikkogap"></div>
		<div className="keskitettymob">
		{window.MURROSTEKSTIT.kuudesosiotokar}
		</div>
		<div key="gapihmomiab" className="otsikkogap"></div>
		<div style={{marginLeft: "20%", marginRight: "20%"}}>
				<div key="murrosnappi" onClick={laskurinappiMob} className="nappi">{window.MURROSTEKSTIT.kuudesosionappi}</div>
		</div>
		<div key="gapihmomipq" className="osiogap"></div>
		<div className="seuraavasivumob">
			<div className="seuraavasivuotsikko" style={{transform: "scale(0.5)"}}>
				<Link to="../elamyksellisyys" style={{textDecoration: "none", verticalAlign: "middle"}} onClick={moveToElamyksellisyysMob} >
					<span style={{color: "#D10000", fontSize:"2em", fontWeight:"bold", verticalAlign: "middle"}}>ELÄMYKSELLISYYS</span>
				</Link>
			</div>
			<div style={{transform: "scale(0.75)"}}>
				<Link to="../elamyksellisyys" style={{textDecoration: "none", verticalAlign: "middle"}} onClick={moveToElamyksellisyysMob} >
					<ArrowRight  stroke="#D10000"/>
				</Link>
			</div>
		</div>

		</div>);
}
function moveToElamyksellisyysMob()
{
	window.SETELAMYS();
	window.drawEla();
}
/*
			  <line id="Line_17" x2="865" transform="translate(222.5 370.5)" fill="none" stroke="#bf281b" strokeWidth="2"/>
*/

function laskurinappiMob(e)
{
	window.location.assign(
  		"https://www.thredup.com/fashionfootprint/"
	);
}

function sliderChangeMob(v)
{
	//console.log(v);
	if (prevvalueMob == v) return;
	let arrayInd = 0;
	let up = true;
	if (prevvalueMob > v) up = false;
	prevvalueMob = v;
	switch (v)
	{
		case 0: valueChangerMob(window.MURROSTEKSTIT.ekavaihtoehto); 
			break;
		case 50: valueChangerMob(window.MURROSTEKSTIT.tokavaihtoehto); 
			break;
		case 100: valueChangerMob(window.MURROSTEKSTIT.kolmasvaihtoehto); 
			break;
		default: //console.log("Unknown value");
	}
}



export default MurrosMob;
