import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Outlet, Link } from "react-router-dom";
import Footer from "./Footer.js";
import ArrowRight from "./ArrowRight.js";
import HoriLine from "./HoriLine.js";
import BlobLeft from "./BlobLeft.js";
import BlobRight from "./BlobRight.js";
import LinearProgress from "@mui/material/LinearProgress";
import { ThemeProvider } from '@mui/material/styles';
import AsiakaskokemusMob from './AsiakaskokemusMob.js';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';



let stopupdates = function () {};

function Asiakaskokemus(p)
{
	React.useEffect( () => { if ( window.RECENTLOAD )  window.drawAsi() } );
	let [progress, pupdate] = React.useState(0);
	let [progressLp, pupdateLp] = React.useState(0);
	let [nomoreload, nomoreupdate] = React.useState(<div></div>);

	stopupdates = nomoreupdate;

	if (! window.KAIKKIKOKEMUSKUVATLADATTU )
	{
		function loopy()
		{
			if (! window.KAIKKIKOKEMUSKUVATLADATTU )
			{
				setTimeout(loopy, 500);
			}
			else
			{
				let empty = [];
				stopupdates(empty);
			}
			pupdate((window.allmedia.kokemus.length) + " images out of " + window.allmedia.kokemuss);
			pupdateLp(100 * (window.allmedia.kokemus.length/window.allmedia.kokemuss));
		}
		setTimeout(loopy, 1000);
		return(	<ThemeProvider theme={window.MUIthemeWhite}>
			<div className="keskitetty" style={{color: "#FFFFFF", fontSize: "125%"}}>
				<div>Ladataan:</div>
				<div>
    	  				<LinearProgress variant="determinate" value={progressLp} />{nomoreload}
				</div>
			</div></ThemeProvider>);
	}
	else
	{
		if (isMobile && window.NOTIPAD)
		{
			return (<AsiakaskokemusMob />);
		}
		else
		{

			return (<div style={{color: "#D10000", fontSize: "125%"}}>
			<div key="murrososioeka" className="kuvallinenoikea">
				<div>
					<img height="500vh" src={window.ASIAKASKOKEMUSTEKSTIT.ekaosiokuva} />
				</div>
				<div>
					<h1>{window.ASIAKASKOKEMUSTEKSTIT.ekaosio}</h1>
					<div key="gapihmomia" className="otsikkogap"></div>
					{window.ASIAKASKOKEMUSTEKSTIT.ekaosioekar}
					<div key="gapihmomib" className="paragap"></div>
					{window.ASIAKASKOKEMUSTEKSTIT.ekaosiotokar}
				</div>
			</div>
			<HoriLine fromcolor="rgb(244, 225, 220)" tocolor="#FF8888"/>
			<div key="gapihmomic" className="osiogap"></div>
			<div key="murrososiotoka" className="kuvallinenvasenkapea">
				<div style={{width: "80%", display: "flex", alignItems: "center"}}>
					<span style={{verticalAlign: "middle"}} >{window.ASIAKASKOKEMUSTEKSTIT.tokaosioekar}</span>
				</div>
				<div>
					<img height="250vh" src={window.ASIAKASKOKEMUSTEKSTIT.tokaosiokuva} />
				</div>
			</div>		
			<div key="gapihmomid" className="osiogap"></div>

			<div className="vasenlevea">
				<h3>{window.ASIAKASKOKEMUSTEKSTIT.kolmasosio}</h3>
			</div>
			<div key="gapihmomie" className="osiogap"></div>
			<BlobRight fromcolor="rgb(244, 225, 220)" tocolor="#FF8888" />
			<div key="murrosneljasosio" className="kuvallinenoikeavasmargi">
				<div>
					<img height="300vh" src={window.ASIAKASKOKEMUSTEKSTIT.kolmasosiokuva} />
				</div>
				<div></div>
				<div>
					{window.ASIAKASKOKEMUSTEKSTIT.kolmassosioekar}
					<div key="gapihmomif" className="paragap"></div>
					<h3>{window.ASIAKASKOKEMUSTEKSTIT.kolmasosiotokar}</h3>
				</div>
			</div>
			<div key="gapihmomig" className="osiogap"></div>
			<div className="keskitetty"  style={{fontFamily: "gtflexaB"}}>
				{window.ASIAKASKOKEMUSTEKSTIT.aikajana}
			</div>
			<div className="keskitetty">
				<img width="40%" src={window.ASIAKASKOKEMUSTEKSTIT.aikajanakuva} />
			</div>
			<div className="keskitetty">
				<Aikajanasvgkoodi />
			</div>
			<div key="gapihmomigla" className="osiogap"></div>
			<div key="gapihmomiglb" className="osiogap"></div>

			<div className="seuraavasivu">
				<div className="seuraavasivuotsikko" >
					<Link to="../digitaalinenmuoti" style={{textDecoration: "none", verticalAlign: "middle"}} onClick={moveToDigimuoti}>
						<span style={{color: "#D10000", fontSize:"2em", fontWeight:"bold", verticalAlign: "middle"}}>TULEVAISUUS</span>
					</Link>
				</div>
				<div style={{transform: "scale(0.75)"}}>
					<Link to="../digitaalinenmuoti" style={{textDecoration: "none", verticalAlign: "middle"}} onClick={moveToDigimuoti}>
						<ArrowRight stroke="#D10000"/>
					</Link>
				</div>
			</div>
			

			</div>);
		}
	}
}

function moveToDigimuoti()
{
	window.SETMUOTI();
	window.drawMuo();
}

function Aikajanasvgkoodi()
{
return(
<svg width="50%" viewBox="0 0 1050 935.999">
  <defs>
    <clipPath id="clipPathAikajana">
      <rect id="Rectangle_6" width="30" height="30" fill="#d10000"/>
    </clipPath>
  </defs>
  <g id="Group_168" transform="translate(-619 -4028.756)">
    <g id="Group_167" >
      <g id="Group_132" transform="translate(0 244.243)">
        <text id="Saat_sovelluksen_kautta_suorat_ohjeet_reklamaatioprosessin_edistamisesta_tuotteen_palauttamisesta_vaihdosta_tai_toimittamisesta_korjattavaksi._" transform="translate(844 3946.513)" fill="#d10000" fontSize="30" fontFamily="gtflexaR"><tspan x="0" y="32">Saat sovelluksen kautta suorat ohjeet </tspan><tspan x="0" y="70">reklamaatioprosessin edistämisestä, tuotteen </tspan><tspan x="0" y="108">palauttamisesta, vaihdosta tai toimittamisesta </tspan></text>
        <text id="Asiakkaana_teet_reklamaation_kotona_tai_brandin_myymassa_alykkaan_reklamaatiolomakkeen_kautta._"   transform="translate(844 3784.513)" fill="#d10000" fontSize="33" fontFamily="gtflexaR"><tspan x="0" y="36">{window.ASIAKASKOKEMUSTEKSTIT.aikajanaekaeka}</tspan><tspan x="0" y="76">{window.ASIAKASKOKEMUSTEKSTIT.aikajanaekatoka}</tspan></text>
        <text id="Parhaassa_tapauksessa_sujuvaan_prosessiin_tyytyväinen_asiakas_palaa_jatkossakin_asiakkaaksenne._"  transform="translate(844 4607.512)" fill="#d10000" fontSize="30" fontFamily="gtflexaR"><tspan x="0" y="32">Parhaassa tapauksessa sujuvaan prosessiin </tspan><tspan x="0" y="70">tyytyväinen asiakas palaa jatkossakin </tspan></text>
        <text id="Yrityksenä_aikaa_vievä_reklamaatioprosessi_tehostuu_reklamaatiot_ohjautuvat_yhteen_kanavaan_ja_toisaalta_myös_turhat_reklamaatiot_saadaan_kitkettyä._"  transform="translate(844 4366.513)" fill="#d10000" fontSize="33" fontFamily="gtflexaR"><tspan x="0" y="36">Yrityksenä aikaa vievä reklamaatioprosessi </tspan><tspan x="0" y="76">tehostuu, reklamaatiot ohjautuvat yhteen </tspan><tspan x="0" y="116">kanavaan, ja toisaalta myös turhat </tspan><tspan x="0" y="156">reklamaatiot saadaan kitkettyä. </tspan></text>
        <text id="Palvelu_on_ensiluokkainen_ja_sujuva._Kenties_huonosta_kokemuksesta_reklamoinnin_tarve_huolimatta_palaat_jatkossakin_hyvin_palvelevan_yrityksen_asiakkaaksi." transform="translate(844 4136.513)" fill="#d10000" fontSize="30" fontFamily="gtflexaR"><tspan x="0" y="32">Palvelu on ensiluokkainen ja sujuva. Kenties </tspan><tspan x="0" y="70">huonosta kokemuksesta (reklamoinnin tarve) </tspan><tspan x="0" y="108">huolimatta palaat jatkossakin hyvin palvelevan </tspan></text>
      </g>
      <g id="Group_133" transform="translate(0 244.243)">
        <g id="Group_122" transform="translate(624 3946.139)">
          <g id="Group_2" clipPath="url(#clipPathAikajana)">
            <path id="Path_67" d="M30.976,16.01h0A37.651,37.651,0,0,0,16.009,30.977h0A37.651,37.651,0,0,0,1.042,16.01,37.651,37.651,0,0,0,16.009,1.043h0A37.651,37.651,0,0,0,30.976,16.01Z" transform="translate(-1.009 -1.01)" fill="#d10000"/>
          </g>
        </g>
        <g id="Group_127" transform="translate(624 4376.004)">
          <g id="Group_2-2" clipPath="url(#clipPathAikajana)">
            <path id="Path_67-2" d="M30.976,16.01h0A37.651,37.651,0,0,0,16.009,30.977h0A37.651,37.651,0,0,0,1.042,16.01,37.651,37.651,0,0,0,16.009,1.043h0A37.651,37.651,0,0,0,30.976,16.01Z" transform="translate(-1.009 -1.01)" fill="#d10000"/>
          </g>
        </g>
        <g id="Ellipse_21" transform="translate(619 3786.513)" fill="none" stroke="#bf281b" strokeWidth="5">
          <circle cx="20" cy="20" r="20" stroke="none"/>
          <circle cx="20" cy="20" r="17.5" fill="none"/>
        </g>
        <g id="Group_130" transform="translate(639 3826.513)">
          <path id="Path_121" d="M0,0V854.073" transform="translate(0)" fill="none" stroke="#d10000" strokeWidth="3"/>
        </g>
      </g>
    </g>
    <g id="Ellipse_22" transform="translate(619 4924.755)" fill="none" stroke="#bf281b" strokeWidth="5">
      <circle cx="20" cy="20" r="20" stroke="none"/>
      <circle cx="20" cy="20" r="17.5" fill="none"/>
    </g>
  </g>
</svg>


);
}

export default Asiakaskokemus;
