import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Outlet, Link } from "react-router-dom";
import Footer from "./Footer.js";
import ArrowRight from "./ArrowRight.js";
import HoriLine from "./HoriLine.js";
import BlobLeft from "./BlobLeft.js";
import BlobRight from "./BlobRight.js";
import LinearProgress from "@mui/material/LinearProgress";
import { ThemeProvider } from '@mui/material/styles';


function sectionCallbackMob()
{
	/*
	console.log("Play video");
	if (uhkVideoCanPlay)
		if (!videoPlaying) document.getElementById("uhkvideoelementforplayback").play();
	window.SECTIONCALLBACKS = [];
	*/
}

function ihs(f)
{
	return (window.innerHeight * f) + "px";
}



function UhkakuvatMob(p)
{
	window.SECTIONCALLBACKS = [];
	window.SECTIONCALLBACKS.push(sectionCallbackMob);
	return (<div style={{color: "#FFFFFF", fontSize: "100%", textAlign: "center"}}>
		<div className="keskitettymob">
		<h1>{window.UHKAKUVATTEKSTIT.ekaosio}</h1>
		</div>
		<div key="gapihmomia" className="otsikkogap"></div>
		<div className="keskitettymob">
		{window.UHKAKUVATTEKSTIT.ekaosioekar}
		</div>
		<div key="gapihmomib" className="paragap"></div>
		<div className="keskitettymob">
		{window.UHKAKUVATTEKSTIT.ekaosiotokar}
		</div>
		<HoriLine fromcolor="rgb(0, 0, 0)" tocolor="rgb(77,77,77)"/>
		<div key="gapihmomic" className="osiogap"></div>
		<div className="keskitettymob">
			<img height={ihs(0.25)} src={window.UHKAKUVATTEKSTIT.tokaosiokuva} /><br/>
			{window.UHKAKUVATTEKSTIT.tokaosiokuvateksti}
		</div>
		<div style={{ display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "repeat(auto-fit, minmax(100px, auto))", justifyItems: "center", alignItems: "center"}}>
			<div style={{display: "inline-block", verticalAlign: "middle", lineHeight:"1"}}>
				<div className="keskitettymob">
				<h3>{window.UHKAKUVATTEKSTIT.tokaosioekar}</h3>
				</div>
				<div key="gapihmomid" className="osiogap"></div>
				<div className="keskitettymob">{window.UHKAKUVATTEKSTIT.tokaosiotokar}</div>
			</div>
		</div>
		<div key="gapihmomie" className="osiogap"></div>
		<div className="keskitettymob">
		{window.UHKAKUVATTEKSTIT.kolmasosio}
		</div>
		<section id="uhkvideosection"></section>
		<div key="gapihmomif" className="osiogap"></div>


		<div style={{position: "absolute", clipPath: "polygon(16% 7%, 16% 100%, 92% 100%, 92% 7%)" }}>
			{p.uhkvideo}
			</div>
			<div style={{position: "absolute"}}>
				<img height="500vh" src={window.UHKAKUVATTEKSTIT.kolmasosiokuva} />
			</div>
		<div key="gapihmomif" className="osiogap"></div>
		<div key="gapihmomif" className="osiogap"></div>
		<div key="gapihmomif" className="osiogap"></div>
		<div key="gapihmomif" className="osiogap"></div>
		<div key="gapihmomif" className="osiogap"></div>
		<div key="gapihmomif" className="osiogap"></div>
		<div key="gapihmomif" className="osiogap"></div>
		<div key="gapihmomif" className="osiogap"></div>
		<div key="gapihmomif" className="osiogap"></div>
		<div key="gapihmomif" className="osiogap"></div>
		<div key="gapihmomif" className="osiogap"></div>
		<div style={{ display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "repeat(auto-fit, minmax(100px, auto))", justifyItems: "center", alignItems: "center"}}>
			<div style={{display: "inline-block", verticalAlign: "middle", lineHeight:"1"}}>
				<div>{window.UHKAKUVATTEKSTIT.kolmasosioekar}</div>
				<div key="gapihmomig" className="osiogap"></div>
				<h3>{window.UHKAKUVATTEKSTIT.kolmasosiotokar}</h3>
			</div>
		</div>

		<div key="gapihmomihe" className="osiogap"></div>
		<div className="keskitettymob" style={{fontSize:"0.5em"}}>
				{window.UHKAKUVATTEKSTIT.sivunalateksti}
		</div>


		<div className="seuraavasivumob">
			<div className="seuraavasivuotsikko" style={{transform: "scale(0.5)"}}>
				<Link to="../digistrategia" style={{textDecoration: "none", verticalAlign: "middle"}} onClick={moveToDigistrategiaMob}>
					<span style={{color: "#FFFFFF", fontSize:"2em", fontWeight:"bold", verticalAlign: "middle"}}>DIGISTRATEGIA</span>
				</Link>
			</div>
			<div style={{transform: "scale(0.75)"}}>
				<Link to="../digistrategia" style={{textDecoration: "none", verticalAlign: "middle"}} onClick={moveToDigistrategiaMob}>
					<ArrowRight  stroke="#FFFFFF"/>
				</Link>
			</div>
		</div>
				<div key="gapihmomidsiloppu" className="osiogap"></div>
		<div key="gapihmomidsiloppua" className="osiogap"></div>

	</div>);
}
function moveToDigistrategiaMob()
{
	window.SETSTRAT();
	window.drawStr();
}


export default UhkakuvatMob;
