import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Outlet, Link } from "react-router-dom";
import ArrowLeft from "./ArrowLeft.js"
import ArrowRight from "./ArrowRight.js"
import Glide from '@glidejs/glide'
import "@glidejs/glide/src/assets/sass/glide.core.scss";
import "@glidejs/glide/src/assets/sass/glide.theme.scss";
import LinearProgress from "@mui/material/LinearProgress";
import { ThemeProvider } from '@mui/material/styles';




let currentPageMob = 0;
let setPageMob = null;
let refKaruMob = React.createRef();

let skipdrawMob = false;

let glideSetMob = false;

let myGlideMob = null;

function DisableControlsMob(Glide, Components, Events) {
  const PREV_CONTROL_SELECTOR = "[data-glide-dir='<']";
  const NEXT_CONTROL_SELECTOR = "[data-glide-dir='>']";
  const component = {
    buildAfter() {
      this.prevBtn = Components.Html.root.querySelector(PREV_CONTROL_SELECTOR);
      this.nextBtn = Components.Html.root.querySelector(NEXT_CONTROL_SELECTOR);
    },
    handleDisable() {
      const perView = Glide.settings.perView;
      const slidesCount = Components.Html.slides.length;
      let aright = document.getElementsByClassName("mobilekaruarrowright")[0];
      let aleft = document.getElementsByClassName("mobilekaruarrowleft")[0];
      let prevBtnDis =  (Glide.index <= 0)
      let nextBtnDis =  ( Glide.index >= slidesCount - perView)
      if (aright != null)
	{
	if (nextBtnDis) aright.style.transform="scale(0)";
	else aright.style.transform="scale(0.75)";
	}
      if (aleft != null)
	{
	if (prevBtnDis) aleft.style.transform="scale(0)";
	else aleft.style.transform="scale(0.75)";
	}
	/*
	if (this.prevBtn.disabled) this.prevBtn.style.
	else this.prevBtn.style.color="rgba(255,255,255,1)";
	if (this.nextBtn.disabled) this.nextBtn.style.color="rgba(255,255,255,0)";
	else this.nextBtn.style.color="rgba(255,255,255,1)";
	*/
    },
  };

  Events.on("build.after", function () {
    component.buildAfter();
    component.handleDisable();
  });
  Events.on("run.after", function () {
    component.handleDisable();
  });
  return component;
}

let karudrawMob = false;

let stopupdatesMob = function () {};

let setRightArrow = function () {};
let setLeftArrow = function () {};



function myGlideClickNext(event)
{
	if (myGlideMob != null) 
	{
		event.currentTarget.setAttribute("data-glide-dir", ">");
		myGlideMob._c.Controls.click(event);
	}
}

function myGlideClickPrev(event)
{
	if (myGlideMob != null) 
	{
		event.currentTarget.setAttribute("data-glide-dir", "<");
		myGlideMob._c.Controls.click(event);
	}
}

function KaruselliMob(p)
{
	let [page, pageSetter] = React.useState(0);
	currentPageMob = page;
	setPageMob = pageSetter;
//new Glide('.glide').mount();
//new Glide('.glide').mount({ Controls, Breakpoints });
	//console.log("KARUSELLI");
	window.SECTIONCALLBACKS = [];
	React.useEffect( () => { 
		window.drawKar();
		if (karudrawMob)
		{
			if (true)
			{
			myGlideMob = new Glide(".glide", {
			  peek: 50,
			  startAt: 0,
			  perView: 1,
			  type: "slider",
			  focusAt: "center",
			});
			/*
			myGlide.on("run.before", function (e) {console.log("Slide change"); console.log(e); console.log(currentPage); 
								if (e.direction == ">") setPage(currentPage++); 
								else setPage(currentPage--);
								});
			*/
			}

			myGlideMob.mount({ DisableControls: DisableControlsMob });
			//console.log(myGlideMob);
			myGlideMob._c.Anchors.click(">");
			myGlideMob._c.Anchors.click(">");
			//console.log(myGlideMob._c.Anchors.click);
			glideSetMob = true;
		}
	}
		);

	let buttonl = [];
	buttonl.push(
          <div key="karubuttonlmob" style={{border: "0px"}} onClick={myGlideClickPrev}>
	<ArrowLeft key="karuarrowleft" />
          </div>);
	let buttonr = [];
	buttonr.push(
          <div key="karubuttonrmob" style={{border: "0px"}} onClick={myGlideClickNext}>
	<ArrowRight key="karuarrowright" />
         </div>);
	karudrawMob = true;
	 return (<div key="kokokaru">
		<div className="keskitettymob" style={{fontFamily: "gtflexaB", fontSize: "140%", color:"white"}}>Visioita muotialan tulevaisuudesta</div>
		<div style={{height: "3em"}}></div>

		<div key="mobkarurootti">

			<div key="karurootmob" className="glide" data-current-page={page}>
				<div key="karutrackmob" className="glide__track" data-glide-el="track">
				  <ul key="karuulmob" className="glide__slides">
				    <li key="karuliekamob" className="glide__slide"><JohdantoMob /></li>
				    <li key="karulitokamob" className="glide__slide"><NykytilaMob /></li>
				    <li key="karulikolmasmob" className="glide__slide"><TulevaisuusMob /></li>
				  </ul>
				</div>
			</div>
			<div key="karubuttonmob" style={{display:"grid", gridTemplateColumns:"3fr 1fr 3fr"}}>
				<div key="karuarleftmob" className="mobilekaruarrowleft" style={{transform: "scale(0)"}}>
							{buttonl}
				</div>
				<div></div>
				<div key="karuarrightmob" className="mobilekaruarrowright">
							{buttonr}
				</div>
			</div>
      		</div>
	</div>
  );

}
	/*
		<div key="mobkaruforglide" style={{height:"50vh", width:"100%"}}>
		</div>
		<div key="mobkarubuttons" style={{display:"grid", gridTemplateColumns:"1fr 1fr", width: "100%"}}>
		</div>
*/

function JohdantoMob(p)
{
	//skipdraw = true; 
	window.PAGEBEGIN();
	return JohdantoGeneerinenMob(false, true, "johdanto.png", "JOHDANTO");
}

function NykytilaMob(p)
{
	skipdrawMob = true; 
	window.PAGEBEGIN();
	return JohdantoGeneerinenMob(true, true, "nykytila.png", "NYKYTILA");
}

function TulevaisuusMob(p)
{
	skipdrawMob = true; 
	window.PAGEBEGIN();
	return JohdantoGeneerinenMob(true, false, "tulevaisuus.png", "TULEVAISUUS");
}

function moveToMurrosMob()
{
	skipdrawMob = false; 
	window.SETMURROS();
	window.drawMur();
}

function moveToDigimuotiMob()
{
	skipdrawMob = false; 
	window.SETDIGIMUOTI();
	window.drawMur();
}

function moveToJohdantoMob()
{
	skipdrawMob = false; 
	window.SETJOHDANTO();
	window.drawJoh();
}

function JohdantoGeneerinenMob(vasen, oikea, kuva, teksti, kohde)
{
	let vasent = [];
	let oikeat = [];
	vasen = false;
	oikea = false;
	if (vasen) vasent.push(<ArrowLeft onClick={() => { if (refKaruMob.current) refKaruMob.current.slidePrev(); }}/>);
	if (oikea) oikeat.push(<ArrowRight onClick={() => { if (refKaruMob.current) refKaruMob.current.slideNext(); }}/>);
	let tekstit = [];
	let kuvat = [];
	
	if (teksti == "JOHDANTO") 
	{
		tekstit.push(
		<Link key="karujohdantolink" to="johdanto" style={{textDecoration: "none"}}>
			<div key="karujohdantodivi" className="johdantootsikko" key={"johdanto"+kohde} style={{color:"white", fontSize:"2em", fontWeight:"bold"}} onClick={moveToJohdantoMob}>
			{teksti}
			</div>
		</Link>
		);
		kuvat.push(<Link key="karujohdantolinki" to="johdanto" style={{textDecoration: "none"}}>
			<div key="karujohdantodivit" className="johdantootsikkokuva" key={"johdanto"+kohde+"click"} onClick={moveToJohdantoMob}>
				<img key="karukuvaeka" width="250vw" src={kuva} />
			</div></Link>);
	}
	if (teksti == "NYKYTILA") 
	{
		tekstit.push(
		<Link key="karunykylink" to="kuluttamisenmurros" style={{textDecoration: "none"}}>
			<div key="karunykydivi" className="johdantootsikko" style={{color:"white", fontSize:"2em", fontWeight:"bold"}} onClick={moveToMurrosMob}>
			{teksti}
			</div>
		</Link>
		);
		kuvat.push(<Link  key="karunykylinkt" to="kuluttamisenmurros" style={{textDecoration: "none"}}>
			<div key="karunykydivit" className="johdantootsikkokuva" onClick={moveToMurrosMob}>
				<img key="karukuvatoka" width="250vw" src={kuva} />
			</div>
		</Link>);
	}
	if (teksti == "TULEVAISUUS") 
	{
		tekstit.push(
		<Link key="karutulelink" to="digitaalinenmuoti" style={{textDecoration: "none"}}>
			<div key="tuledivi" className="johdantootsikko" style={{color:"white", fontSize:"2em", fontWeight:"bold"}} onClick={moveToDigimuotiMob}>
			{teksti}
			</div>
		</Link>
		);
		kuvat.push(<Link key="karutulelinki" to="digitaalinenmuoti" style={{textDecoration: "none"}}>
			<div key="karutuledivit" className="johdantootsikkokuva" onClick={moveToDigimuotiMob}>
				<img key="karukuvakolmas" width="250vw" src={kuva} />
			</div>
		</Link>
		);
	}
	return (<div key="karusuperdivi">
			<div key="karusupidivi" className="mainmenumid">
				<div key="karusupsupdivi">{vasent}</div>
				{kuvat}
				<div key="karusuppusdivi">{oikeat}</div>
			</div>
			<div key="karusupidivija" className="mainmenumid">
					<div key="karutyhjadivieka"></div>
					{tekstit}
					<div key="karutyhjadivitoka"></div>
			</div>
		</div>);
}
export default KaruselliMob;
