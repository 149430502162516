import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Outlet, Link } from "react-router-dom";
import Footer from "./Footer.js";
import ArrowRight from "./ArrowRight.js";
import HoriLine from "./HoriLine.js";
import BlobLeft from "./BlobLeft.js";
import BlobRight from "./BlobRight.js";
import Slider from "rc-slider";
import { createSliderWithTooltip } from "rc-slider";
import LinearProgress from "@mui/material/LinearProgress";
import { ThemeProvider } from '@mui/material/styles';
import DigistrategiaMob from './DigistrategiaMob.js';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';




let valueChanger = function () {};
let taustaChangers = [];
let tekstiChangers = [];
let taustaInChangers = [];
let tekstiInChangers = [];


let strvideo = [];

strvideo.push( <video key="strvideo" width="auto" height="450vh" autoPlay={true} muted={true} mute="true" playsInline={true} loop={true} disablePictureInPicture={true} onCanPlay={strCanPlay} src={window.DIGISTRATEGIATEKSTIT.kolmasosiovideo} type="video/mp4" /> );

function strCanPlay(e)
{
	//console.log(e);
	//console.log("I can now play in Str");
	window.strVideoCanPlay = true;
}

let firstStrLoad = true;

let stopupdates = function () {};


function Digistrategia(p)
{
	React.useEffect( () => { if ( window.RECENTLOAD ) window.drawStr() } );
	let [chosenValue, valueChooser] = React.useState(window.DIGISTRATEGIATEKSTIT.grafiikkaekapalkkiseli);
	let [tokatausta, tokataustaChooser] = React.useState("#fff");
	let [kolmastausta, kolmastaustaChooser] = React.useState("#fff");
	let [neljastausta, neljastaustaChooser] = React.useState("#fff");
	let [viidestausta, viidestaustaChooser] = React.useState("#fff");
	let [tokateksti, tokatekstiChooser] = React.useState("#000");
	let [kolmasteksti, kolmastekstiChooser] = React.useState("#000");
	let [neljasteksti, neljastekstiChooser] = React.useState("#000");
	let [viidesteksti, viidestekstiChooser] = React.useState("#000");
	let [progress, pupdate] = React.useState(0);
	let [progressLp, pupdateLp] = React.useState(0);
	let [nomoreload, nomoreupdate] = React.useState(<div></div>);

	stopupdates = nomoreupdate;


	if (! (window.KAIKKISTRATEGIAKUVATLADATTU && window.strVideoCanPlay) )
	{
		let loopy = () =>
		{
			if (! (window.KAIKKISTRATEGIAKUVATLADATTU && window.strVideoCanPlay) )
			{
				//console.log("Kaikki " + window.KAIKKISTRATEGIAKUVATLADATTU + " video " + window.strVideoCanPlay);
				setTimeout(loopy, 500);
			}
			else
			{
				//console.log("No more loopy");
				let empty = [];
				stopupdates(empty);

			}
			pupdate((window.allmedia.strategia.length + window.allmedia.strategiav.length) + " images out of " + window.allmedia.strategias);
			let addition = 0;
			if (window.strVideoCanPlay) addition = 10;
			pupdateLp((90 * (window.allmedia.strategia.length/window.allmedia.strategias)) + addition );
		};
		setTimeout(loopy, 1000);
		let cont = [];
		if (firstStrLoad) firstStrLoad = false;
		else cont = strvideo;
		return(	<div><ThemeProvider theme={window.MUIthemeWhite}>
			<div className="keskitetty" style={{color: "#FFFFFF", fontSize: "125%"}}>
				<div>Ladataan:</div>
				<div>
    	  				<LinearProgress variant="determinate" value={progressLp} />
				</div>
			</div></ThemeProvider><div style={{ display:"none" }}> {cont} {nomoreload} </div> </div>);
	}
	else
	{
		if (isMobile && window.NOTIPAD)
		{
			return (<DigistrategiaMob strvideo={strvideo}/>);
		}
		else
		{


			valueChanger = valueChooser;
			taustaChangers.push(tokataustaChooser);
			taustaInChangers.push(tokataustaChooser);
			taustaChangers.push(kolmastaustaChooser);
			taustaInChangers.push(kolmastaustaChooser);
			taustaChangers.push(neljastaustaChooser);
			taustaInChangers.push(neljastaustaChooser);
			taustaChangers.push(viidestaustaChooser);
			taustaInChangers.push(viidestaustaChooser);
			tekstiChangers.push(tokatekstiChooser);
			tekstiInChangers.push(tokatekstiChooser);
			tekstiChangers.push(kolmastekstiChooser);
			tekstiInChangers.push(kolmastekstiChooser);
			tekstiChangers.push(neljastekstiChooser);
			tekstiInChangers.push(neljastekstiChooser);
			tekstiChangers.push(viidestekstiChooser);
			tekstiInChangers.push(viidestekstiChooser);
			let marks = {
				10: " ",
				30: " ",
				50: " ",
				70: " ",
				90: " "
			};
			//let vsrc = window.allmedia.strategiav[0].src;

			return (<div style={{color: "#FFFFFF", fontSize: "125%"}}>
				<div key="stratosioeka" className="kuvallinenvasen">
					<div>
						<h1>{window.DIGISTRATEGIATEKSTIT.ekaosio}</h1>
						<div key="gapihmomia" className="otsikkogap"></div>
						{window.DIGISTRATEGIATEKSTIT.ekaosioekar}
						<div key="gapihmomib" className="paragap"></div>
						{window.DIGISTRATEGIATEKSTIT.ekaosiotokar}
					</div>
					<div></div><div></div>
				</div>
				<HoriLine fromcolor="rgb(0, 0, 0)" tocolor="rgb(77,77,77)"/>
				<div key="gapihmomic" className="osiogap"></div>
				<div key="stratosiotoka" className="kuvallinenvasen">
					<div style={{ display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "repeat(auto-fit, minmax(100px, auto))", justifyItems: "center", alignItems: "center"}}>
						<div style={{display: "inline-block", verticalAlign: "middle", lineHeight:"1"}}>
							<h3>{window.DIGISTRATEGIATEKSTIT.tokaosioekar}</h3>
						</div>
					</div>
					<div>
						<img height="300vh" src={window.DIGISTRATEGIATEKSTIT.tokaosiokuva} /><br/>
						{window.DIGISTRATEGIATEKSTIT.tokaosiokuvateksti}
					</div>
				</div>
				<BlobLeft fromcolor="rgb(0, 0, 0)" tocolor="rgb(77,77,77)" />
				<div key="gapihmomid" className="osiogap"></div>
				<div key="stratosiotokab" className="kuvallinenoikeavasmargi">
					<div>
						<div style={{position: "absolute", clipPath: "polygon(16% 7%, 16% 100%, 92% 100%, 92% 7%)" }}> 
							{strvideo}
						</div>
						<div style={{position: "absolute"}}>
							<img height="500vh" src={window.DIGISTRATEGIATEKSTIT.kolmasosiokuva} />
						</div>
					</div>
					<div></div>
					<div style={{ display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "repeat(auto-fit, minmax(100px, auto))", justifyItems: "center", alignItems: "center"}}>
						<div style={{height:"10vh"}}></div>
						<div style={{display: "inline-block", verticalAlign: "middle", lineHeight:"1"}}>
							<div>{window.DIGISTRATEGIATEKSTIT.kolmasosioekar}</div>
							<div key="gapihmomie" className="osiogap"></div>
							<h3>{window.DIGISTRATEGIATEKSTIT.kolmasosiotokar}</h3>
						</div>
					</div>
				</div>
				<div key="gapihmomif" className="osiogap"></div>
				<div key="gapihmomifa" className="osiogap"></div>
				<div key="gapihmomifb" className="osiogap"></div>
				<div key="gapihmomifc" className="osiogap"></div>
				<div key="gapihmomifba" className="osiogap"></div>
				<div key="gapihmomifcb" className="osiogap"></div>


				<div key="stratosiotokac" className="kuvallinenoikeavasmargi">
					<div>
						<img height="300vh" src={window.DIGISTRATEGIATEKSTIT.neljasosiokuva} />
					</div>
					<div></div>
					<div style={{ display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "repeat(auto-fit, minmax(100px, auto))", justifyItems: "center", alignItems: "center"}}>
						<div style={{display: "inline-block", verticalAlign: "middle", lineHeight:"1"}}>
							<h3>{window.DIGISTRATEGIATEKSTIT.neljasosio}</h3>
							<div key="gapihmomig" className="osiogap"></div>
							<div>{window.DIGISTRATEGIATEKSTIT.neljasosioekar}</div>
						</div>
					</div>
				</div>
				<div key="gapihmomih" className="osiogap"></div>
				<div key="stratosioekad" className="kuvallinenvasen">
					<div>
						<h2>{window.DIGISTRATEGIATEKSTIT.viidesosio}</h2>
						<div key="gapihmomii" className="otsikkogap"></div>
						{window.DIGISTRATEGIATEKSTIT.viidesosioekar}
						<div key="gapihmomij" className="paragap"></div>
						{window.DIGISTRATEGIATEKSTIT.viidesosiotokar}
					</div>
					<div></div><div></div>
				</div>

				<div key="stratosiotokaf" className="kuvallinenoikeavasmargi" style={{backgroundSize: "100% 100%", backgroundImage: "url(\"lenkkaritausta.svg\")", backgroundRepeat: "no-repeat"}}>
					<div>
					</div>
					<div></div>
					<div style={{ display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "repeat(auto-fit, minmax(100px, auto))", justifyItems: "center", alignItems: "center"}}>
						<div className="lenkkari" style={{transform: "translate(-5em, -1em)"}}>
							<img height="600vh" src={window.DIGISTRATEGIATEKSTIT.kuudesosiokuva} />			
						</div>
					</div>
				</div>

				<div key="stratosiotokag" className="kuvallinenoikeavasmargi">
					<div>
					</div>
					<div></div>
					<div style={{ display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "repeat(auto-fit, minmax(100px, auto))", justifyItems: "center", alignItems: "center"}}>
						<div style={{display: "inline-block", verticalAlign: "middle", lineHeight:"1"}}>
							<div>{window.DIGISTRATEGIATEKSTIT.kuudesosioekar}</div>
							<div key="gapihmomik" className="osiogap"></div>
							<div>{window.DIGISTRATEGIATEKSTIT.kuudesosiotokar}</div>
						</div>
					</div>
				</div>

				<div key="gapihmomil" className="osiogap"></div>
				
				<div key="stratosiotokai" className="keskitetty">
					<div style={{display: "inline-block", verticalAlign: "middle", lineHeight:"1", width:"100%"}}>
							<div style={{fontFamily: "gtflexaB"}}>{window.DIGISTRATEGIATEKSTIT.grafiikkaosio}</div>
							<div key="gapihmomilpalk" className="osiogap"></div>
							<div className="chart" style={{display: "grid", gridTemplateColumns: "1% 18% 2% 18% 2% 18% 2% 18% 2% 18% 1%", justifyItems: "center", alignItems: "end"}}>
								<div key="tyhja1"></div>
								<div key="palkkivasen"><Palkkivasen  key="palkkivi" fill="#000" texteka={window.DIGISTRATEGIATEKSTIT.grafiikkaekapalkkieka} texttoka={window.DIGISTRATEGIATEKSTIT.grafiikkaekapalkkitoka} tfill="#fff"/></div>
								<div key="tyhja2"></div>
								<div key="palkkivasentoi"><Palkkivasentoi  key="palkkivti" fill={tokatausta} texteka={window.DIGISTRATEGIATEKSTIT.grafiikkatokapalkkieka} texttoka={window.DIGISTRATEGIATEKSTIT.grafiikkatokapalkkitoka} tfill={tokateksti}/></div>
								<div key="tyhja3"></div>
								<div key="palkkikeski"><Palkkikeski  key="palkkike" fill={kolmastausta} texteka={window.DIGISTRATEGIATEKSTIT.grafiikkakolmaspalkkieka} texttoka={window.DIGISTRATEGIATEKSTIT.grafiikkakolmaspalkkitoka} tfill={kolmasteksti}/></div>
								<div key="tyhja4"></div>
								<div key="palkkioikeatoi"><Palkkioikeatoi  key="palkkiot" fill={neljastausta} texteka={window.DIGISTRATEGIATEKSTIT.grafiikkaneljaspalkkieka} texttoka={window.DIGISTRATEGIATEKSTIT.grafiikkaneljaspalkkitoka} tfill={neljasteksti}/></div>
								<div key="tyhja5"></div>
								<div key="palkkioikea"><Palkkioikea  key="palkkio" fill={viidestausta} texteka={window.DIGISTRATEGIATEKSTIT.grafiikkaviidespalkkieka} texttoka={window.DIGISTRATEGIATEKSTIT.grafiikkaviidespalkkitoka} tfill={viidesteksti}/></div>
								<div key="tyhja6"></div>
							</div>
							<Slider dots min={0} marks={marks} step={null} defaultValue={10} minValue={10} maxValue={90} onChange={sliderChange}/>
				<div key="gapihmomim" className="osiogap"></div>
				<div key="gapihmomin" className="osiogap"></div>
							<div>{chosenValue}</div>
					</div>
				</div>
				

				<div key="gapihmomio" className="osiogap"></div>
		<div key="gapihmomihe" className="osiogap"></div>
		<div className="vasen" style={{fontSize:"0.5em"}}>
				{window.DIGISTRATEGIATEKSTIT.sivunalateksti}
		</div>


				<div className="seuraavasivu">
					<div className="seuraavasivuotsikko">
						<Link to="../" style={{textDecoration: "none", verticalAlign: "middle"}} onClick={moveToKaruselli}>
							<span style={{color: "#FFFFFF", fontSize:"2em", fontWeight:"bold", verticalAlign: "middle"}}>ALKUUN</span>
						</Link>
					</div>
					<div style={{transform: "scale(0.75)"}}>
						<Link to="../" style={{textDecoration: "none", verticalAlign: "middle"}} onClick={moveToKaruselli}>
							<ArrowRight stroke="#FFFFFF"/>
						</Link>
					</div>
				</div>
				

			</div>);
		}
	}
}

let prevvalue = 10;

function sliderChange(v)
{
	//console.log(v);
	if (prevvalue == v) return;
	let arrayInd = 0;
	let up = true;
	if (prevvalue > v) up = false;
	prevvalue = v;
	switch (v)
	{
		case 10: valueChanger(window.DIGISTRATEGIATEKSTIT.grafiikkaekapalkkiseli); 
			arrayInd = 0;
			break;
		case 30: valueChanger(window.DIGISTRATEGIATEKSTIT.grafiikkatokapalkkiseli); 
			arrayInd = 1;
			break;
		case 50: valueChanger(window.DIGISTRATEGIATEKSTIT.grafiikkakolmaspalkkiseli); 
			arrayInd = 2;
			break;
		case 70: valueChanger(window.DIGISTRATEGIATEKSTIT.grafiikkaneljaspalkkiseli); 
			arrayInd = 3;
			break;
		case 90: valueChanger(window.DIGISTRATEGIATEKSTIT.grafiikkaviidespalkkiseli); 
			arrayInd = 4;
			break;
		default: //console.log("Unknown value");
	}
	//console.log("array ind at " + arrayInd);
	if (up)
	{
		//console.log("Color in up order to " + arrayInd);
		for (let i = 0; i < arrayInd; i++)
		{
			//console.log("Change " + i + " to whiteonblack");
			tekstiChangers[i]("#fff");
			taustaChangers[i]("#000");
			tekstiInChangers[i]("#fff");
			taustaInChangers[i]("#000");
			//console.log("Changed");
		}
	}
	else
	{
		//console.log("Color in down order to " +  arrayInd);
		for (let i = 3; i >= arrayInd; i--)
		{
			//console.log("Change " + i + " to blackonwhite");
			tekstiChangers[i]("#000");
			taustaChangers[i]("#fff");
			tekstiInChangers[i]("#000");
			taustaInChangers[i]("#fff");
			//console.log("Changed");
		}
	}
}

/*
					<div className="chart" style={{display: "grid", gridTemplateColumns: "1% 18% 2% 18% 2% 18% 2% 18% 2% 18% 1%", justifyItems: "center", alignItems: "start"}}>
						<div></div>
						<div><Sormi fill="#fff"/></div>
						<div></div>
						<div><Sormi fill="none"/></div>
						<div></div>
						<div><Sormi fill="none"/></div>
						<div></div>
						<div><Sormi fill="none"/></div>
						<div></div>
						<div><Sormi fill="none"/></div>
						<div></div>
					</div>

*/


function moveToKaruselli()
{
	window.SETKARUSELLI();
	window.drawKar();
}

function Palkkioikea(p)
{
	let [tausta, taustaChooser] = React.useState(p.fill);
	let [teksti, tekstiChooser] = React.useState(p.tfill);
	taustaInChangers[3] = taustaChooser;
	tekstiInChangers[3] = tekstiChooser;
return(
<svg width="100%" height="auto" viewBox="0 0 232 500">
  <g id="Group_183" transform="translate(-1356 -5449)">
    <g id="Rectangle_81" transform="translate(1356 5449)" fill={tausta} stroke="#fff" strokeWidth="2">
      <rect width="232" height="500" stroke="#fff"/>
      <rect x="1" y="1" width="230" height="498" fill={tausta}/>
    </g>
    <text id="Strateginen_edell�k�vijyys_" transform="translate(1470 5479)" fill={teksti}  fontSize="26" fontFamily="gtflexaR"><tspan x="-65.546" y="28">{p.texteka}</tspan><tspan x="-77.86" y="63">{p.texttoka}</tspan><tspan fontFamily="GTFlexa-Rg, GT Flexa"><tspan x="0" y="98"></tspan></tspan></text>
  </g>
</svg>

)
}

function Palkkioikeatoi(p)
{
	let [tausta, taustaChooser] = React.useState(p.fill);
	let [teksti, tekstiChooser] = React.useState(p.tfill);
	taustaInChangers[2] = taustaChooser;
	tekstiInChangers[2] = tekstiChooser;
return(
<svg width="100%" height="auto" viewBox="0 0 232 400">
  <g id="Group_182" transform="translate(-1100 -5549)">
    <g id="Rectangle_74" transform="translate(1100 5549)" fill={tausta} stroke="#fff" strokeWidth="2">
      <rect width="232" height="400" stroke="#fff"/>
      <rect x="1" y="1" width="230" height="398" fill={tausta}/>
    </g>
    <text id="Liiketoiminta-mahdollisuudet" transform="translate(1215 5579)" fill={teksti} fontSize="26" fontFamily="gtflexaR"><tspan x="-78.768" y="28">{p.texteka}</tspan><tspan x="-88.975" y="63">{p.texttoka}</tspan></text>
  </g>
</svg>
)
}

function Palkkikeski(p)
{
	let [tausta, taustaChooser] = React.useState(p.fill);
	let [teksti, tekstiChooser] = React.useState(p.tfill);
	taustaInChangers[1] = taustaChooser;
	tekstiInChangers[1] = tekstiChooser;
return(
<svg width="100%" height="auto" viewBox="0 0 232 350">
  <g id="Group_180" transform="translate(-844 -5599)">
    <g id="Group_179" >
      <g id="Rectangle_77" transform="translate(844 5599)" fill={tausta} stroke="#fff" strokeWidth="2">
        <rect width="232" height="350" stroke="#fff"/>
        <rect x="1" y="1" width="230" height="348" fill={tausta}/>
      </g>
    </g>
    <text id="Tehokkuuden_lis��minen_" transform="translate(960 5629)" fill={teksti}  fontSize="26" fontFamily="gtflexaR"><tspan x="-76.762" y="28">{p.texteka}</tspan><tspan x="-60.899" y="63">{p.texttoka}</tspan><tspan fontFamily="GTFlexa-Rg, GT Flexa"><tspan x="0" y="98"></tspan></tspan></text>
  </g>
</svg>
)
}

function Palkkivasentoi(p)
{
	let [tausta, taustaChooser] = React.useState(p.fill);
	let [teksti, tekstiChooser] = React.useState(p.tfill);
	taustaInChangers[0] = taustaChooser;
	tekstiInChangers[0] = tekstiChooser;
return(
<svg width="100%" height="auto" viewBox="0 0 232 200">
  <g id="Group_181" transform="translate(-588 -5749)">
    <g id="Rectangle_79" transform="translate(588 5749)" fill={tausta} stroke="#fff" strokeWidth="2">
      <rect width="232" height="200" stroke="#fff"/>
      <rect x="1" y="1" width="230" height="198" fill={tausta}/>
    </g>
    <text id="Riskien_hallinta_" transform="translate(703 5779)" fill={teksti} fontSize="26" fontFamily="gtflexaR"><tspan x="-85.56" y="28">{p.texteka}</tspan><tspan fontFamily="GTFlexa-Rg, GT Flexa"><tspan x="0" y="63"></tspan></tspan></text>
  </g>
</svg>
)
}

function Palkkivasen(p)
{
return(
<svg width="100%" height="auto" viewBox="0 0 232 150">
  <g id="Group_175" transform="translate(-332 -5799)">
    <g id="Rectangle_80" transform="translate(332 5799)" fill={p.fill} stroke="#fff" strokeWidth="2">
      <rect width="232" height="150" stroke="none"/>
      <rect x="1" y="1" width="230" height="148" fill="none"/>
    </g>
    <text id="Kielt�ytyminen_ja_minimointi" transform="translate(347 5826)" fill={p.tfill} fontSize="26" fontFamily="gtflexaR"><tspan x="0.676" y="28">{p.texteka}</tspan><tspan x="36.775" y="63">{p.texttoka}</tspan></text>
  </g>
</svg>
)
}




export default Digistrategia;
