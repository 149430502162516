

function HoriLine(p)
{
	let rand = Math.floor(Math.random(10000000));
	return(  <svg width="100%" height="5%">
		<defs>
    			<linearGradient id={"horilinegradient"+rand} x1="50%" y1="0%" x2="50%" y2="100%">
      			<stop offset="0%" style={{stopColor:p.fromcolor,stopOpacity:"0.1"}} />
      			<stop offset="50%" style={{stopColor:p.tocolor,stopOpacity:"0.5"}} />
			<stop offset="100%" style={{stopColor:p.fromcolor,stopOpacity:"0.1"}} />
    			</linearGradient>
  			</defs>
  			<rect width="100%" height="100%" style={{fill:"url(#horilinegradient"+rand+")", strokeWidth:"0", stroke:"rgb(0,0,0)"}} />
		</svg> ); 	
}


export default HoriLine;
