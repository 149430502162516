import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';


function BlobLeft(p)
{
	let rand = Math.floor(Math.random()*10000000);
	let randSec = Math.floor(Math.random()*10000000);
	let scale = "1";
	let width = "200vh";
	let height = "200vh";
	if (p.height != null) height = p.height;
	if (p.width != null) width = p.width;
	if (p.scale != null) scale = p.scale;
	let styobj = {position: "absolute", left:"0%", zIndex: -1, transform: "scale(" + scale +")", width: "100%", overflow: "hidden"}
	if (isMobile && window.NOTIPAD) styobj = {position: "absolute", left:"0%", zIndex: -1, transform: "scale(" + scale +")"}
	return(  <div style={styobj} >
		<svg width={width} height={height}>
		<defs>
    			<radialGradient id={"blobgradient"+rand} >
      			<stop offset="0%" style={{stopColor:p.tocolor,stopOpacity:"1"}} />
			<stop offset="40%" style={{stopColor:p.tocolor,stopOpacity:"0.7"}} />
			<stop offset="100%" style={{stopColor:p.fromcolor,stopOpacity:"0.0"}} />
    			</radialGradient>
			<radialGradient id={"blobgradient"+randSec} >
      			<stop offset="0%" style={{stopColor:p.tocolor,stopOpacity:"0"}} />
			<stop offset="100%" style={{stopColor:p.fromcolor,stopOpacity:"0"}} />
    			</radialGradient>
  			</defs>
			<ellipse cx="0%" cy="50%" rx="50%" ry="50%" fill={"url(#blobgradient"+rand+")"} />
			<ellipse cx="30%" cy="80%" rx="10%" ry="10%" fill={"url(#blobgradient"+randSec+")"} />
		</svg> </div>); 	
}


export default BlobLeft;
