import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Outlet, Link } from "react-router-dom";
import Footer from "./Footer.js";
import ArrowRight from "./ArrowRight.js";
import ArrowLeft from "./ArrowLeft.js";
import HoriLine from "./HoriLine.js";
import BlobLeft from "./BlobLeft.js";
import BlobRight from "./BlobRight.js";
import CoverFlow from "react-coverflow";
import Box from '@mui/material/Box';
import LinearProgress from "@mui/material/LinearProgress";
import { ThemeProvider } from '@mui/material/styles';





let coverrefMob = [];

function coverClickLeftMob(e)
{
	if (coverrefMob.current.state.current > 1) 
	{
		coverrefMob.current._handlePrevFigure(e);
	}
	else 
	{
		//console.log("NOK" + coverrefMob.current.state.current);
	}
	if (coverrefMob.current.state.current < 3) 
	{
		//console.log("Disable right");
		document.getElementById("hideleftarrow").style.display = "none";
	}
	else
	{
		//console.log("Enable left");
		document.getElementById("hiderightarrow").style.display = "inline";
	}
}
function coverClickRightMob(e)
{
	if (coverrefMob.current.state.current < 4) 
	{
		coverrefMob.current._handleNextFigure(e);
	}
	else 
	{
		//console.log("NOK " + coverrefMob.current.state.current);
	}
	if (coverrefMob.current.state.current > 2) 
	{
		//console.log("Disable left");
		document.getElementById("hiderightarrow").style.display = "none";
	}
	else
	{
		//console.log("Enable right");
		document.getElementById("hideleftarrow").style.display = "inline";
	}
}

function updatecoverref()
{
	//console.log("Coverref updated");
	//console.log(coverrefMob.current.refs.stage);
	coverrefMob.current.refs.stage.style.height="350px";
}

let elavideo = [];


function ihs(f)
{
	return (window.innerHeight * f) + "px";
}


function ElamyksellisyysMob(p)
{
		React.useEffect(updatecoverref);
		elavideo = p.elavideo;
		let curWidth = "" + (window.innerWidth -  100) + "px";
		//console.log(curWidth);
		//console.log(window.allmedia.elamysv);
		//let vsrc = window.allmedia.elamysv[0].src;
		return(<div style={{color: "#D10000", fontSize: "100%", textAlign: "center"}}>
		<div className="keskitettymob">
			<img height={ihs(0.25)} src={window.ELAMYKSELLISYYSTEKSTIT.ekaosiokuvamob} />
		</div>
		<div className="keskitettymob">
			<h1>{window.ELAMYKSELLISYYSTEKSTIT.ekaosio}</h1>
			<div key="gapihmomia" className="otsikkogap"></div>
			{window.ELAMYKSELLISYYSTEKSTIT.ekaosioekar}
			<div key="gapihmomib" className="paragap"></div>
			{window.ELAMYKSELLISYYSTEKSTIT.ekaosiotokar}
		</div>
		<HoriLine fromcolor="rgb(244, 225, 220)" tocolor="#FF8888"/>
		<div className="keskitettymob">
		<h3>{window.ELAMYKSELLISYYSTEKSTIT.tokaosioekar}</h3>
		</div>
		<BlobLeft fromcolor="rgb(244, 225, 220)" tocolor="#FF8888" width="40vh" height="100vh"/>
		<div>
			<div style={{position: "absolute",
					clipPath: "polygon(16% 7%, 16% 100%, 92% 100%, 92% 7%)"
}}>
				{elavideo}
			</div>
			<div style={{position: "absolute"}}>
				<img height="500vh" src={window.ELAMYKSELLISYYSTEKSTIT.tokaosiokuva} />
			</div>
		</div>
		<div key="gapihmomid" className="osiogap"></div>
		<div key="gapihmomid" className="osiogap"></div>
		<div key="gapihmomid" className="osiogap"></div>
		<div key="gapihmomid" className="osiogap"></div>
		<div key="gapihmomid" className="osiogap"></div>
		<div key="gapihmomid" className="osiogap"></div>
		<div key="gapihmomid" className="osiogap"></div>
		<div key="gapihmomid" className="osiogap"></div>
		<div key="gapihmomid" className="osiogap"></div>
		<div key="gapihmomid" className="osiogap"></div>
		<div key="gapihmomid" className="osiogap"></div>
		<div style={{ display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "repeat(auto-fit, minmax(100px, auto))", justifyItems: "center", alignItems: "center"}} >
			<div style={{display: "inline-block", verticalAlign: "middle", lineHeight:"1"}}>
				<div className="keskitettymob">
				{window.ELAMYKSELLISYYSTEKSTIT.tokaosiotokar}
				</div>
				<div className="keskitettymob">
				<h3>
				{window.ELAMYKSELLISYYSTEKSTIT.tokaosiokolmasr}
				</h3>
				</div>
			</div>
		</div>
		<div key="gapihmomik" className="osiogap"></div>
		<div className="keskitettymob">
		{window.ELAMYKSELLISYYSTEKSTIT.neljasosioekar}
		</div>
		<div key="gapihmomil" className="osiogap"></div>
		<div className="keskitettymob" style={{fontFamily: "gtflexaB"}}>
			{window.ELAMYKSELLISYYSTEKSTIT.neljasosiotokar}
		</div>
		<div key="wrapperflowmob">
			<div id="unmountcoverflow" key="unmountcoverflowmob">
			<CoverFlow ref={coverrefMob}
				displayQuantityOfSide={1}
				navigation={false}
				enableClick={false}
				enableHeading={false}
				media={{background: "rgba(0,0,0,0)"}}
				otherFigureScale={0.5}
				currentFigureScale={0.75}
				>
					<div role="menuitem"></div>
				      <img
					src={window.ELAMYKSELLISYYSTEKSTIT.ekakorttikuva}
					alt='title or description'
					style={{ display: 'block', height: '100%' }}
				      />
				      <img
					src={window.ELAMYKSELLISYYSTEKSTIT.tokakorttikuva}
					alt='title or description'
					style={{ display: 'block', height: '100%' }}
				      />
				      <img
					src={window.ELAMYKSELLISYYSTEKSTIT.kolmaskorttikuva}
					alt='title or description'
					style={{ display: 'block', height: '100%' }}
				      />
				      <img
					src={window.ELAMYKSELLISYYSTEKSTIT.neljaskorttikuva}
					alt='title or description'
					style={{ display: 'block', height: '100%' }}
				      />
			</CoverFlow>
			</div>
		</div>
		<div style={{display:"grid", gridTemplateColumns: "5% 20% 27.5% 20% 27.5%", zIndex: "2147483620", transform:"translateY(-75px)"}}>
			<div></div>
			<div key="arrowleftkaru">
				<div id="hideleftarrow">
				<MuotiPrevMob onClick={coverClickLeftMob} scale={0.75} stroke="#D10000"/>
				</div>
			</div>
			<div>
			</div>
			<div key="arrowrightkaru">
				<div id="hiderightarrow">
				<MuotiNextMob onClick={coverClickRightMob} scale={0.75} stroke="#D10000"/>
				</div>
			</div>
			<div></div>
		</div>
		<div className="seuraavasivumob">
			<div className="seuraavasivuotsikko" style={{transform: "scale(0.5)"}}>
				<Link to="../asiakaskokemus" style={{textDecoration: "none", verticalAlign: "middle"}} onClick={moveToAsiakaskokemusMob} >
					<span style={{color: "#D10000", fontSize:"2em", fontWeight:"bold", verticalAlign: "middle"}}>ASIAKASKOKEMUS</span>
				</Link>
			</div>
			<div style={{transform: "scale(0.75)"}}>
				<Link to="../asiakaskokemus" style={{textDecoration: "none", verticalAlign: "middle"}} onClick={moveToAsiakaskokemusMob} >
					<ArrowRight  key="nextpage" stroke="#D10000"/>
				</Link>
			</div>
		</div>	
				<div key="gapihmomidsiloppu" className="osiogap"></div>
		<div key="gapihmomidsiloppua" className="osiogap"></div>

	</div>);
}

function moveToAsiakaskokemusMob()
{
	window.SETKOKEMUS();
	window.drawAsi();
}
/*
		<div style={{position: "absolute", left: "0px"}}>
			<BlobLeft fromcolor="rgb(244, 225, 220)" tocolor="#FF8888" scale="0.1" />	
		</div>
		<div style={{position:"absolute", left:"0px"}}>
			<BlobRight fromcolor="rgb(244, 225, 220)" tocolor="#FF8888" scale="0.1" />
		</div>
*/


function MuotiPrevMob(p)
{
	return (
	<div key="karunuolivasen" className="arrowanimleft" onClick={p.onClick}>
<div key="karunuolivaseni" className="arrowleft" style={{transform: "scale(0.5)"}}>
<svg width="200" height="200" viewBox="0 0 200 200">
  <defs>
    <clipPath id="clipPathPrevM">
      <rect id="Rectangle_36" width="104" height="67.918" fill="none" stroke="#fff" strokeWidth="3"/>
    </clipPath>
  </defs>
  <g id="Group_83" transform="translate(1716 2503) rotate(180)">
    <g id="Group_75" transform="translate(1563.539 2369)">
      <g id="Group_51" transform="translate(0.461 0)" clipPath="url(#clipPathPrevM)">
        <path id="Path_113" d="M151.245.006a25.26,25.26,0,0,0,1.982,9.156,35.89,35.89,0,0,0,9.854,13.063c4.185,3.577,9.5,8.3,17.489,11.7A83.82,83.82,0,0,0,163.082,45.7a35.89,35.89,0,0,0-9.854,13.063,25.26,25.26,0,0,0-1.982,9.156" transform="translate(-83.061 -0.003)" fill="none" stroke={p.stroke} strokeMiterlimit="10" strokeWidth="3"/>
        <line id="Line_16" x2="96.791" transform="translate(-0.489 33.959)" fill="none" stroke={p.stroke} strokeMiterlimit="10" strokeWidth="3"/>
      </g>
    </g>
    <g id="Ellipse_18" transform="translate(1516 2303)" fill="none" stroke={p.stroke} strokeWidth="2" strokeDasharray="20">
      <circle cx="100" cy="100" r="100" stroke="none"/>
      <circle cx="100" cy="100" r="99" fill="none"/>
    </g>
  </g>
</svg></div></div>		
	)
}

function MuotiNextMob(p)
{
	return (
	<div key="karunuolioikee" className="arrowanimright" style={{display:"grid"}} onClick={p.onClick}>
	<div></div>
<div key="karunuolioikeei" className="arrowright" style={{transform: "scale(0.5)"}}>
<svg width="200" height="200" viewBox="0 0 200 200">
  <defs>
    <clipPath id="clipPathNextM">
      <rect id="Rectangle_36" width="104" height="67.918" fill="none" stroke="#fff" strokeWidth="3"/>
    </clipPath>
  </defs>
  <g id="Group_78" transform="translate(-1516 -2303)">
    <g id="Group_75" transform="translate(1563.539 2369)">
      <g id="Group_51" transform="translate(0.461 0)" clipPath="url(#clipPathNextM)">
        <path id="Path_113" d="M151.245.006a25.26,25.26,0,0,0,1.982,9.156,35.89,35.89,0,0,0,9.854,13.063c4.185,3.577,9.5,8.3,17.489,11.7A83.82,83.82,0,0,0,163.082,45.7a35.89,35.89,0,0,0-9.854,13.063,25.26,25.26,0,0,0-1.982,9.156" transform="translate(-83.061 -0.003)" fill="none" stroke={p.stroke} strokeMiterlimit="10" strokeWidth="3"/>
        <line id="Line_16" x2="96.791" transform="translate(-0.489 33.959)" fill="none" stroke={p.stroke} strokeMiterlimit="10" strokeWidth="3"/>
      </g>
    </g>
    <g id="Ellipse_18" transform="translate(1516 2303)" fill="none" stroke={p.stroke} strokeWidth="2" strokeDasharray="20">
      <circle cx="100" cy="100" r="100" stroke="none"/>
      <circle cx="100" cy="100" r="99" fill="none"/>
    </g>
  </g>
</svg></div><div></div></div>

	)
}


export default ElamyksellisyysMob;
