import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Outlet, Link } from "react-router-dom";
import Footer from "./Footer.js";
import ArrowRight from "./ArrowRight.js";
import HoriLine from "./HoriLine.js";
import BlobLeft from "./BlobLeft.js";
import BlobRight from "./BlobRight.js";
import Slider from "rc-slider";
import { createSliderWithTooltip } from "rc-slider";
import LinearProgress from "@mui/material/LinearProgress";
import { ThemeProvider } from '@mui/material/styles';



let valueChangerMob = function () {};
let taustaChangersMob = [];
let tekstiChangersMob = [];
let taustaInChangersMob = [];
let tekstiInChangersMob = [];


function ihs(f)
{
	return (window.innerHeight * f) + "px";
}


function DigistrategiaMob(p)
{
	let [chosenValue, valueChooser] = React.useState(window.DIGISTRATEGIATEKSTIT.grafiikkaekapalkkiseli);
	let [tokatausta, tokataustaChooser] = React.useState("#fff");
	let [kolmastausta, kolmastaustaChooser] = React.useState("#fff");
	let [neljastausta, neljastaustaChooser] = React.useState("#fff");
	let [viidestausta, viidestaustaChooser] = React.useState("#fff");
	let [tokateksti, tokatekstiChooser] = React.useState("#000");
	let [kolmasteksti, kolmastekstiChooser] = React.useState("#000");
	let [neljasteksti, neljastekstiChooser] = React.useState("#000");
	let [viidesteksti, viidestekstiChooser] = React.useState("#000");

	valueChangerMob = valueChooser;
	taustaChangersMob.push(tokataustaChooser);
	taustaInChangersMob.push(tokataustaChooser);
	taustaChangersMob.push(kolmastaustaChooser);
	taustaInChangersMob.push(kolmastaustaChooser);
	taustaChangersMob.push(neljastaustaChooser);
	taustaInChangersMob.push(neljastaustaChooser);
	taustaChangersMob.push(viidestaustaChooser);
	taustaInChangersMob.push(viidestaustaChooser);
	tekstiChangersMob.push(tokatekstiChooser);
	tekstiInChangersMob.push(tokatekstiChooser);
	tekstiChangersMob.push(kolmastekstiChooser);
	tekstiInChangersMob.push(kolmastekstiChooser);
	tekstiChangersMob.push(neljastekstiChooser);
	tekstiInChangersMob.push(neljastekstiChooser);
	tekstiChangersMob.push(viidestekstiChooser);
	tekstiInChangersMob.push(viidestekstiChooser);
	let marks = {
		10: " ",
		30: " ",
		50: " ",
		70: " ",
		90: " "
	};
	//let vsrc = window.allmedia.strategiav[0].src;

		/* <BlobLeft fromcolor="rgb(0, 0, 0)" tocolor="rgb(77,77,77)" />*/
	return (<div style={{color: "#FFFFFF", fontSize: "100%", textAlign: "center"}}>
		<div className="keskitettymob">
		<h1>{window.DIGISTRATEGIATEKSTIT.ekaosio}</h1>
		</div>
		<div key="gapihmomia" className="otsikkogap"></div>
		<div className="keskitettymob">
		{window.DIGISTRATEGIATEKSTIT.ekaosioekar}
		</div>
		<div key="gapihmomib" className="paragap"></div>
		<div className="keskitettymob">
		{window.DIGISTRATEGIATEKSTIT.ekaosiotokar}
		</div>
		<HoriLine fromcolor="rgb(0, 0, 0)" tocolor="rgb(77,77,77)"/>
		<div key="gapihmomic" className="osiogap"></div>
		<BlobLeft fromcolor="rgb(0, 0, 0)" tocolor="rgb(77,77,77)"  width="40vh" height="100vh" />
		<div style={{ display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "repeat(auto-fit, minmax(100px, auto))", justifyItems: "center", alignItems: "center"}}>
			<div style={{display: "inline-block", verticalAlign: "middle", lineHeight:"1"}}>
				<div className="keskitettyjohmob">
				<h3>{window.DIGISTRATEGIATEKSTIT.tokaosioekar}</h3>
				</div>
			</div>
		</div>
		<div>
			<div style={{position: "absolute", clipPath: "polygon(16% 7%, 16% 100%, 92% 100%, 92% 7%)" }}> 
				{p.strvideo}
			</div>
			<div style={{position: "absolute"}}>
				<img height="500vh" src={window.DIGISTRATEGIATEKSTIT.kolmasosiokuva} />
			</div>
		</div>
		<div key="gapihmomidsa" className="osiogap"></div>
		<div key="gapihmomidsb" className="osiogap"></div>
		<div key="gapihmomidsc" className="osiogap"></div>
		<div key="gapihmomidsd" className="osiogap"></div>
		<div key="gapihmomidsf" className="osiogap"></div>
		<div key="gapihmomidsg" className="osiogap"></div>
		<div key="gapihmomidsh" className="osiogap"></div>
		<div key="gapihmomidsi" className="osiogap"></div>
		<div key="gapihmomidsj" className="osiogap"></div>
		<div key="gapihmomidsk" className="osiogap"></div>
		<div key="gapihmomidsl" className="osiogap"></div>
		<div style={{ display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "repeat(auto-fit, minmax(100px, auto))", justifyItems: "center", alignItems: "center"}}>
			<div style={{display: "inline-block", verticalAlign: "middle", lineHeight:"1"}}>
				<div className="keskitettymob">{window.DIGISTRATEGIATEKSTIT.kolmasosioekar}</div>
				<div key="gapihmomie" className="osiogap"></div>
				<div className="keskitettymob"><h3>{window.DIGISTRATEGIATEKSTIT.kolmasosiotokar}</h3></div>
			</div>
		</div>
		<div key="gapihmomifb" className="osiogap"></div>
		<div key="gapihmomifcb" className="osiogap"></div>


		<div>
			<img height={ihs(0.25)} src={window.DIGISTRATEGIATEKSTIT.neljasosiokuva} />
		</div>
		<div></div>
		<div style={{ display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "repeat(auto-fit, minmax(100px, auto))", justifyItems: "center", alignItems: "center"}}>
			<div style={{display: "inline-block", verticalAlign: "middle", lineHeight:"1"}}>
				<div className="keskitettymob">
				<h3>{window.DIGISTRATEGIATEKSTIT.neljasosio}</h3>
				</div>
				<div key="gapihmomig" className="osiogap"></div>
				
				<div className="keskitettymob">{window.DIGISTRATEGIATEKSTIT.neljasosioekar}</div>
			</div>
		</div>
		<div key="gapihmomih" className="osiogap"></div>
		<div className="keskitettymob"><h2>{window.DIGISTRATEGIATEKSTIT.viidesosio}</h2></div>
		<div key="gapihmomii" className="otsikkogap"></div>
		<div className="keskitettymob">{window.DIGISTRATEGIATEKSTIT.viidesosioekar}</div>
		<div key="gapihmomij" className="paragap"></div>
		<div className="keskitettymob">{window.DIGISTRATEGIATEKSTIT.viidesosiotokar}</div>

		<div key="stratosiotokaf" className="keskitettymob" style={{backgroundSize: "100% 100%", backgroundImage: "url(\"lenkkaritausta.svg\")", backgroundRepeat: "no-repeat"}}>
			<div style={{ display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "repeat(auto-fit, minmax(100px, auto))", justifyItems: "center", alignItems: "center"}}>
				<div className="lenkkarimob">
					<img height={ihs(0.25)} src={window.DIGISTRATEGIATEKSTIT.kuudesosiokuva} />			
				</div>
			</div>
		</div>

		<div style={{ display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "repeat(auto-fit, minmax(100px, auto))", justifyItems: "center", alignItems: "center"}}>
			<div style={{display: "inline-block", verticalAlign: "middle", lineHeight:"1"}}>
				<div className="keskitettymob">{window.DIGISTRATEGIATEKSTIT.kuudesosioekar}</div>
				<div key="gapihmomik" className="osiogap"></div>
				<div className="keskitettymob">{window.DIGISTRATEGIATEKSTIT.kuudesosiotokar}</div>
			</div>
		</div>

		<div key="gapihmomil" className="osiogap"></div>
		<div key="stratosiotokai" className="keskitetty">
				<div style={{display: "inline-block", verticalAlign: "middle", lineHeight:"1", width:"100%"}}>
					<div style={{fontFamily: "gtflexaB"}}>{window.DIGISTRATEGIATEKSTIT.grafiikkaosio}</div>
					<div key="gapihmomilpalk" className="osiogap"></div>
					<div className="chartmob" style={{display: "grid", gridTemplateColumns: "1% 18% 2% 18% 2% 18% 2% 18% 2% 18% 1%", justifyItems: "center", alignItems: "end", height: ihs(0.25), overflow:"hidden"}}>
						<div key="tyhja1"></div>
						<div key="palkkivasen"><PalkkivasenMob  key="palkkivi" fill="#000" texteka={window.DIGISTRATEGIATEKSTIT.grafiikkaekapalkkieka} texttoka={window.DIGISTRATEGIATEKSTIT.grafiikkaekapalkkitoka} tfill="#fff"/></div>
						<div key="tyhja2"></div>
						<div key="palkkivasentoi"><PalkkivasentoiMob  key="palkkivti" fill={tokatausta} texteka={window.DIGISTRATEGIATEKSTIT.grafiikkatokapalkkieka} texttoka={window.DIGISTRATEGIATEKSTIT.grafiikkatokapalkkitoka} tfill={tokateksti}/></div>
						<div key="tyhja3"></div>
						<div key="palkkikeski"><PalkkikeskiMob  key="palkkike" fill={kolmastausta} texteka={window.DIGISTRATEGIATEKSTIT.grafiikkakolmaspalkkieka} texttoka={window.DIGISTRATEGIATEKSTIT.grafiikkakolmaspalkkitoka} tfill={kolmasteksti}/></div>
						<div key="tyhja4"></div>
						<div key="palkkioikeatoi"><PalkkioikeatoiMob  key="palkkiot" fill={neljastausta} texteka={window.DIGISTRATEGIATEKSTIT.grafiikkaneljaspalkkieka} texttoka={window.DIGISTRATEGIATEKSTIT.grafiikkaneljaspalkkitoka} tfill={neljasteksti}/></div>
						<div key="tyhja5"></div>
						<div key="palkkioikea"><PalkkioikeaMob  key="palkkio" fill={viidestausta} texteka={window.DIGISTRATEGIATEKSTIT.grafiikkaviidespalkkieka} texttoka={window.DIGISTRATEGIATEKSTIT.grafiikkaviidespalkkitoka} tfill={viidesteksti}/></div>
						<div key="tyhja6"></div>
					</div>
					<Slider dots min={0} marks={marks} step={null} defaultValue={10} minValue={10} maxValue={90} onChange={sliderChangeMob} style={{height:"100px", width: "99%"}}/>
					<div>{chosenValue}</div>
			</div>
		</div>

		<div key="gapihmomio" className="osiogap"></div>
		<div className="keskitettymob" style={{fontSize:"0.5em"}}>
				{window.DIGISTRATEGIATEKSTIT.sivunalateksti}
		</div>


		<div className="seuraavasivumob">
			<div className="seuraavasivuotsikko" style={{transform: "scale(0.5)"}}>
				<Link to="../" style={{textDecoration: "none", verticalAlign: "middle"}} onClick={moveToKaruselliMob} >
					<span style={{color: "#FFFFFF", fontSize:"2em", fontWeight:"bold", verticalAlign: "middle"}}>ALKUUN</span>
				</Link>
			</div>
			<div style={{transform: "scale(0.75)"}}>
				<Link to="../" style={{textDecoration: "none", verticalAlign: "middle"}} onClick={moveToKaruselliMob} >
					<ArrowRight stroke="#FFFFFF"/>
				</Link>
			</div>
		</div>
		<div key="gapihmomidsiloppu" className="osiogap"></div>
		<div key="gapihmomidsiloppua" className="osiogap"></div>
		

	</div>);
}

let prevvalueMob = 10;

function sliderChangeMob(v)
{
	//console.log(v);
	if (prevvalueMob == v) return;
	let arrayInd = 0;
	let up = true;
	if (prevvalueMob > v) up = false;
	prevvalueMob = v;
	switch (v)
	{
		case 10: valueChangerMob(window.DIGISTRATEGIATEKSTIT.grafiikkaekapalkkiseli); 
			arrayInd = 0;
			break;
		case 30: valueChangerMob(window.DIGISTRATEGIATEKSTIT.grafiikkatokapalkkiseli); 
			arrayInd = 1;
			break;
		case 50: valueChangerMob(window.DIGISTRATEGIATEKSTIT.grafiikkakolmaspalkkiseli); 
			arrayInd = 2;
			break;
		case 70: valueChangerMob(window.DIGISTRATEGIATEKSTIT.grafiikkaneljaspalkkiseli); 
			arrayInd = 3;
			break;
		case 90: valueChangerMob(window.DIGISTRATEGIATEKSTIT.grafiikkaviidespalkkiseli); 
			arrayInd = 4;
			break;
		default: //console.log("Unknown value");
	}
	//console.log("array ind at " + arrayInd);
	if (up)
	{
		//console.log("Color in up order to " + arrayInd);
		for (let i = 0; i < arrayInd; i++)
		{
			//console.log("Change " + i + " to whiteonblack");
			tekstiChangersMob[i]("#fff");
			taustaChangersMob[i]("#000");
			tekstiInChangersMob[i]("#fff");
			taustaInChangersMob[i]("#000");
			//console.log("Changed");
		}
	}
	else
	{
		//console.log("Color in down order to " +  arrayInd);
		for (let i = 3; i >= arrayInd; i--)
		{
			//console.log("Change " + i + " to blackonwhite");
			tekstiChangersMob[i]("#000");
			taustaChangersMob[i]("#fff");
			tekstiInChangersMob[i]("#000");
			taustaInChangersMob[i]("#fff");
			//console.log("Changed");
		}
	}
}

/*
					<div className="chart" style={{display: "grid", gridTemplateColumns: "1% 18% 2% 18% 2% 18% 2% 18% 2% 18% 1%", justifyItems: "center", alignItems: "start"}}>
						<div></div>
						<div><Sormi fill="#fff"/></div>
						<div></div>
						<div><Sormi fill="none"/></div>
						<div></div>
						<div><Sormi fill="none"/></div>
						<div></div>
						<div><Sormi fill="none"/></div>
						<div></div>
						<div><Sormi fill="none"/></div>
						<div></div>
					</div>

*/


function moveToKaruselliMob()
{
	window.SETKARUSELLI();
	window.drawKar();
}

function PalkkioikeaMob(p)
{
	let [tausta, taustaChooser] = React.useState(p.fill);
	let [teksti, tekstiChooser] = React.useState(p.tfill);
	taustaInChangersMob[3] = taustaChooser;
	tekstiInChangersMob[3] = tekstiChooser;
return(
<svg width="100%" height="auto" viewBox="0 0 232 500">
  <g id="Group_183" transform="translate(-1356 -5449)">
    <g id="Rectangle_81" transform="translate(1356 5449)" fill={tausta} stroke="#fff" strokeWidth="2">
      <rect width="232" height="500" stroke="#fff"/>
      <rect x="1" y="1" width="230" height="498" fill={tausta}/>
    </g>
    <text id="Strateginen_edell�k�vijyys_" transform="translate(1470 5479)" fill={teksti}  fontSize="26" fontFamily="gtflexaR"><tspan x="-65.546" y="28">{p.texteka}</tspan><tspan x="-77.86" y="63">{p.texttoka}</tspan><tspan fontFamily="GTFlexa-Rg, GT Flexa"><tspan x="0" y="98"></tspan></tspan></text>
  </g>
</svg>

)
}

function PalkkioikeatoiMob(p)
{
	let [tausta, taustaChooser] = React.useState(p.fill);
	let [teksti, tekstiChooser] = React.useState(p.tfill);
	taustaInChangersMob[2] = taustaChooser;
	tekstiInChangersMob[2] = tekstiChooser;
return(
<svg width="100%" height="auto" viewBox="0 0 232 400">
  <g id="Group_182" transform="translate(-1100 -5549)">
    <g id="Rectangle_74" transform="translate(1100 5549)" fill={tausta} stroke="#fff" strokeWidth="2">
      <rect width="232" height="400" stroke="#fff"/>
      <rect x="1" y="1" width="230" height="398" fill={tausta}/>
    </g>
    <text id="Liiketoiminta-mahdollisuudet" transform="translate(1215 5579)" fill={teksti} fontSize="26" fontFamily="gtflexaR"><tspan x="-78.768" y="28">{p.texteka}</tspan><tspan x="-88.975" y="63">{p.texttoka}</tspan></text>
  </g>
</svg>
)
}

function PalkkikeskiMob(p)
{
	let [tausta, taustaChooser] = React.useState(p.fill);
	let [teksti, tekstiChooser] = React.useState(p.tfill);
	taustaInChangersMob[1] = taustaChooser;
	tekstiInChangersMob[1] = tekstiChooser;
return(
<svg width="100%" height="auto" viewBox="0 0 232 350">
  <g id="Group_180" transform="translate(-844 -5599)">
    <g id="Group_179" >
      <g id="Rectangle_77" transform="translate(844 5599)" fill={tausta} stroke="#fff" strokeWidth="2">
        <rect width="232" height="350" stroke="#fff"/>
        <rect x="1" y="1" width="230" height="348" fill={tausta}/>
      </g>
    </g>
    <text id="Tehokkuuden_lis��minen_" transform="translate(960 5629)" fill={teksti}  fontSize="26" fontFamily="gtflexaR"><tspan x="-76.762" y="28">{p.texteka}</tspan><tspan x="-60.899" y="63">{p.texttoka}</tspan><tspan fontFamily="GTFlexa-Rg, GT Flexa"><tspan x="0" y="98"></tspan></tspan></text>
  </g>
</svg>
)
}

function PalkkivasentoiMob(p)
{
	let [tausta, taustaChooser] = React.useState(p.fill);
	let [teksti, tekstiChooser] = React.useState(p.tfill);
	taustaInChangersMob[0] = taustaChooser;
	tekstiInChangersMob[0] = tekstiChooser;
return(
<svg width="100%" height="auto" viewBox="0 0 232 200">
  <g id="Group_181" transform="translate(-588 -5749)">
    <g id="Rectangle_79" transform="translate(588 5749)" fill={tausta} stroke="#fff" strokeWidth="2">
      <rect width="232" height="200" stroke="#fff"/>
      <rect x="1" y="1" width="230" height="198" fill={tausta}/>
    </g>
    <text id="Riskien_hallinta_" transform="translate(703 5779)" fill={teksti} fontSize="26" fontFamily="gtflexaR"><tspan x="-85.56" y="28">{p.texteka}</tspan><tspan fontFamily="GTFlexa-Rg, GT Flexa"><tspan x="0" y="63"></tspan></tspan></text>
  </g>
</svg>
)
}

function PalkkivasenMob(p)
{
return(
<svg width="100%" height="auto" viewBox="0 0 232 150">
  <g id="Group_175" transform="translate(-332 -5799)">
    <g id="Rectangle_80" transform="translate(332 5799)" fill={p.fill} stroke="#fff" strokeWidth="2">
      <rect width="232" height="150" stroke="none"/>
      <rect x="1" y="1" width="230" height="148" fill="none"/>
    </g>
    <text id="Kielt�ytyminen_ja_minimointi" transform="translate(347 5826)" fill={p.tfill} fontSize="26" fontFamily="gtflexaR"><tspan x="0.676" y="28">{p.texteka}</tspan><tspan x="36.775" y="63">{p.texttoka}</tspan></text>
  </g>
</svg>
)
}




export default DigistrategiaMob;
