import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Outlet, Link } from "react-router-dom";


function ArrowLeft(p)
{
	let stroke = "#fff";
	let scale = "0.75";
	if (p.stroke != null) stroke = p.stroke;
	if (p.scale != null) scale = p.scale;
	return (<div className="arrowanimleft"  onClick={p.onClick}>
	<div className="arrowleft" style={{transform: "scale(" + scale + ")"}}><svg width="104" height="67.918" viewBox="0 0 104 67.918">
  <defs>
    <clipPath id="clipPathLe">
      <rect id="Rectangle_36" width="104" height="67.918" fill="none" stroke="#fff" strokeWidth="3"/>
    </clipPath>
  </defs>
  <g id="Group_96" transform="translate(104.461 67.918) rotate(180)">
    <g id="Group_51" transform="translate(0.461 0)" clipPath="url(#clipPathLe)">
      <path id="Path_113"  d="M151.245.006a25.26,25.26,0,0,0,1.982,9.156,35.89,35.89,0,0,0,9.854,13.063c4.185,3.577,9.5,8.3,17.489,11.7A83.82,83.82,0,0,0,163.082,45.7a35.89,35.89,0,0,0-9.854,13.063,25.26,25.26,0,0,0-1.982,9.156" transform="translate(-83.061 -0.003)" fill="none" stroke={stroke} strokeMiterlimit="10" strokeWidth="3"/>
      <line id="Line_16" x2="96.791" transform="translate(-0.489 33.959)" fill="none" stroke={stroke} strokeMiterlimit="10" strokeWidth="3"/>
    </g>
  </g>
</svg>
</div></div>);
}

export default ArrowLeft;
