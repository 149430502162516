import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { Outlet, Link } from "react-router-dom";
import Karuselli from "./Karuselli.js";
import Johdanto from "./Johdanto.js";
import Tulevaisuus from "./Tulevaisuus.js";
import Murros from "./Murros.js";
import Asiakaskokemus from "./Asiakaskokemus.js";
import Elamyksellisyys from "./Elamyksellisyys.js";
import Digimuoti from "./Digimuoti.js";
import Digistrategia from "./Digistrategia.js";
import Uhkakuvat from "./Uhkakuvat.js";
import Nykytila from "./Nykytila.js";
import Footer from "./Footer.js";
import FooterMob from "./FooterMob.js";
import Logo from "./Logo.js";
import DimmingGradient from "./DimmingGradient.js";
import { createTheme } from '@mui/material/styles';


window.MUIthemeRed = createTheme({
  palette: {
    primary: {
      main: 'rgb(209,0,0)'
    },
  },
});

window.MUIthemeWhite = createTheme({
  palette: {
    primary: {
      main: 'rgb(128,128,128)'
    },
  },
});


function App() {
  React.useEffect( () =>  window.drawApp() );
	//console.log("Redraw App " + window.WEBROOT);

  return (
     <BrowserRouter>
      <Routes>
        <Route path={window.WEBROOT} element={<Layout />}>
          <Route index element={<Karuselli />} />
          <Route path={window.WEBROOT + "johdanto"} element={<Johdanto />} />
          <Route path={window.WEBROOT + "nykytila"} element={<Murros />} />
          <Route path={window.WEBROOT + "tulevaisuus"} element={<Digimuoti />} />
	  <Route path={window.WEBROOT + "kuluttamisenmurros"} element={<Murros />} />
	  <Route path={window.WEBROOT + "asiakaskokemus"} element={<Asiakaskokemus />} />
	  <Route path={window.WEBROOT + "elamyksellisyys"} element={<Elamyksellisyys />} />
	  <Route path={window.WEBROOT + "digitaalinenmuoti"} element={<Digimuoti />} />
	  <Route path={window.WEBROOT + "digistrategia"} element={<Digistrategia />} />
	  <Route path={window.WEBROOT + "uhkakuvat"} element={<Uhkakuvat />} />
          <Route path="*" element={<Karuselli />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

let textfa = [[],[],[]];
let spanfa = [[],[],[]];
let clicfa = [[],[],[]];

function setAlue(n)
{
	window.ALUE = n;
	switch (n)
	{
		case 0:
			textfa[0]("kuluttamisenmurros");
			textfa[1]("elamyksellisyys");
			textfa[2]("asiakaskokemus");
			spanfa[0](<span id="spantextl" className="hampsnipspan" key="spantextl">Kuluttamisen&nbsp;murros</span>);
			spanfa[1](<span id="spantextm" className="hampsnipspan" key="spantextm">Elämyksellisyys</span>);
			spanfa[2](<span id="spantextr" className="hampsnipspan" key="spantextr">Asiakaskokemus</span>);
			clicfa[0]({f: setMur});
			clicfa[1]({f: setEla});
			clicfa[2]({f: setAsi});
			break;
		case 1:
			textfa[0]("digitaalinenmuoti");
			textfa[1]("uhkakuvat");
			textfa[2]("digistrategia");
			spanfa[0](<span id="spantextl" className="hampsnipspan" key="spantextl">Digitaalinen&nbsp;muoti</span>);
			spanfa[1](<span id="spantextm" className="hampsnipspan" key="spantextm">Uhkakuvat</span>);
			spanfa[2](<span id="spantextr" className="hampsnipspan" key="spantextr">Digistrategia</span>);
			clicfa[0]({f: setMuo});
			clicfa[1]({f: setUhk});
			clicfa[2]({f: setStr});
			break;
		case 2:
		default:
			/*
			textfa[0](" ");
			textfa[1](" ");
			textfa[2](" ");
			spanfa[0](<span id="spantextl" className="hampsnipspan" key="spantextl"></span>);
			spanfa[1](<span id="spantextm" className="hampsnipspan" key="spantextm"></span>);
			spanfa[2](<span id="spantextr" className="hampsnipspan" key="spantextr"></span>);
			*/
			break;
	}
}

function setNyky() { /* if (isMobile) window.suljehamppari(); */ window.SECT = "nykytila"; setAlue(0); window.CURRENTLYLOADED = 2; window.TESTLOAD();}
function setTule() { /* if (isMobile) window.suljehamppari(); */ window.SECT = "tulevaisuus"; setAlue(1); window.CURRENTLYLOADED = 3; window.TESTLOAD();}


function setJohd() { /* if (isMobile) window.suljehamppari(); */ window.SECT = "johdanto"; setAlue(2); window.CURRENTLYLOADED = 0; window.TESTLOAD();}
function setKaru() { /* if (isMobile) window.suljehamppari(); */ window.SECT = "karuselli"; setAlue(2); window.CURRENTLYLOADED = 1; window.TESTLOAD();}

function setAsi() { /* if (isMobile) window.suljehamppari(); */ window.SECT = "asiakaskokemus"; setAlue(0); window.CURRENTLYLOADED = 5; window.TESTLOAD();}
function setMur() { /* if (isMobile) window.suljehamppari(); */ window.SECT = "murros"; setAlue(0); window.CURRENTLYLOADED = 4; window.TESTLOAD();}
function setEla() { /* if (isMobile) window.suljehamppari(); */ window.SECT = "elamyksellisyys"; setAlue(0); window.CURRENTLYLOADED = 6; window.TESTLOAD();}

function setMuo() { /* if (isMobile) window.suljehamppari(); */ window.SECT = "digimuoti"; setAlue(1); window.CURRENTLYLOADED = 8; window.TESTLOAD();}
function setStr() { /* if (isMobile) window.suljehamppari(); */ window.SECT = "digistrategia"; setAlue(1); window.CURRENTLYLOADED = 9; window.TESTLOAD();}
function setUhk() { /* if (isMobile) window.suljehamppari(); */ window.SECT = "uhkakuvat"; setAlue(1); window.CURRENTLYLOADED = 7; window.TESTLOAD();}


window.SETDIGIMUOTI = setMuo;
window.SETMURROS = setMur;
window.SETELAMYS = setEla;
window.SETKOKEMUS = setAsi;
window.SETMUOTI = setMuo;
window.SETUHKAT = setUhk;
window.SETSTRAT = setStr;
window.SETJOHDANTO = setJohd;
window.SETKARUSELLI = setKaru;

function setDummy() {}
let infoHook;

let infoOn = false;
function switchInfotext()
{
	if (infoHook)
	{
		if (infoOn)
		{
			infoHook(false);
			infoOn = false;
			window.BLURBACKGROUND(false);
		}
		else
		{
			infoHook(true);
			infoOn = true;
			window.BLURBACKGROUND(true);
		}
	}
}

function Layout(p)
{
	React.useEffect( () => window.drawLay() );
	//console.log("Redraw layout " + window.WEBROOT);
	let [infotext, setInfotext] = React.useState(false);
	let infotextd = [];
	let infoclass = "infotext";
	let infofoot = <Footer />
	if (isMobile && window.NOTIPAD) {
		infoclass = "infotextmob"
		infofoot = <FooterMob />
	}
	if (infotext) infotextd.push(<div onClick={switchInfotext} className={infoclass}>
			<div className="infotextinner">
				<div className="infotextkappale">{window.INFOTEKSTIT.ekaosio}</div>      
				<div className="infotextsep"></div>
				<div className="infotextkappale">{window.INFOTEKSTIT.tokaosio}</div>      
				<div className="infotextsep"></div>
				<div className="infotextkappale">{window.INFOTEKSTIT.kolmasosio}</div>
				<div className="infotextsep"></div>
				<div className="infotextsep"></div>
			</div>
			{infofoot}</div>);
	//console.log("Infotext is "+infotext);
	window.DISPLAYINFOTEXT = switchInfotext;
	infoHook = setInfotext;
	let texts = [" ", " ", " "];
	let spans = [ <span id="spantextl" className="hampsnipspan" key="spantextl"></span>,
		      <span id="spantextm" className="hampsnipspan" key="spantextm"></span>,
		      <span id="spantextr" className="hampsnipspan" key="spantextr"></span> ];
	let textsNykMob = ["kuluttamisenmurros", "elamyksellisyys", "asiakaskokemus"];
	let textsTulMob = [ "digitaalinenmuoti", "uhkakuvat", "digistrategia" ];
	let spansNykMob = [ <span id="spantextln" className="hampsnipspanMob" key="spantextln">Kuluttamisen&nbsp;murros</span>,
				  <span id="spantextmn" className="hampsnipspanMob" key="spantextmn">Elämyksellisyys</span>,
				  <span id="spantextrn" className="hampsnipspanMob" key="spantextrn">Asiakaskokemus</span>];
	let spansTulMob = [<span id="spantextlt" className="hampsnipspanMob" key="spantextlt">Digitaalinen&nbsp;muoti</span>,
				<span id="spantextmt" className="hampsnipspanMob" key="spantextmt">Uhkakuvat</span>,
				<span id="spantextrt" className="hampsnipspanMob" key="spantextrt">Digistrategia</span>];
	switch (window.ALUE)
	{
		case 0: 
			texts = ["kuluttamisenmurros", "elamyksellisyys", "asiakaskokemus"];
			spans = [ <span id="spantextl" className="hampsnipspan" key="spantextl">Kuluttamisen&nbsp;murros</span>,
				  <span id="spantextm" className="hampsnipspan" key="spantextr">Elämyksellisyys</span>,
				  <span id="spantextr" className="hampsnipspan" key="spantextm">Asiakaskokemus</span>];
			break;
		case 1: 
			texts = [ "digitaalinenmuoti", "uhkakuvat", "digistrategia" ];
			spans = [<span id="spantextl" className="hampsnipspan" key="spantextl">Digitaalinen&nbsp;muoti</span>,
				<span id="spantextm" className="hampsnipspan" key="spantextm">Uhkakuvat</span>,
				<span id="spantextr" className="hampsnipspan" key="spantextr">Digistrategia</span>];
			break;
		default:
	}
	let [textsal, textfal] = React.useState(texts[0]);
	textfa[0] = textfal;
	let [textsam, textfam] = React.useState(texts[1]);
	textfa[1] = textfam;
	let [textsar, textfar] = React.useState(texts[2]);
	textfa[2] = textfar;
	let [spansal, spanfal] = React.useState(spans[0]);
	spanfa[0] = spanfal;
	let [spansam, spanfam] = React.useState(spans[1]);
	spanfa[1] = spanfam;
	let [spansar, spanfar] = React.useState(spans[2]);
	spanfa[2] = spanfar;

	let [curheight, curheightf] = React.useState(window.CURRENTHEIGHT);
	window.UPDATECURRENTHEIGHT = curheightf;

	let fl = setDummy;
	let fm = setDummy;
	let fr = setDummy;

	let flnMob = setMur;
	let fmnMob = setEla;
	let frnMob = setAsi;

	let fltMob = setMuo;
	let fmtMob = setUhk;
	let frtMob = setStr;

	let gradientcols = [0,0,0];

	switch (window.ALUE)
	{
		case 0: fl = setAsi; fm = setEla; fr = setMur; gradientcols = [ 244, 225, 220 ]; break;
		case 1: fl = setMuo; fm = setUhk; fr = setStr; break;
		default:
	}

	let [clicsal, clicfal] = React.useState({f : fl});
	clicfa[0] = clicfal;
	let [clicsam, clicfam] = React.useState({f : fm});
	clicfa[1] = clicfam;
	let [clicsar, clicfar] = React.useState({f : fr});
	clicfa[2] = clicfar;

	

	let toReturn = [];
	//console.log("Am I mobile: " + isMobile);
	if (isMobile && window.NOTIPAD)
	{
		window.RUNNINGINMOBILE = true;
		toReturn.push(
	<div key="rootmaindivmob">
	<DimmingGradient key="dimmermob" gradientcols={gradientcols} />
	<Logo key="logomob" onClick={() => {setKaru();}}/>
      	<div key="opacitydivmob" className="opacitywrapper">
	<div key="hampsniptable" className="hampsniptablemob" id="hampvalik">
		<div key="hampsniptableinnerup" className="hampsniptableinnerupmob" id="hampvalikinup">
			<div key="mobileupbar" className="mobileupbar">
			</div>
		</div>
		<div key="hampsniptableinnerlow" className="hampsniptableinnerlowmob" id="hampvalikinlo">
			<div key="mobilerowa" className="mobilerowt" >
				<div key="johdantomoba" style={{height: "2em", textAlign: "top"}}>
					<Link to={window.WEBROOT + "johdanto"} className="hampsniplinkmob" style={{textDecoration: "none"}}>
					<div id="johdivw" key="hampsnipwrapper4" className="hampsniptabletdwrappermob">
					<div id="johdiv" key="hampsniptabletd4" className="hampsniptabletduppermob" onClick={setJohd}>
						<span id="johtext" className="hampsnipspanuppermob">Johdanto</span>
					</div>
					</div>
					</Link>
				</div>
				<div key="johdantomobbc">&nbsp;</div>
			</div>
			<div key="mobilerowb" className="mobilerowm">
				<div key="murrosmoba" style={{height: "2em", textAlign: "top"}}>
					<Link to={window.WEBROOT + "kuluttamisenmurros"} className="hampsniplinkmob" style={{textDecoration: "none"}}>
					<div id="nykdivw" key="hampsnipwrapper5" className="hampsniptabletdwrappermob">
					<div id="nykdiv" key="hampsniptabletd5" className="hampsniptabletdmob" onClick={setMur}>
						<span id="nyktext" className="hampsnipspanuppermob">Nykytila</span>
					</div>
					</div>
					</Link>
				</div>
				<div key="murrosmobb">
					<Link to={window.WEBROOT + textsNykMob[0]} className="hampsniplinkmob" style={{textDecoration: "none"}}>
					<div id="subdivlwn" key="hampsnipwrapper1n" className="hampsniptabletdwrappermob">
						<div id="subdivln" key="hampsniptabletd1n" className="hampsniptabletdmob" onClick={flnMob}>{spansNykMob[0]}</div>
					</div>
					</Link>
					<Link to={window.WEBROOT + textsNykMob[1]} className="hampsniplinkmob" style={{textDecoration: "none"}}>
					<div id="subdivmwn" key="hampsnipwrapper2n" className="hampsniptabletdwrappermob">
						<div id="subdivmn" key="hampsniptabletd2n" className="hampsniptabletdmob" onClick={fmnMob}>{spansNykMob[1]}</div>
					</div>
					</Link>
					<Link to={window.WEBROOT + textsNykMob[2]} className="hampsniplinkmob" style={{textDecoration: "none"}}>
					<div id="subdivrwn" key="hampsnipwrapper3n" className="hampsniptabletdwrappermob">
						<div id="subdivrn" key="hampsniptabletd3n" className="hampsniptabletdmob" onClick={frnMob}>{spansNykMob[2]}</div>
					</div>
					</Link>
				</div>
				<div key="murrosmobbc">&nbsp;</div>
			</div>
			<div key="mobilerow" className="mobilerowl" >
				<div key="muotimoba" style={{height: "2em", textAlign: "top"}}>
					<Link to={window.WEBROOT + "digitaalinenmuoti"} className="hampsniplinkmob" style={{textDecoration: "none"}}>
					<div id="tuldivw" key="hampsnipwrapper6" className="hampsniptabletdwrappermob">
					<div id="tuldiv" key="hampsniptabletd6" className="hampsniptabletdmob" onClick={setMuo}>
						<span id="tultext" className="hampsnipspanuppermob">Tulevaisuus</span>
					</div>
					</div>
					</Link>
				</div>
				<div key="muotimobb">
					<Link to={window.WEBROOT + textsTulMob[0]} className="hampsniplinkmob" style={{textDecoration: "none"}}>
					<div id="subdivlwt" key="hampsnipwrapper1t" className="hampsniptabletdwrappermob">
						<div id="subdivlt" key="hampsniptabletd1t" className="hampsniptabletdmob" onClick={fltMob}>{spansTulMob[0]}</div>
					</div>
					</Link>
					<Link to={window.WEBROOT + textsTulMob[1]} className="hampsniplinkmob" style={{textDecoration: "none"}}>
					<div id="subdivmwt" key="hampsnipwrapper2t" className="hampsniptabletdwrappermob">
						<div id="subdivmt" key="hampsniptabletd2t" className="hampsniptabletdmob" onClick={fmtMob}>{spansTulMob[1]}</div>
					</div>
					</Link>
					<Link to={window.WEBROOT + textsTulMob[2]} className="hampsniplinkmob" style={{textDecoration: "none"}}>
					<div id="subdivrwt" key="hampsnipwrapper3t" className="hampsniptabletdwrappermob">
						<div id="subdivrt" key="hampsniptabletd3t" className="hampsniptabletdmob" onClick={frtMob}>{spansTulMob[2]}</div>
					</div>
					</Link>
				</div>
			</div>
		</div>
	</div>
	</div>
	<div key="opacitywraptwomob" className="opacitywrapper">
      <div key="skipmenu" className="tekstinalku"></div>
      <div key="heightfix" style={{minHeight: curheight}}>
	      <Outlet />
      </div>
	</div>
	{infotextd}
	<InfoButton whichclass="infobuttonmob" />
    </div>);
	}
	else
	{
		window.RUNNINGINMOBILE = false;
	toReturn.push(
//	return (
    <div key="rootmaindiv" style={{fontSize: window.INCREASEFONTSIZE}}>
	<Logo key="logokey" onClick={() => {setKaru();}}/>
      	<div key="opacitywrapone" className="opacitywrapper">
	<div key="hampsniptable" className="hampsniptable" id="hampvalik">
		<div key="hampsniptableinnerup" className="hampsniptableinnerup" id="hampvalikinup">
			<Link to={window.WEBROOT + "johdanto"} className="hampsniplink" style={{textDecoration: "none"}}>
			<div id="johdivw" key="hampsnipwrapper4" className="hampsniptabletdwrapper">
			<div id="johdiv" key="hampsniptabletd4" className="hampsniptabletdupper" onClick={setJohd}>
				<span id="johtext" className="hampsnipspanupper">Johdanto</span>
			</div>
			</div>
			</Link>
			<Link to={window.WEBROOT + "kuluttamisenmurros"} className="hampsniplink" style={{textDecoration: "none"}}>
			<div id="nykdivw" key="hampsnipwrapper5" className="hampsniptabletdwrapper">
			<div id="nykdiv" key="hampsniptabletd5" className="hampsniptabletdupper" onClick={setMur}>
				<span id="nyktext" className="hampsnipspanupper">Nykytila</span>
			</div>
			</div>
			</Link>
			<Link to={window.WEBROOT + "digitaalinenmuoti"} className="hampsniplink" style={{textDecoration: "none"}}>
			<div id="tuldivw" key="hampsnipwrapper6" className="hampsniptabletdwrapper">
			<div id="tuldiv" key="hampsniptabletd6" className="hampsniptabletdupper" onClick={setMuo}>
				<span id="tultext" className="hampsnipspanupper">Tulevaisuus</span>
			</div>
			</div>
			</Link>
			<div key="hampsniptabletdright" className="hampsniptabletdright"></div>
		</div>
		<div key="hampsniptableinnerlow" className="hampsniptableinnerlow" id="hampvalikinlo">
			<Link to={window.WEBROOT + textsal} className="hampsniplink" style={{textDecoration: "none"}}>
			<div id="subdivlw" key="hampsnipwrapper1" className="hampsniptabletdwrapper">
				<div id="subdivl" key="hampsniptabletd1" className="hampsniptabletd" onClick={clicsal.f}>{spansal}</div>
			</div>
			</Link>
			<Link to={window.WEBROOT + textsam} className="hampsniplink" style={{textDecoration: "none"}}>
			<div id="subdivmw" key="hampsnipwrapper2" className="hampsniptabletdwrapper">
				<div id="subdivm" key="hampsniptabletd2" className="hampsniptabletd" onClick={clicsam.f}>{spansam}</div>
			</div>
			</Link>
			<Link to={window.WEBROOT + textsar} className="hampsniplink" style={{textDecoration: "none"}}>
			<div id="subdivrw" key="hampsnipwrapper3" className="hampsniptabletdwrapper">
				<div id="subdivr" key="hampsniptabletd3" className="hampsniptabletd" onClick={clicsar.f}>{spansar}</div>
			</div>
			</Link>
		</div>
	</div>
	</div>
	<div key="opacitywraptwo" className="opacitywrapper">
      <div key="skipmenu" className="tekstinalku"></div>
      <div key="heightfix" style={{minHeight: curheight}}>
	      <Outlet />
      </div>
	</div>
	{infotextd}
	<InfoButton whichclass="infobutton" />
    </div>);
	}
	return toReturn;
}

function InfoButton(p)
{
    return(<div className={p.whichclass} onClick={window.DISPLAYINFOTEXT}>
	<svg id="Group_137" width="60" height="60" viewBox="0 0 60 60">

  <g id="Mask_Group_136" transform="translate(0 0)" clipPath="url(#clip-path-info)">
    <circle id="Ellipse_23" cx="28.391" cy="28.391" r="28.391" transform="translate(1.61 1.609)"  className="cls-2" fill="rgba(0,0,0,0)" strokeMiterlimit="10" strokeWidth="2"/>
    <path id="Path_122" d="M37.49,52.492A10.945,10.945,0,0,1,38.8,47.51l2.951-5.757a5.746,5.746,0,0,0,.822-2.129c0-.87-.58-1.208-1.451-1.208-1.064,0-2.709.531-4.354,3.289-.194.339-.338.436-.629.436H34.491c-.29,0-.483-.145-.483-.436a1.878,1.878,0,0,1,.145-.628c2.419-5.37,5.612-7.983,9.579-7.983,3.677,0,5.224,1.886,5.224,4.547a10.94,10.94,0,0,1-1.306,4.984L44.7,48.381a5.726,5.726,0,0,0-.823,2.128c0,.871.58,1.209,1.451,1.209,1.065,0,2.71-.532,4.355-3.29.193-.338.338-.436.628-.436h1.645c.291,0,.484.145.484.436a1.9,1.9,0,0,1-.145.629c-2.419,5.37-5.322,7.982-9.772,7.982-3.193,0-5.032-1.887-5.032-4.548M44.312,26.32a4.4,4.4,0,1,1,8.805,0,4.4,4.4,0,1,1-8.805,0" transform="translate(-14.698 -9.431)" className="cls-1"/>
  </g>
</svg>
</div>);

}

export default App;
