import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Outlet, Link } from "react-router-dom";
import Footer from "./Footer.js";
import ArrowRight from "./ArrowRight.js";
import HoriLine from "./HoriLine.js";
import BlobLeft from "./BlobLeft.js";
import BlobRight from "./BlobRight.js";
import Slider from "rc-slider";
import { createSliderWithTooltip } from "rc-slider";
import LinearProgress from "@mui/material/LinearProgress";
import { ThemeProvider } from '@mui/material/styles';
import ReactCardFlip from 'react-card-flip';
import MurrosMob from './MurrosMob.js';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';



let prevvalue = 0;

let valueChanger = function () {};

let stopupdates = function () {};

let ekaf = false;
let fswapeka = function () {};
function ekaflipper()
{
	ekaf = !ekaf;
	fswapeka(ekaf);
}

let tokaf = false;
let fswaptoka = function () {};
function tokaflipper()
{
	tokaf = !tokaf;
	fswaptoka(tokaf);
}

let kolmasf = false;
let fswapkolmas = function () {};
function kolmasflipper()
{
	kolmasf = !kolmasf;
	fswapkolmas(kolmasf);
}

function Murros(p)
{
	let marks = {
		0: " ",
		50: " ",
		100: " "
	};
	let [chosenValue, valueChange] = React.useState(window.MURROSTEKSTIT.ekavaihtoehto);
	valueChanger = valueChange;
	React.useEffect( () => { if ( window.RECENTLOAD )  window.drawMur() });
	let [progress, pupdate] = React.useState(0);
	let [progressLp, pupdateLp] = React.useState(0);
	let [nomoreload, nomoreupdate] = React.useState(<div></div>);
	let [ekaflipped, flipeka] = React.useState(false);
	let [tokaflipped, fliptoka] = React.useState(false);
	let [kolmasflipped, flipkolmas] = React.useState(false);
	fswapeka = flipeka;
	fswaptoka = fliptoka;
	fswapkolmas = flipkolmas;

	stopupdates = nomoreupdate;

	if (! window.KAIKKIMURROSKUVATLADATTU )
	{
		function loopy()
		{
				if (! window.KAIKKIMURROSKUVATLADATTU )
				{
					setTimeout(loopy, 500);
				}
				else
				{
					let empty = [];
					stopupdates(empty);
				}
				pupdate((window.allmedia.murros.length) + " images out of " + window.allmedia.murross);
				pupdateLp(100 * (window.allmedia.murros.length/window.allmedia.murross));
		}
		setTimeout(loopy, 1000);
		return(	<ThemeProvider theme={window.MUIthemeWhite}>
			<div className="keskitetty" style={{color: "#FFFFFF", fontSize: "125%"}}>
				<div>Ladataan:</div>
				<div>
    	  				<LinearProgress variant="determinate" value={progressLp} />{nomoreload}
				</div>
			</div></ThemeProvider>);
	}
	else
	{
		if (isMobile && window.NOTIPAD)
		{
			return (<MurrosMob />);
		}
		else
		{
			return (<div style={{color: "#D10000", fontSize: "125%"}}>
			<div key="murrososioeka" className="kuvallinenoikea">
				<div>
					<img height="500vh" src={window.MURROSTEKSTIT.ekaosiokuva} />
				</div>
				<div>
					<h1>{window.MURROSTEKSTIT.ekaosio}</h1>
					<div key="gapihmomia" className="otsikkogap"></div>
					{window.MURROSTEKSTIT.ekaosioekar}
					<div key="gapihmomib" className="paragap"></div>
					{window.MURROSTEKSTIT.ekaosiotokar}
				</div>
			</div>
			<HoriLine fromcolor="rgb(244, 225, 220)" tocolor="#FF8888"/>
			<div key="gapihmomic" className="osiogap"></div>
			<div key="murrososiotoka" className="kuvallinenvasenkapea">
				<div style={{width: "80%", display: "flex", alignItems: "center"}}>
					<span style={{verticalAlign: "middle"}} >{window.MURROSTEKSTIT.tokaosioekar}</span>
				</div>
				<div>
					<img height="250vh" src={window.MURROSTEKSTIT.tokaosiokuva} />
				</div>
			</div>	
			<BlobLeft fromcolor="rgb(244, 225, 220)" tocolor="#FF8888" height="150vh"/>	
			<div key="gapihmomid" className="osiogap"></div>
			<div className="vasenlevea">
				<h3>{window.MURROSTEKSTIT.kolmasosioekar}</h3>
			</div>
			<div key="gapihmomie" className="osiogap"></div>
			<h3  style={{textAlign: "center"}}>{window.MURROSTEKSTIT.kortitosio}</h3>
			<div style={{marginLeft:"20%", marginRight:"20%"}}>
				<div className="murroskortit" style={{verticalAlign: "center"}} >
					<table border="0px" width="100%"><thead><tr>
						<td>
							<ReactCardFlip isFlipped={ekaflipped} flipDirection="vertical">
								<img width="100%" src={window.MURROSKORTIT[0].kuva} onClick={ekaflipper} />
								<img width="100%"  src={window.MURROSKORTIT[1].kuva} onClick={ekaflipper} />
							</ReactCardFlip>
						</td>
						<td width="2%"></td>
						<td>
							<ReactCardFlip isFlipped={tokaflipped} flipDirection="vertical">
								<img width="100%"  src={window.MURROSKORTIT[2].kuva} onClick={tokaflipper} />
								<img width="100%"  src={window.MURROSKORTIT[3].kuva} onClick={tokaflipper} />
							</ReactCardFlip>
						</td>
						<td width="2%"></td>
						<td>
							<ReactCardFlip isFlipped={kolmasflipped} flipDirection="vertical">
								<img width="100%"  src={window.MURROSKORTIT[4].kuva} onClick={kolmasflipper} />
								<img width="100%"  src={window.MURROSKORTIT[5].kuva} onClick={kolmasflipper} />
							</ReactCardFlip>
						</td></tr></thead></table>
				</div>
			</div>
			<BlobRight fromcolor="rgb(244, 225, 220)" tocolor="#FF8888" />
			<div key="gapihmomif" className="osiogap"></div>
			<div key="murrosneljasosio" className="kuvallinenoikeavasmargi">
				<div>
					<img height="300vh" src={window.MURROSTEKSTIT.neljasosiokuva} />
				</div>
				<div></div>
				<div>
					{window.MURROSTEKSTIT.neljasosioekar}
					<div key="gapihmomih" className="paragap"></div>
					<h3>{window.MURROSTEKSTIT.neljasosiotokar}</h3>
				</div>
			</div>
			<div key="gapihmomii" className="osiogap"></div>
			<div className="vasen">
					{window.MURROSTEKSTIT.viidesosioekar}
					<ul style={{listStyleImage: "url('"+window.MURROSTEKSTIT.viidesosiobullet+"')"}}>
						<li>{window.MURROSTEKSTIT.viidesosioekap}</li>
						<li>{window.MURROSTEKSTIT.viidesosiotokap}</li>
						<li>{window.MURROSTEKSTIT.viidesosiokolmasp}</li>
					</ul>
			</div>
			<div key="gapihmomiz"></div>
			<div key="laskurikorttiroot" className="keskitetty" style={{ background: "url(laskuripohja.svg)", backgroundSize: "100% 100%"}}>
				<div className="laskurislidercont">
					<div className="laskurisliderotsbox">
						{window.MURROSTEKSTIT.laskuriotsikko}
					</div>
					<div className="laskurislidervaihtoehdotboxeka">
						{window.MURROSTEKSTIT.laskuriekavaihtoehto}
					</div>
					<div className="laskurislidervaihtoehdotboxtoka">
						{window.MURROSTEKSTIT.laskuritokavaihtoehto}
					</div>
					<div className="laskurislidervaihtoehdotboxkolmas">
						{window.MURROSTEKSTIT.laskurikolmasvaihtoehto}
					</div>
					<div className="laskurisliderliukubox">
						<Slider key="slideri" dots min={0} marks={marks} step={null} defaultValue={0} minValue={0} maxValue={100} prefixCls="pinkslider" onChange={sliderChange}/>
					</div>
					<div className="laskurislidertulosbox">
						{chosenValue}
					</div>
					<div className="laskurisliderloppubox">
						&nbsp;
					</div>
					
				</div>
			</div>
			<div key="gapihmomiq" className="osiogap"></div>
			<div className="vasen">
					{window.MURROSTEKSTIT.kuudesosioekar}
					<div key="gapihmomiaa" className="otsikkogap"></div>
					{window.MURROSTEKSTIT.kuudesosiotokar}
					<div key="gapihmomiab" className="otsikkogap"></div>
					<div className="murrosnappiwrappi">
						<div key="murrosnappi" onClick={laskurinappi} className="nappi">{window.MURROSTEKSTIT.kuudesosionappi}</div>
					</div>
			</div>
			<div key="gapihmomipq" className="osiogap"></div>
			<div className="seuraavasivu">
				<div className="seuraavasivuotsikko" >
					<Link to="../elamyksellisyys" style={{textDecoration: "none", verticalAlign: "middle"}} onClick={moveToElamyksellisyys}>
						<span style={{color: "#D10000", fontSize:"2em", fontWeight:"bold", verticalAlign: "middle"}}>ELÄMYKSELLISYYS</span>
					</Link>
				</div>
				<div style={{transform: "scale(0.75)"}}>
					<Link to="../elamyksellisyys" style={{textDecoration: "none", verticalAlign: "middle"}} onClick={moveToElamyksellisyys}>

						<ArrowRight stroke="#D10000"/>
					</Link>
				</div>
			</div>

			</div>);
		}
	}
}

/*
							<ReactCardFlip front={window.MURROSKORTIT[2].kuva} back={window.MURROSKORTIT[3].kuva}/>
							<ReactCardFlip front={window.MURROSKORTIT[4].kuva} back={window.MURROSKORTIT[5].kuva}/>
				<svg width="100%" viewBox="0 0 1322 768">
					<defs>
				    <filter id="Rectangle_67" x="0" y="0" width="1322" height="768" filterUnits="userSpaceOnUse">
				      <feOffset dy="3" input="SourceAlpha"/>
				      <feGaussianBlur stdDeviation="3" result="blur"/>
				      <feFlood floodColor="#d00100"/>
				      <feComposite operator="in" in2="blur"/>
				      <feComposite in="SourceGraphic"/>
				    </filter>
				  </defs>
				  <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Rectangle_67)">
				    <rect id="Rectangle_67-2" width="1304" height="750" transform="translate(9 6)" fill="#fff"/>
				  </g>
				  <text id="Pari_kertaa_vuodessa" transform="translate(117 257)" fill="#d10000" fontSize="26" fontFamily="gtflexaR"><tspan x="36.907" y="28">Pari kertaa </tspan><tspan x="43.699" y="63">vuodessa</tspan></text>
				  <text id="Kuinka_usein_käytät_paallasi_olevaa_vaatetta_" transform="translate(230 83)" fill="#d10000" fontSize="33" fontFamily="gtflexaR"><tspan x="110.854" y="36">Kuinka usein käytät päälläsi olevaa vaatetta?</tspan></text>
				  <text id="Monta_kertaa_viikossa" transform="translate(983 257)" fill="#d10000" fontSize="26" fontFamily="gtflexaR"><tspan x="27.858" y="28">Monta kertaa </tspan><tspan x="60.25" y="63">viikossa</tspan></text>
				  <text id="Muutaman_kerran_kuukaudessa_" transform="translate(511 257)" fill="#d10000" fontSize="26" fontFamily="gtflexaR"><tspan x="47.593" y="28">Muutaman kerran </tspan><tspan x="76.291" y="63">kuukaudessa </tspan></text>
				</svg>
*/

function moveToElamyksellisyys()
{
	window.SETELAMYS();
	window.drawEla();
}
/*
			  <line id="Line_17" x2="865" transform="translate(222.5 370.5)" fill="none" stroke="#bf281b" strokeWidth="2"/>
*/

function laskurinappi(e)
{
//	console.log("Laskurinappia painettu?");
	window.location.assign(
  		"https://www.thredup.com/fashionfootprint/"
	);

}

function sliderChange(v)
{
	//console.log(v);
	if (prevvalue == v) return;
	let arrayInd = 0;
	let up = true;
	if (prevvalue > v) up = false;
	prevvalue = v;
	switch (v)
	{
		case 0: valueChanger(window.MURROSTEKSTIT.ekavaihtoehto); 
			break;
		case 50: valueChanger(window.MURROSTEKSTIT.tokavaihtoehto); 
			break;
		case 100: valueChanger(window.MURROSTEKSTIT.kolmasvaihtoehto); 
			break;
		default: //console.log("Unknown value");
	}
}



export default Murros;
