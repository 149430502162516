import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Outlet, Link } from "react-router-dom";


function tutustuNappiMob(e)
{
	e.stopPropagation();
	window.location.assign(
  		"https://urn.fi/URN:ISBN:978-951-827-453-0"
	);
}

function FooterMob(p)
{
	return (<div className="footer" style={{margin: "2px"}}>
			<div key="gappref" className="prefootergap"></div>
			<div key="footer" className="rfooter">
					<div key="footertext" className="rfootertext">
						<p>{window.FOOTERTEKSTIT.eka}</p>
						<p>{window.FOOTERTEKSTIT.toka}</p>
						<p />
					</div>
					<div>
						<div className="rfootertutustu">
							<div></div>
							<div className="nappi" onClick={tutustuNappiMob}>{window.FOOTERTEKSTIT.tutustu}</div>
							<div></div>
						</div>
						<div key="footerpic" className="rfooterpic">
							<img className="rfooterimg" src={window.FOOTERTEKSTIT.kuva}></img>
						</div>
					</div>
			</div>
						<span className="rfooterspanmob">
							{window.FOOTERTEKSTIT.copyr}
						</span>
		</div>);
}

export default FooterMob;
