import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Outlet, Link } from "react-router-dom";


function tutustuNappi(e)
{
	e.stopPropagation();
	window.location.assign(
  		"https://urn.fi/URN:ISBN:978-951-827-453-0"
	);
}

function Footer(p)
{
	return (<div className="footer">
			<div key="gappref" className="prefootergap"></div>
			<div key="footer" className="rfooter">
				<div key="fgapf" className="otsikkogap"></div>
				<div key="fgaps" className="otsikkogap"></div>
				<div className="rfootertable">
					<div key="footertext" className="rfootertext">
						<p>{window.FOOTERTEKSTIT.eka}</p>
						<p>{window.FOOTERTEKSTIT.toka}</p>
						<p />
						<span className="rfooterspan">
							{window.FOOTERTEKSTIT.copyr}
						</span>
					</div>
					<div></div>
					<div>
						<div className="rfootertutustu">
							<div></div>
							<div className="nappi" onClick={tutustuNappi}>{window.FOOTERTEKSTIT.tutustu}</div>
							<div></div>
						</div>
						<div key="footerpic" className="rfooterpic">
							<img className="rfooterimg" src={window.FOOTERTEKSTIT.kuva}></img>
						</div>
					</div>
				</div>
			</div>
		</div>);
}

export default Footer;
