import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Outlet, Link } from "react-router-dom";
import Footer from "./Footer.js";
import ArrowRight from "./ArrowRight.js";
import ArrowLeft from "./ArrowLeft.js";
import HoriLine from "./HoriLine.js";
import BlobLeft from "./BlobLeft.js";
import BlobRight from "./BlobRight.js";
import CoverFlow from "react-coverflow";
import Box from '@mui/material/Box';
import LinearProgress from "@mui/material/LinearProgress";
import { ThemeProvider } from '@mui/material/styles';
import ElamyksellisyysMob from './ElamyksellisyysMob.js';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';




let coverref = [];

function coverClickLeft(e)
{
	if (coverref.current.state.current > 1) 
	{
		coverref.current._handlePrevFigure(e);
	}
	else 
	{
	//	console.log("NOK" + coverref.current.state.current);
	}
	if (coverref.current.state.current < 3) 
	{
	//	console.log("Disable right");
		document.getElementById("hideleftarrow").style.display = "none";
	}
	else
	{
	//	console.log("Enable left");
		document.getElementById("hiderightarrow").style.display = "inline";
	}

}
function coverClickRight(e)
{
	if (coverref.current.state.current < 4) 
	{
		coverref.current._handleNextFigure(e);
	}
	else 
	{
	//	console.log("NOK " + coverref.current.state.current);
	}
	if (coverref.current.state.current > 2) 
	{
	//	console.log("Disable left");
		document.getElementById("hiderightarrow").style.display = "none";
	}
	else
	{
	//	console.log("Enable right");
		document.getElementById("hideleftarrow").style.display = "inline";
	}

}

let firstElaLoad = true;

let elavideo = [];

elavideo.push( <video key="elavideo" width="auto" height="450vh" autoPlay="true" muted="true" mute="true" loop="true" playsInline="true" disablePictureInPicture="true" onCanPlay={elaCanPlay} src={window.ELAMYKSELLISYYSTEKSTIT.tokaosiovideo} type="video/mp4" /> );

function elaCanPlay(e)
{
	//console.log(e);
	//console.log("I can now play in Ela");
	window.elaVideoCanPlay = true;
}

let stopupdates = function () {};


function updatecoverref()
{
	if (coverref)
		if (coverref.current)
			coverref.current.refs.stage.style.height="700px";
}


function Elamyksellisyys(p)
{
	//console.log("Paint");
	let [progress, pupdate] = React.useState(0);
	let [progressLp, pupdateLp] = React.useState(0);
	let [nomoreload, nomoreupdate] = React.useState(<div></div>);

	stopupdates = nomoreupdate;

	coverref = React.useRef();
	React.useEffect( () => { if ( window.RECENTLOAD )  window.drawEla(); updatecoverref(); });
	if (! (window.KAIKKIELAMYSKUVATLADATTU && window.elaVideoCanPlay) )
	{
		function loopy()
		{
			if (! (window.KAIKKIELAMYSKUVATLADATTU && window.elaVideoCanPlay ))
			{
				setTimeout(loopy, 500);
				//console.log("Set new timeout");
			}
			else
			{
				let empty = [];
				stopupdates(empty);
				//console.log("Stopping timeout");
			}
			pupdate((window.allmedia.elamys.length + window.allmedia.elamysv.length) + " images out of " + window.allmedia.elamyss);
			let addition = 0;
			if (window.elaVideoCanPlay) addition = 10;
			pupdateLp((90 * (window.allmedia.elamys.length/window.allmedia.elamyss)) + addition);
		}
		setTimeout(loopy, 1000);
		let cont = [];
		if (firstElaLoad) firstElaLoad = false;
		else cont = elavideo;
		return(	<div> <ThemeProvider theme={window.MUIthemeRed}>
			<div className="keskitetty" style={{color: "#D10000", fontSize: "125%"}}>
				<div>Ladataan:</div>
				<div>
    	  				<LinearProgress variant="determinate" value={progressLp} />
				</div>
			</div></ThemeProvider><div style={{ display:"none" }}> {cont} {nomoreload} </div> </div>);
	}
	else
	{
		if (isMobile && window.NOTIPAD)
		{
			return (<ElamyksellisyysMob elavideo={elavideo}/>);
		}
		else
		{

			let curWidth = "" + (window.innerWidth -  100) + "px";
			//console.log(curWidth);
			//console.log(window.allmedia.elamysv);
			//let vsrc = window.allmedia.elamysv[0].src;
			return(<div style={{color: "#D10000", fontSize: "125%"}}>
			<div key="elamysosioeka" className="kuvallinenvasen">
				<div>
					<h1>{window.ELAMYKSELLISYYSTEKSTIT.ekaosio}</h1>
					<div key="gapihmomia" className="otsikkogap"></div>
					{window.ELAMYKSELLISYYSTEKSTIT.ekaosioekar}
					<div key="gapihmomib" className="paragap"></div>
					{window.ELAMYKSELLISYYSTEKSTIT.ekaosiotokar}
				</div>
				<div style={{justifyContent:"right", alignItems: "right", textAlign: "right"}}>
					<img height="500vh" src={window.ELAMYKSELLISYYSTEKSTIT.ekaosiokuva} />
				</div>
			</div>
			<HoriLine fromcolor="rgb(244, 225, 220)" tocolor="#FF8888"/>
			<div key="gapihmomic" className="osiogap"></div>
			<div className="vasenlevea">
				<h3>{window.ELAMYKSELLISYYSTEKSTIT.tokaosioekar}</h3>
			</div>
			<BlobLeft fromcolor="rgb(244, 225, 220)" tocolor="#FF8888" />	
			<div key="gapihmomid" className="osiogap"></div>
			<div key="elamysosiotoka" className="kuvallinenoikeakapea">
				<div>
					<div style={{position: "absolute",
							clipPath: "polygon(16% 7%, 16% 100%, 92% 100%, 92% 7%)"
	}}>
						{elavideo}
					</div>
					<div style={{position: "absolute"}}>
						<img height="500vh" src={window.ELAMYKSELLISYYSTEKSTIT.tokaosiokuva} />
					</div>
				</div>
				<div style={{ display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "repeat(auto-fit, minmax(100px, auto))", justifyItems: "center", alignItems: "center"}} >
					<div style={{height:"10vh"}}></div>
					<div style={{display: "inline-block", verticalAlign: "middle", lineHeight:"1"}}>
						<div>
						{window.ELAMYKSELLISYYSTEKSTIT.tokaosiotokar}
						</div>
						<h3>
						{window.ELAMYKSELLISYYSTEKSTIT.tokaosiokolmasr}
						</h3>
					</div>
				</div>
			</div>		
			<div key="gapihmomie" className="osiogap"></div>
			<div key="gapihmomif" className="osiogap"></div>
			<div key="gapihmomig" className="osiogap"></div>
			<div key="gapihmomih" className="osiogap"></div>
			<div key="gapihmomii" className="osiogap"></div>
			<div key="gapihmomij" className="osiogap"></div>
			<div key="gapihmomik" className="osiogap"></div>
			<BlobRight fromcolor="rgb(244, 225, 220)" tocolor="#FF8888" />
			<div key="elamysosiokolmas" className="kuvallinenvasenkapea">
				<div>
					{window.ELAMYKSELLISYYSTEKSTIT.neljasosioekar}
				</div>
				<div>
				</div>
			</div>
			<div key="gapihmomil" className="osiogap"></div>
			<div className="keskitetty" style={{fontFamily: "gtflexaB"}}>
				{window.ELAMYKSELLISYYSTEKSTIT.neljasosiotokar}
			</div>
			<div key="wrapperflow" style={{height: "500px", position: "relative"}}>
				<div style={{height: "500px", position: "absolute", alignItems: "center", left:"10em", right:"11em", display:"grid", gridTemplateColumns: "10% 10% 60% 10% 10%", zIndex: "2147483640"}}>
					<div></div>
					<div>
						<div id="hideleftarrow">
						<MuotiPrev onClick={coverClickLeft} stroke="#D10000"/>
						</div>
					</div>
					<div>
					</div>
					<div>
						<div id="hiderightarrow">
						<MuotiNext onClick={coverClickRight} stroke="#D10000"/>
						</div>
					</div>
					<div></div>
				</div>
				<div id="unmountcoverflow" key="unmountcoverflow">
				<CoverFlow ref={coverref}
					height={500}
					displayQuantityOfSide={1}
					navigation={false}
					enableClick={false}
					enableHeading={false}
					media={{background: "rgba(0,0,0,0)"}}
					otherFigureScale={0.5}
					currentFigureScale={0.75}
					>
					<div role="menuitem"></div>
				      <img
					src={window.ELAMYKSELLISYYSTEKSTIT.ekakorttikuva}
					alt='title or description'
					style={{ display: 'block', height: '100%' }}
				      />
				      <img
					src={window.ELAMYKSELLISYYSTEKSTIT.tokakorttikuva}
					alt='title or description'
					style={{ display: 'block', height: '100%' }}
				      />
				      <img
					src={window.ELAMYKSELLISYYSTEKSTIT.kolmaskorttikuva}
					alt='title or description'
					style={{ display: 'block', height: '100%' }}
				      />
				      <img
					src={window.ELAMYKSELLISYYSTEKSTIT.neljaskorttikuva}
					alt='title or description'
					style={{ display: 'block', height: '100%' }}
				      />

				</CoverFlow>
				</div>
			</div>
			<div key="gapihmomim" className="osiogap"></div>
			<div key="gapihmomin" className="osiogap"></div>
			<div key="gapihmomio" className="osiogap"></div>
			<div key="gapihmomiola" className="osiogap"></div>
			<div key="gapihmomiolb" className="osiogap"></div>
			<div className="seuraavasivu">
				<div className="seuraavasivuotsikko" >
					<Link to="../asiakaskokemus" style={{textDecoration: "none", verticalAlign: "middle"}} onClick={moveToAsiakaskokemus}>
						<span style={{color: "#D10000", fontSize:"2em", fontWeight:"bold", verticalAlign: "middle"}}>ASIAKASKOKEMUS</span>
					</Link>
				</div>
				<div style={{transform: "scale(0.75)"}}>
					<Link to="../asiakaskokemus" style={{textDecoration: "none", verticalAlign: "middle"}} onClick={moveToAsiakaskokemus}>
						<ArrowRight stroke="#D10000"/>
					</Link>
				</div>
			</div>	

		</div>);
		}
	}
}

/*

				<video height= width= autoplay muted loop>
					<source src="" type="video/mp4"/>
					Selaimesi ei tue videoita.
				</video>


		<div style={{marginLeft:"20%", marginRight:"20%"}}>
			<div className="murroskortit" style={{verticalAlign: "center"}} >
				<img width="100%" src={window.ELAMYKSELLISYYSTEKSTIT.kortitdummykuva} />
			</div>
		</div>
		<div key="gapihmomi" className="osiogap"></div>
		<div key="murrosneljasosio" className="kuvallinenoikeavasmargi">
			<div>
				<img height="300vh" src={window.ELAMYKSELLISYYSTEKSTIT.neljasosiokuva} />
			</div>
			<div></div>
			<div>
				{window.ELAMYKSELLISYYSTEKSTIT.neljasosioekar}
				<div key="gapihmomi" className="paragap"></div>
				<h3>{window.ELAMYKSELLISYYSTEKSTIT.neljasosiotokar}</h3>
			</div>
		</div>

		<div key="gapihmomi" className="osiogap"></div>
		<div className="vasen">
				{window.ELAMYKSELLISYYSTEKSTIT.viidesosioekar}
				<ul style={{listStyleImage: "url('"+window.ELAMYKSELLISYYSTEKSTIT.viidesosiobullet+"')"}}>
					<li>{window.ELAMYKSELLISYYSTEKSTIT.viidesosioekap}</li>
					<li>{window.ELAMYKSELLISYYSTEKSTIT.viidesosiotokap}</li>
					<li>{window.ELAMYKSELLISYYSTEKSTIT.viidesosiokolmasp}</li>
				</ul>
		</div>
		

*/

function moveToAsiakaskokemus()
{
	window.SETKOKEMUS();
	window.drawAsi();
}


function MuotiPrev(p)
{
	return (
	<div key="karunuolivasen" className="arrowanimleft" onClick={p.onClick}>
<div key="karunuolivaseni" className="arrowleft" style={{transform: "scale(0.5)"}}>
<svg width="200" height="200" viewBox="0 0 200 200">
  <defs>
    <clipPath id="clipPathPrevM">
      <rect id="Rectangle_36" width="104" height="67.918" fill="none" stroke="#fff" strokeWidth="3"/>
    </clipPath>
  </defs>
  <g id="Group_83" transform="translate(1716 2503) rotate(180)">
    <g id="Group_75" transform="translate(1563.539 2369)">
      <g id="Group_51" transform="translate(0.461 0)" clipPath="url(#clipPathPrevM)">
        <path id="Path_113" d="M151.245.006a25.26,25.26,0,0,0,1.982,9.156,35.89,35.89,0,0,0,9.854,13.063c4.185,3.577,9.5,8.3,17.489,11.7A83.82,83.82,0,0,0,163.082,45.7a35.89,35.89,0,0,0-9.854,13.063,25.26,25.26,0,0,0-1.982,9.156" transform="translate(-83.061 -0.003)" fill="none" stroke={p.stroke} strokeMiterlimit="10" strokeWidth="3"/>
        <line id="Line_16" x2="96.791" transform="translate(-0.489 33.959)" fill="none" stroke={p.stroke} strokeMiterlimit="10" strokeWidth="3"/>
      </g>
    </g>
    <g id="Ellipse_18" transform="translate(1516 2303)" fill="none" stroke={p.stroke} strokeWidth="2" strokeDasharray="20">
      <circle cx="100" cy="100" r="100" stroke="none"/>
      <circle cx="100" cy="100" r="99" fill="none"/>
    </g>
  </g>
</svg></div></div>		
	)
}

function MuotiNext(p)
{
	return (
	<div key="karunuolioikee" className="arrowanimright" style={{display:"grid"}} onClick={p.onClick}>
	<div></div>
<div key="karunuolioikeei" className="arrowright" style={{transform: "scale(0.5)"}}>
<svg width="200" height="200" viewBox="0 0 200 200">
  <defs>
    <clipPath id="clipPathNextM">
      <rect id="Rectangle_36" width="104" height="67.918" fill="none" stroke="#fff" strokeWidth="3"/>
    </clipPath>
  </defs>
  <g id="Group_78" transform="translate(-1516 -2303)">
    <g id="Group_75" transform="translate(1563.539 2369)">
      <g id="Group_51" transform="translate(0.461 0)" clipPath="url(#clipPathNextM)">
        <path id="Path_113" d="M151.245.006a25.26,25.26,0,0,0,1.982,9.156,35.89,35.89,0,0,0,9.854,13.063c4.185,3.577,9.5,8.3,17.489,11.7A83.82,83.82,0,0,0,163.082,45.7a35.89,35.89,0,0,0-9.854,13.063,25.26,25.26,0,0,0-1.982,9.156" transform="translate(-83.061 -0.003)" fill="none" stroke={p.stroke} strokeMiterlimit="10" strokeWidth="3"/>
        <line id="Line_16" x2="96.791" transform="translate(-0.489 33.959)" fill="none" stroke={p.stroke} strokeMiterlimit="10" strokeWidth="3"/>
      </g>
    </g>
    <g id="Ellipse_18" transform="translate(1516 2303)" fill="none" stroke={p.stroke} strokeWidth="2" strokeDasharray="20">
      <circle cx="100" cy="100" r="100" stroke="none"/>
      <circle cx="100" cy="100" r="99" fill="none"/>
    </g>
  </g>
</svg></div><div></div></div>

	)
}





export default Elamyksellisyys;
