import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Outlet, Link } from "react-router-dom";
import Footer from "./Footer.js";
import ArrowRight from "./ArrowRight.js";
import HoriLine from "./HoriLine.js";
import BlobLeft from "./BlobLeft.js";
import BlobRight from "./BlobRight.js";
import LinearProgress from "@mui/material/LinearProgress";
import { ThemeProvider } from '@mui/material/styles';

let stopupdates = function () {};

function ihs(f)
{
	return (window.innerHeight * f) + "px";
}


function AsiakaskokemusMob(p)
{
	return (<div style={{color: "#D10000", fontSize: "100%", textAlign:"center"}}>
		<div className="keskitettymob">
			<img height={ihs(0.25)} src={window.ASIAKASKOKEMUSTEKSTIT.ekaosiokuvamob} />
		</div>
		<div className="keskitettymob">
		<h1>{window.ASIAKASKOKEMUSTEKSTIT.ekaosio}</h1>
		</div>
		<div key="gapihmomia" className="otsikkogap"></div>
		<div className="keskitettymob">
		{window.ASIAKASKOKEMUSTEKSTIT.ekaosioekar}
		</div>
		<div key="gapihmomib" className="paragap"></div>
		<div className="keskitettymob">
		{window.ASIAKASKOKEMUSTEKSTIT.ekaosiotokar}
		</div>
		<HoriLine fromcolor="rgb(244, 225, 220)" tocolor="#FF8888"/>
		<div className="keskitettymob">
			<img height={ihs(0.25)} src={window.ASIAKASKOKEMUSTEKSTIT.tokaosiokuva} />
		</div>
		<div key="gapihmomic" className="osiogap"></div>
		<div className="keskitettymob">
			<span style={{verticalAlign: "middle"}} >{window.ASIAKASKOKEMUSTEKSTIT.tokaosioekar}</span>
		</div>
		<div key="gapihmomid" className="osiogap"></div>

		<div className="keskitettymob">
		<h3>{window.ASIAKASKOKEMUSTEKSTIT.kolmasosio}</h3>
		</div>
		<div key="gapihmomie" className="osiogap"></div>
		<div className="keskitettymob">
			<img height={ihs(0.25)} src={window.ASIAKASKOKEMUSTEKSTIT.kolmasosiokuva} />
		</div>
		<div className="keskitettymob">
		{window.ASIAKASKOKEMUSTEKSTIT.kolmassosioekar}
		</div>
		<div key="gapihmomif" className="paragap"></div>
		<div className="keskitettymob">
		<h3>{window.ASIAKASKOKEMUSTEKSTIT.kolmasosiotokar}</h3>
		</div>
		<div key="gapihmomig" className="osiogap"></div>
 		<BlobRight fromcolor="rgb(244, 225, 220)" tocolor="#FF8888" width="40vh" height="50vh"/>
		<div className="keskitettymob"  style={{fontFamily: "gtflexaB"}}>
			{window.ASIAKASKOKEMUSTEKSTIT.aikajana}
		</div>
		<div className="keskitettymob">
			<img width="40%" src={window.ASIAKASKOKEMUSTEKSTIT.aikajanakuva} />
		</div>
		<div className="keskitettymob">
			<AikajanasvgkoodiMob />
		</div>
		<div key="gapihmomigla" className="osiogap"></div>

		<div className="seuraavasivumob">
			<div className="seuraavasivuotsikko" style={{transform: "scale(0.5)"}}>
				<Link to="../digitaalinenmuoti" style={{textDecoration: "none", verticalAlign: "middle"}} onClick={moveToDigimuotiMob} >
					<span style={{color: "#D10000", fontSize:"2em", fontWeight:"bold", verticalAlign: "middle"}}>TULEVAISUUS</span>
				</Link>
			</div>
			<div style={{transform: "scale(0.75)"}}>
				<Link to="../digitaalinenmuoti" style={{textDecoration: "none", verticalAlign: "middle"}} onClick={moveToDigimuotiMob} >
					<ArrowRight stroke="#D10000"/>
				</Link>
			</div>
		</div>
		<div key="gapihmomidsiloppu" className="osiogap"></div>
		<div key="gapihmomidsiloppua" className="osiogap"></div>
		

		</div>);
}

function moveToDigimuotiMob()
{
	window.SETMUOTI();
	window.drawMuo();
}

function AikajanasvgkoodiMob()
{
return(
<svg width="95%" viewBox="0 0 1050 935.999">
  <defs>
    <clipPath id="clipPathAikajana">
      <rect id="Rectangle_6" width="30" height="30" fill="#d10000"/>
    </clipPath>
  </defs>
  <g id="Group_168" transform="translate(-619 -4028.756)">
    <g id="Group_167" >
      <g id="Group_132" transform="translate(0 244.243)">
        <text id="Saat_sovelluksen_kautta_suorat_ohjeet_reklamaatioprosessin_edistamisesta_tuotteen_palauttamisesta_vaihdosta_tai_toimittamisesta_korjattavaksi._" transform="translate(844 3946.513)" fill="#d10000" fontSize="30" fontFamily="gtflexaR"><tspan x="0" y="32">Saat sovelluksen kautta suorat ohjeet </tspan><tspan x="0" y="70">reklamaatioprosessin edistämisestä, tuotteen </tspan><tspan x="0" y="108">palauttamisesta, vaihdosta tai toimittamisesta </tspan></text>
        <text id="Asiakkaana_teet_reklamaation_kotona_tai_brandin_myymassa_alykkaan_reklamaatiolomakkeen_kautta._"   transform="translate(844 3784.513)" fill="#d10000" fontSize="33" fontFamily="gtflexaR"><tspan x="0" y="36">{window.ASIAKASKOKEMUSTEKSTIT.aikajanaekaeka}</tspan><tspan x="0" y="76">{window.ASIAKASKOKEMUSTEKSTIT.aikajanaekatoka}</tspan></text>
        <text id="Parhaassa_tapauksessa_sujuvaan_prosessiin_tyytyväinen_asiakas_palaa_jatkossakin_asiakkaaksenne._"  transform="translate(844 4607.512)" fill="#d10000" fontSize="30" fontFamily="gtflexaR"><tspan x="0" y="32">Parhaassa tapauksessa sujuvaan prosessiin </tspan><tspan x="0" y="70">tyytyväinen asiakas palaa jatkossakin </tspan></text>
        <text id="Yrityksenä_aikaa_vievä_reklamaatioprosessi_tehostuu_reklamaatiot_ohjautuvat_yhteen_kanavaan_ja_toisaalta_myös_turhat_reklamaatiot_saadaan_kitkettyä._"  transform="translate(844 4366.513)" fill="#d10000" fontSize="33" fontFamily="gtflexaR"><tspan x="0" y="36">Yrityksenä aikaa vievä reklamaatioprosessi </tspan><tspan x="0" y="76">tehostuu, reklamaatiot ohjautuvat yhteen </tspan><tspan x="0" y="116">kanavaan, ja toisaalta myös turhat </tspan><tspan x="0" y="156">reklamaatiot saadaan kitkettyä. </tspan></text>
        <text id="Palvelu_on_ensiluokkainen_ja_sujuva._Kenties_huonosta_kokemuksesta_reklamoinnin_tarve_huolimatta_palaat_jatkossakin_hyvin_palvelevan_yrityksen_asiakkaaksi." transform="translate(844 4136.513)" fill="#d10000" fontSize="30" fontFamily="gtflexaR"><tspan x="0" y="32">Palvelu on ensiluokkainen ja sujuva. Kenties </tspan><tspan x="0" y="70">huonosta kokemuksesta (reklamoinnin tarve) </tspan><tspan x="0" y="108">huolimatta palaat jatkossakin hyvin palvelevan </tspan></text>
      </g>
      <g id="Group_133" transform="translate(0 244.243)">
        <g id="Group_122" transform="translate(624 3946.139)">
          <g id="Group_2" clipPath="url(#clipPathAikajana)">
            <path id="Path_67" d="M30.976,16.01h0A37.651,37.651,0,0,0,16.009,30.977h0A37.651,37.651,0,0,0,1.042,16.01,37.651,37.651,0,0,0,16.009,1.043h0A37.651,37.651,0,0,0,30.976,16.01Z" transform="translate(-1.009 -1.01)" fill="#d10000"/>
          </g>
        </g>
        <g id="Group_127" transform="translate(624 4376.004)">
          <g id="Group_2-2" clipPath="url(#clipPathAikajana)">
            <path id="Path_67-2" d="M30.976,16.01h0A37.651,37.651,0,0,0,16.009,30.977h0A37.651,37.651,0,0,0,1.042,16.01,37.651,37.651,0,0,0,16.009,1.043h0A37.651,37.651,0,0,0,30.976,16.01Z" transform="translate(-1.009 -1.01)" fill="#d10000"/>
          </g>
        </g>
        <g id="Ellipse_21" transform="translate(619 3786.513)" fill="none" stroke="#bf281b" strokeWidth="5">
          <circle cx="20" cy="20" r="20" stroke="none"/>
          <circle cx="20" cy="20" r="17.5" fill="none"/>
        </g>
        <g id="Group_130" transform="translate(639 3826.513)">
          <path id="Path_121" d="M0,0V854.073" transform="translate(0)" fill="none" stroke="#d10000" strokeWidth="3"/>
        </g>
      </g>
    </g>
    <g id="Ellipse_22" transform="translate(619 4924.755)" fill="none" stroke="#bf281b" strokeWidth="5">
      <circle cx="20" cy="20" r="20" stroke="none"/>
      <circle cx="20" cy="20" r="17.5" fill="none"/>
    </g>
  </g>
</svg>


);
}

export default AsiakaskokemusMob;
