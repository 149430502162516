import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Outlet, Link } from "react-router-dom";
import Footer from "./Footer.js";
import ArrowRight from "./ArrowRight.js";
import HoriLine from "./HoriLine.js";
import BlobLeft from "./BlobLeft.js";
import BlobRight from "./BlobRight.js";
import Muotikaruselli from "./Muotikaruselli.js";
import LinearProgress from "@mui/material/LinearProgress";
import { ThemeProvider } from '@mui/material/styles';
import DigimuotiMob from './DigimuotiMob.js';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';




let stopupdates = function () {};


function Digimuoti(p)
{
	React.useEffect( () => { if ( window.RECENTLOAD )  window.drawMuo() });
	let [progress, pupdate] = React.useState(0);
	let [progressLp, pupdateLp] = React.useState(0);
	let [nomoreload, nomoreupdate] = React.useState(<div></div>);

	stopupdates = nomoreupdate;

	if (! window.KAIKKIMUOTIKUVATLADATTU )
	{
		function loopy()
		{
				if (! window.KAIKKIMUOTIKUVATLADATTU )
				{
					setTimeout(loopy, 500);
				}
				else
				{
					let empty = [];
					stopupdates(empty);
				}
				pupdate((window.allmedia.muoti.length) + " images out of " + window.allmedia.muotis);
				pupdateLp(100 * (window.allmedia.muoti.length/window.allmedia.muotis));
		}
		setTimeout(loopy, 1000);
		return(	<ThemeProvider theme={window.MUIthemeWhite}>
			<div className="keskitetty" style={{color: "#FFFFFF", fontSize: "125%"}}>
				<div>Ladataan:</div>
				<div>
    	  				<LinearProgress variant="determinate" value={progressLp} />{nomoreload}
				</div>
			</div></ThemeProvider>);
	}
	else
	{
		if (isMobile && window.NOTIPAD)
		{
			return (<DigimuotiMob />);
		}
		else
		{


			return (<div style={{color: "#FFFFFF", fontSize: "125%"}}>
			<div key="digimuotiosioekaa" className="kuvallinenvasen">
				<div>
					<h1>{window.DIGIMUOTITEKSTIT.ekaosio}</h1>
					<div key="gapihmomia" className="otsikkogap"></div>
					{window.DIGIMUOTITEKSTIT.ekaosioekar}
					<div key="gapihmomib" className="paragap"></div>
					{window.DIGIMUOTITEKSTIT.ekaosiotokar}
				</div>
				<div></div><div></div>
			</div>
			<HoriLine fromcolor="rgb(0, 0, 0)" tocolor="rgb(77,77,77)"/>
			<div key="gapihmomic" className="osiogap"></div>
			<div key="digimuotiosiotokab" className="kuvallinenoikeakapea">
				<div style={{fontSize:"56.25%"}}>
					<img height="400vh" src={window.DIGIMUOTITEKSTIT.tokaosiokuva} /><br/>
					{window.DIGIMUOTITEKSTIT.tokaosiokuvateksti}
				</div>
				<div style={{ display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "repeat(auto-fit, minmax(100px, auto))", justifyItems: "center", alignItems: "center"}}>
					<div style={{display: "inline-block", verticalAlign: "middle", lineHeight:"1"}}>
						<div style={{fontFamily: "gtflexaB"}}>{window.DIGIMUOTITEKSTIT.tokaosioekar}</div>
						<div key="gapihmomid" className="osiogap"></div>
						<div>{window.DIGIMUOTITEKSTIT.tokaosiotokar}</div>
						<div key="gapihmomie" className="paragap"></div>
						<div>{window.DIGIMUOTITEKSTIT.tokaosiokolmasr}</div>
						<div key="gapihmomif" className="paragap"></div>
						<div>{window.DIGIMUOTITEKSTIT.tokaosioneljasr}</div>
					</div>
				</div>
			</div>
			<div key="gapihmomig" className="osiogap"></div>
			<div key="digimuotiosioekac" className="kuvallinenvasen">
				<div>
					<h1>{window.DIGIMUOTITEKSTIT.kolmasosio}</h1>
					<div key="gapihmomih" className="otsikkogap"></div>
					{window.DIGIMUOTITEKSTIT.kolmasosioekar}
					<div key="gapihmomii" className="paragap"></div>
					{window.DIGIMUOTITEKSTIT.kolmasosiotokar}
				</div>
				<div><img height="250vh" src={window.DIGIMUOTITEKSTIT.kolmasosiokuva} /></div>
			</div>
			<div key="gapihmomij" className="osiogap"></div>
			<div style={{marginLeft:"10%", marginRight:"10%"}}>
				<Muotikaruselli />
			</div>
			<div key="gapihmomik" className="osiogap"></div>
			<div className="keskitetty">
				<h3>{window.DIGIMUOTITEKSTIT.neljasosio}</h3>
			</div>
			<div key="gapihmomil" className="osiogap"></div>
			<div key="murrosneljasosio" className="kuvallinenvasenmargi">
				<div style={{ display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "repeat(auto-fit, minmax(100px, auto))", justifyItems: "center", alignItems: "center"}}>
					<div style={{display: "inline-block", verticalAlign: "baseline", lineHeight:"1"}}>
						{window.DIGIMUOTITEKSTIT.neljasosioekar}
						<div key="gapihmomim" className="paragap"></div>
						{window.DIGIMUOTITEKSTIT.neljasosiotokar}
					</div>
				</div>
				<div>
					<img height="300vh" src={window.DIGIMUOTITEKSTIT.neljasosiokuva} />
				</div>

			</div>
			<div key="gapihmomin" className="osiogap"></div>
			<div className="vasen" style={{fontSize:"56.25%"}}>
					{window.DIGIMUOTITEKSTIT.sivunalateksti}
			</div>
			<div className="vasen" style={{fontSize:"56.25%"}}>
					{window.DIGIMUOTITEKSTIT.sivunalatekstivideo}
			</div>
			<div className="seuraavasivu">
				<div className="seuraavasivuotsikko" >
					<Link to="../uhkakuvat" style={{textDecoration: "none", verticalAlign: "middle"}} onClick={moveToUhkakuvat}>
						<span style={{color: "#FFFFFF", fontSize:"2em", fontWeight:"bold", verticalAlign: "middle"}}>UHKAKUVAT</span>
					</Link>
				</div>
				<div style={{transform: "scale(0.75)"}}>
					<Link to="../uhkakuvat" style={{textDecoration: "none", verticalAlign: "middle"}} onClick={moveToUhkakuvat}>
						<ArrowRight  stroke="#FFFFFF"/>
					</Link>
				</div>
			</div>

			</div>);
		}
	}
}
function moveToUhkakuvat()
{
	window.SETUHKAT();
	window.drawUhk();
}


export default Digimuoti;
