import React from "react";
import Carousel from 'react-material-ui-carousel'
import { Paper, Button } from '@mui/material'

function korttiClickRightMob()
{
	if (myOnClickNext != null) myOnClickNext();
}
function korttiClickLeftMob()
{
	if (myOnClickPrev != null) myOnClickPrev();
}

let karureffi = [];

let myOnClickNext = null;
let myOnClickPrev = null;

function Muotikaruselli(p)
{

	karureffi = React.useRef();
	const [muotikaruEkaLoaded, setMuotikaruEkaLoaded] = React.useState(false);

	//console.log("Muotikaruselli mobiili:" + p.mobile);
	let content = [];
	let contentButtons = [];
	content.push(
		<img key="karulatausennakko"
		    src="muotikortti1.png"
		    onLoad={() => setMuotikaruEkaLoaded(true)}
		    style={{ display: "none" }}
		  />
	);
	if (muotikaruEkaLoaded) 
	{
		content = [];
		if (p.mobile)
		{
		content.push( <Carousel key="karukaru" autoPlay={false} fullHeightHover={false}  
				NavButton={({onClick, className, style, next, prev}) => {
        // Other logic
				//console.log("On click is ");
				//console.log(onClick);
				if (next) myOnClickNext = onClick;
				if (prev) myOnClickPrev = onClick;
/*
					    <Button onClick={onClick} className={className} style={style}>
						{next && "Next"}
						{prev && "Previous"}
					    </Button>
*/
				return (<div></div>)
				    }}
				navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
				style: {
				    backgroundColor: "rgba(0,0,0,0)",
				    borderRadius: 0
				}
			    	}} 
			    	navButtonsWrapperProps={{   // Move the buttons to the bottom. Unsetting top here to override default style.
				style: {
					top: "25%"
				}
    }} animation="slide" navButtonsAlwaysVisible={true} NextIcon={<KorttiNext/>} PrevIcon={<KorttiPrev/>}>
			{
				window.DIGIMUOTIKORTIT.map( (kortti, i) => <Kortti key={"korttialku" + i} n={i} k={kortti} mobile={true}/> )
			}
		</Carousel> ) ;
			content.push(<div style={{alignItems: "start", display:"grid", height: "50px", gridTemplateColumns: "0% 45% 5% 30% 15%", zIndex: "2147483620"}}>
			<div></div>
			<div key="arrowleftkaru" style={{transform: "scale(0.5) translateY(-100px)", textAlign: "left"}}>
				<div id="hideleftarrowkortti" onClick={korttiClickLeftMob}>
				<KorttiPrev scale={0.75} stroke="#D10000"/>
				</div>
			</div>
			<div>
			</div>
			<div key="arrowrightkaru" style={{transform: "scale(0.5) translateY(-100px)", alignItems: "left"}}>
				<div id="hiderightarrowkortti" onClick={korttiClickRightMob}>
				<KorttiNext scale={0.75} stroke="#D10000"/>
				</div>
			</div>
			<div></div>
		</div>);

				
		}
		else
		{
		content.push( <Carousel ref={karureffi} key="karukaru" autoPlay={false} fullHeightHover={false}  
				navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
				style: {
				    backgroundColor: "rgba(0,0,0,0)",
				    borderRadius: 0
				}
			    	}} 
			    	navButtonsWrapperProps={{   // Move the buttons to the bottom. Unsetting top here to override default style.
				style: {
					top: "25%"
				}
    }} animation="slide" navButtonsAlwaysVisible={true} NextIcon={<KorttiNext/>} PrevIcon={<KorttiPrev/>}>
			{
				window.DIGIMUOTIKORTIT.map( (kortti, i) => <Kortti key={"korttialku" + i} n={i} k={kortti} mobile={false}/> )
			}
		</Carousel> ) ;
		}
	}
	
	return (<div>
		<div key="karuwrap" className="murroskortit" style={{verticalAlign: "center", backgroundSize: "100% 100%", backgroundImage: "url(\"muotikarugradientti.svg\")", backgroundRepeat: "no-repeat"}} >
			{content}
		</div>
		{contentButtons}
		</div>
	);
}


function Kortti(p)
{
	let imgheight=(window.innerHeight*0.8) + "px";
	let textarea = [];
	if (p.mobile)
	{
		imgheight=(window.innerHeight*0.6) + "px";
		textarea.push(
			<div  key={"karukorttigridimib"+p.n} style={{alignSelf: "center", justifySelf: "center", fontSize: "62.5%"}}>
				{p.k.tekijaotsikko}
				<br />
				{p.k.tekija}
			</div>
	)
	}
	else
	{
		textarea.push(
			<div  key={"karukorttigridimib"+p.n} style={{alignSelf: "center", justifySelf: "center", fontSize: "58%"}}>
				{p.k.tekijaotsikko}
				<br />
				{p.k.tekija}
			</div>
	)
	}
    return (
	<div key={"karukorttigridi"+p.n} className="korttigrid" style={{display: "grid", gridTemplateColumns:"25% 50% 25%", fontFamily: "gtflexaR"}}>
		<div key={"karukorttigridil"+p.n}></div>
		<div  key={"karukorttigridim"+p.n} className="korttiinner" style={{display: "grid", gridTemplateColumns:"100%"}}>
			<div key={"karukorttigridimia"+p.n} style={{alignSelf: "center", justifySelf: "center"}}>
		    		<img height={imgheight} src={p.k.kuva} />
			</div>
			{textarea}
		</div>
		<div key={"karukorttigridir"+p.n}></div>
	</div>
    )
}


function KorttiPrev(p)
{
	return (
	<div key="karunuolivasen" className="arrowanimleft">
<div key="karunuolivaseni" className="arrowleft" style={{transform: "scale(0.75)"}}>
<svg width="200" height="200" viewBox="0 0 200 200">
  <defs>
    <clipPath id="clipPathPrev">
      <rect id="Rectangle_36" width="104" height="67.918" fill="none" stroke="#fff" strokeWidth="3"/>
    </clipPath>
  </defs>
  <g id="Group_83" transform="translate(1716 2503) rotate(180)">
    <g id="Group_75" transform="translate(1563.539 2369)">
      <g id="Group_51" transform="translate(0.461 0)" clipPath="url(#clipPathPrev)">
        <path id="Path_113" d="M151.245.006a25.26,25.26,0,0,0,1.982,9.156,35.89,35.89,0,0,0,9.854,13.063c4.185,3.577,9.5,8.3,17.489,11.7A83.82,83.82,0,0,0,163.082,45.7a35.89,35.89,0,0,0-9.854,13.063,25.26,25.26,0,0,0-1.982,9.156" transform="translate(-83.061 -0.003)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
        <line id="Line_16" x2="96.791" transform="translate(-0.489 33.959)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
      </g>
    </g>
    <g id="Ellipse_18" transform="translate(1516 2303)" fill="none" stroke="#fff" strokeWidth="2" strokeDasharray="20">
      <circle cx="100" cy="100" r="100" stroke="none"/>
      <circle cx="100" cy="100" r="99" fill="none"/>
    </g>
  </g>
</svg></div></div>		
	)
}

function KorttiNext(p)
{
	return (
	<div key="karunuolioikee" className="arrowanimright" style={{display:"grid"}}>
	<div></div>
<div key="karunuolioikeei" className="arrowright" style={{transform: "scale(0.75)"}}>
<svg width="200" height="200" viewBox="0 0 200 200">
  <defs>
    <clipPath id="clipPathNext">
      <rect id="Rectangle_36" width="104" height="67.918" fill="none" stroke="#fff" strokeWidth="3"/>
    </clipPath>
  </defs>
  <g id="Group_78" transform="translate(-1516 -2303)">
    <g id="Group_75" transform="translate(1563.539 2369)">
      <g id="Group_51" transform="translate(0.461 0)" clipPath="url(#clipPathNext)">
        <path id="Path_113" d="M151.245.006a25.26,25.26,0,0,0,1.982,9.156,35.89,35.89,0,0,0,9.854,13.063c4.185,3.577,9.5,8.3,17.489,11.7A83.82,83.82,0,0,0,163.082,45.7a35.89,35.89,0,0,0-9.854,13.063,25.26,25.26,0,0,0-1.982,9.156" transform="translate(-83.061 -0.003)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
        <line id="Line_16" x2="96.791" transform="translate(-0.489 33.959)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
      </g>
    </g>
    <g id="Ellipse_18" transform="translate(1516 2303)" fill="none" stroke="#fff" strokeWidth="2" strokeDasharray="20">
      <circle cx="100" cy="100" r="100" stroke="none"/>
      <circle cx="100" cy="100" r="99" fill="none"/>
    </g>
  </g>
</svg></div><div></div></div>

	)
}



export default Muotikaruselli;
